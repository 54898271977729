import { formatSizeToString } from 'utils/formatSize';
import { EImgFileSize } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { RuleObject } from 'antd/es/form';
import { RcFile } from 'antd/es/upload';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getValidateFileSizeValidator = (maxFileSize: EImgFileSize = EImgFileSize.DEFAULT): ValidatorRule => ({
	validator: (_: RuleObject, file?: string | RcFile) => {
		if (!file || typeof file === 'string') return Promise.resolve();

		const isValid = file.size < maxFileSize;

		if (isValid) {
			return Promise.resolve();
		}

		const formattedSize = formatSizeToString(maxFileSize);

		return Promise.reject(
			<TranslatedLabel
				nameSpace='common'
				i18nKey='image.validation.file.size'
				extra={{ maxFileSize: formattedSize }}
			/>
		);
	},
});
