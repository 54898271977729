import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getAccessibleCountryList, getSettingsSlice } from 'store/selectors';
import { setSelectedAreas, setSelectedCountry, setSelectedTimezone } from 'store/slices/settings';
import { useGlobalConfigs } from 'hooks';
import { transformCountriesListToSelectOptions } from './helper';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import styles from './CountrySelect.module.css';
import dayjs from 'dayjs';
import { Select } from 'antd';

export const CountrySelect: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { storage } = useGlobalConfigs();

	// ! selectors
	const countriesList = useSelector(getAccessibleCountryList);
	const { selectedCountryId } = useSelector(getSettingsSlice);

	// ! handlers
	// * timeZone management
	const handleTimeZoneSelect = (newSelectedTimezone: string) => {
		dispatch(
			setSelectedTimezone({
				newSelectedTimezone,
				storage,
			})
		);
	};

	// * country management
	const onCountrySelectChange = (chosenCountryId: string) => {
		if (selectedCountryId != null && selectedCountryId.toString() !== chosenCountryId) {
			dispatch(setSelectedCountry({ newSelectedCountryId: +chosenCountryId }));
			handleClearAreas();

			handleTimeZoneSelect(dayjs.tz.guess());

			navigate(ABSOLUTE_ROUTES.LOBBY);
		}
	};

	// * area management
	const handleClearAreas = () => {
		dispatch(
			setSelectedAreas({
				selectedAreas: [],
				storage,
			})
		);
	};

	// ! render
	if (!countriesList?.length) {
		return null;
	}

	return (
		<Select
			size='small'
			onChange={onCountrySelectChange}
			value={selectedCountryId?.toString()}
			className={styles.country_select}
			options={transformCountriesListToSelectOptions(countriesList)}
		/>
	);
};
