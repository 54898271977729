import { ISelectOption } from 'types/common';

const createDeliveryTimeOption = (timeOpt: number) => ({
	value: timeOpt,
	label: `${timeOpt} Min`,
});

export const ORDER_DELIVERY_TIME_OPTIONS_LIST: ISelectOption[] = [
	...Array.from({ length: 56 }, (_, i) => i + 5), // 5 to 60
	...Array.from({ length: 6 }, (_, i) => 65 + i * 5), // 65 to 90 (step +5)
	...Array.from({ length: 7 }, (_, i) => 120 + i * 30), // 120 to 300 (step +30)
].map<ISelectOption>(createDeliveryTimeOption);
