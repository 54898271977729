import { isNumber } from 'utils/validationUtils/isNumber';
import TranslatedLabel from 'components/TranslatedLabel';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getMaxDeliveryTimeValidator = (minDeliveryTimeFormValue?: number): ValidatorRule => ({
	validator(_, value) {
		if (isNumber(minDeliveryTimeFormValue) && minDeliveryTimeFormValue > value) {
			return Promise.reject(<TranslatedLabel i18nKey={'form_validations.max_order_delivery_time'} />);
		}
		return Promise.resolve();
	},
});
