import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import {
	IVerticalGroupCreatePayload,
	IVerticalGroupPatchPayload,
} from 'pages/HomeAppConfiguration/VerticalGroupList/types';

export const ALL_SERVICES_API = {
	list: (params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/app-management/vertical-groups',
		method: 'GET',
		params,
	}),
	create: (data: IVerticalGroupCreatePayload): THttpRequestConfig => ({
		url: 'operations/app-management/vertical-groups',
		method: 'POST',
		data,
	}),
	patch: (id: number, data: Partial<IVerticalGroupPatchPayload>): THttpRequestConfig => ({
		url: `operations/app-management/vertical-groups/${id}`,
		method: 'PATCH',
		data,
	}),
	delete: (id: number): THttpRequestConfig => ({
		url: `operations/app-management/vertical-groups/${id}`,
		method: 'DELETE',
	}),
};
