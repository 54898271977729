import { IHttpClientData } from 'hooks/useHttpClient/types';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { TIME_FORMAT } from 'utils/date';
import { ORDERS_API } from 'configs/api';
import { ORDER_PAYMENT_TYPES_ICONS } from 'configs/orders';
import { IDispatcherOrder, IDispatcherOrderTimeline, IUpdateOrdersStatusConfig } from 'types/api';
import { TEmptyFunction } from 'types/common';
import { EDispatcherTab, IOrderCardData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import {
	DISPATCHER_ORDER_TIMELINE_STEPS_ICONS,
	IDispatcherOrderTimelineStep,
} from './OrdersCards/DispatcherOrderTimeline';
import { Tooltip } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { CheckCircleOutlined } from '@ant-design/icons';

// ! cards data
const getOrderTimelineSteps = (timeline: IDispatcherOrderTimeline): IDispatcherOrderTimelineStep[] => {
	return timeline.timeline_order.map((key) => ({
		key,
		disabled: true,
		icon: DISPATCHER_ORDER_TIMELINE_STEPS_ICONS[key] ?? <CheckCircleOutlined />,
		description: timeline[key] && (
			<Tooltip
				placement='bottom'
				title={<LocalTimeDate date={timeline[key]!} />}
			>
				<div>
					<LocalTimeDate
						date={timeline[key]!}
						format={TIME_FORMAT}
					/>
				</div>
			</Tooltip>
		),
	}));
};

const transformIDispatcherOrderToIOrderCardData = (order: IDispatcherOrder): IOrderCardData => {
	const { id, info, store: branch, payment, driver, delivery, timeline, actions, issue, order_journey } = order;

	return {
		// branch (aka 'old' store)
		order_id: id,
		branch,
		pickup_address_latlng: { lat: branch.lat, lng: branch.lng },

		// info
		order_code: info.code,
		status: info.status,
		status_description: info.status_description,

		order_placed_at: info.placed_at,
		order_max_customer_delivery_eta_at: info.max_customer_delivery_eta_at,

		delivery_status: info.delivery_status,
		dispute_status: info.dispute_status,
		dispute_status_description: info.dispute_status_description,
		booking_type: info.booking_type,
		pickup_time: info.pickup_time,
		delivery_mode: info.delivery_mode,
		delivery_mode_description: info.delivery_mode_description,
		delivery_mode_description_detailed: info.delivery_mode_description_detailed,

		// payment
		payment_status: payment.status,
		payment_type: payment.method,
		payment_type_icon: ORDER_PAYMENT_TYPES_ICONS[payment.method],
		to_pay: `${payment.amount_payable} ${payment.currency_code}`,
		total_order: `${payment.grand_total} ${payment.currency_code}`,

		// driver
		driver_id: driver?.id,
		driver: driver,

		// order journey
		order_journey,

		// delivery address
		delivery_address_latlng: {
			lat: delivery.delivery_address.lat,
			lng: delivery.delivery_address.lng,
		},

		// areas
		origin_area: delivery.origin_area,
		delivery_area: delivery.delivery_area,

		// order timeline
		timeline: getOrderTimelineSteps(timeline),

		// order status actions
		actions,

		// order issues
		issue,
	};
};

export const transformDataToCardData = (orders: IDispatcherOrder[]): IOrderCardData[] => {
	return orders.map(transformIDispatcherOrderToIOrderCardData);
};

// ! order status
export const updateOrderStatus = (
	id: number,
	data: IUpdateOrdersStatusConfig,
	successCb: TEmptyFunction,
	http: IHttpClientData,
	message: MessageInstance
) => {
	http.request({
		requestConfig: ORDERS_API.updateOrderStatus(id, data),
		successCallback: () => {
			message.success(
				<TranslatedLabel
					nameSpace='dispatcher'
					i18nKey='order_status_update_modal.success_message'
				/>,
				5
			);
			successCb();
		},
	});
};

// ! delivery status
export const canShowDeliveryStatus = (activeTab: EDispatcherTab): boolean => {
	const tabsWithDeliveryStatusFilters = [
		EDispatcherTab.NEW,
		EDispatcherTab.PREPARING,
		EDispatcherTab.READY_FOR_PICK_UP,
		EDispatcherTab.DISPATCHED,
		EDispatcherTab.DISPUTED,
		EDispatcherTab.INTERVENTION,
	];

	return tabsWithDeliveryStatusFilters.includes(activeTab);
};

// ! delivery mode
export const CAN_SHOW_DELIVERY_MODE: Record<EDispatcherTab, boolean> = {
	[EDispatcherTab.NEW]: true,
	[EDispatcherTab.PREPARING]: true,
	[EDispatcherTab.READY_FOR_PICK_UP]: true,
	[EDispatcherTab.DISPATCHED]: true,
	[EDispatcherTab.AWAITING_PAYMENT]: false,
	[EDispatcherTab.SCHEDULED]: false,
	[EDispatcherTab.DISPUTED]: false,
	[EDispatcherTab.COMPLETED]: false,
	[EDispatcherTab.CANCELLED]: false,
	[EDispatcherTab.FAILED]: false,
	[EDispatcherTab.INTERVENTION]: false,
};

export const CAN_SHOW_REBROADCAST_BUTTON: Record<EDispatcherTab, boolean> = {
	[EDispatcherTab.AWAITING_PAYMENT]: false,
	[EDispatcherTab.SCHEDULED]: false,
	[EDispatcherTab.NEW]: false,
	[EDispatcherTab.PREPARING]: false,
	[EDispatcherTab.READY_FOR_PICK_UP]: true,
	[EDispatcherTab.DISPATCHED]: false,
	[EDispatcherTab.DISPUTED]: false,
	[EDispatcherTab.COMPLETED]: false,
	[EDispatcherTab.CANCELLED]: false,
	[EDispatcherTab.FAILED]: false,
	[EDispatcherTab.INTERVENTION]: false,
};

// ! Clear Dispute
export const clearDispute = (
	orderId: number,
	comment: string,
	successCb: TEmptyFunction,
	http: IHttpClientData,
	message: MessageInstance
) => {
	http.request({
		requestConfig: ORDERS_API.clearDispute(orderId, comment),
		successCallback: () => {
			message.success(
				<TranslatedLabel
					nameSpace='dispatcher'
					i18nKey='clear_dispute_modal.success_message'
				/>,
				3
			);
			successCb();
		},
	});
};
