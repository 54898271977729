import { FC } from 'react';
import { classNames } from 'utils/classNames';
import { CONTENT_RENDERS } from './configs';
import { IAppComponentProps } from './types';
import { IHomeDnDContentElement } from 'pages/HomeAppConfiguration/SchemaBuilder/types';
import styles from './SchemaComponent.module.css';
import { Button, Card, Flex } from 'antd';
import { CloseOutlined, HolderOutlined, SwapOutlined } from '@ant-design/icons';

export const SchemaComponent: FC<IAppComponentProps> = ({ ...props }) => {
	const {
		extraCommands,
		label,
		handleProps,
		isDragging,
		hasError,
		type,
		content,

		onRemove,
		onInject,
	} = props;

	// ! handlers
	const handleInjectComponent = () => {
		const newElement: IHomeDnDContentElement = {
			...props.content,
			...props,
			dndId: props.content?.dndId || crypto.randomUUID(),
		};

		onInject?.(newElement);
	};

	// ! render
	return (
		<Card
			size='small'
			className={classNames(
				//
				styles.schema_component,
				{
					[styles.dragging]: !!isDragging,
					[styles.error]: !!hasError,
				}
			)}
		>
			<Flex gap='small'>
				{/* Component preview */}
				<Flex
					vertical
					gap='small'
					className='w-100'
				>
					{label && <div style={{ overflow: 'hidden' }}>{label}</div>}

					<Flex
						gap='small'
						justify='center'
					>
						<div className={styles.template_representation_container}>
							{CONTENT_RENDERS[type]?.(content) ?? <>Mock not implemented</>}
						</div>
					</Flex>
				</Flex>

				<Flex
					vertical
					gap='small'
				>
					{extraCommands?.dragHandle && (
						<Button
							size='small'
							shape='circle'
							icon={<HolderOutlined />}
							{...handleProps}
						/>
					)}

					{extraCommands?.remove && onRemove && (
						<Button
							size='small'
							icon={<CloseOutlined />}
							onClick={onRemove}
						/>
					)}

					{extraCommands?.injectOnPosition && (
						<Button
							size='small'
							shape='circle'
							icon={<SwapOutlined />}
							onClick={handleInjectComponent}
						/>
					)}
				</Flex>
			</Flex>
		</Card>
	);
};
