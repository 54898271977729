import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalTimeDate, useCustomer, useGetFormValues, useNewHttpClient } from 'hooks';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { CUSTOMER_API } from 'configs/api';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { EXPIRE_ON_DATE_FORMAT } from '../configs';
import { CANCELLATION_OPTIONS } from './configs';
import { ICustomerCariUnlimitedSubscriptionCancellationData } from 'types/api';
import { ICariUnlimitedSubscriptionCancellationModalProps } from './types';
import { Alert, App, Flex, Form, Input, Modal, Radio, Typography as T } from 'antd';

const CariUnlimitedSubscriptionCancellationModal: FC<ICariUnlimitedSubscriptionCancellationModalProps> = ({
	open,
	customerId,
	expiryDate,
	onCloseModal,
}) => {
	const { message } = App.useApp();
	const { getFormValues } = useGetFormValues();
	const { fetchCustomerInformation: refreshData } = useCustomer();

	const { t: tCommon } = useTranslation('common');
	const { t: tSubscriptionCancelModal } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.cari_unlimited_manager.subscription_cancellation_modal',
	});

	// ! form
	const [form] = Form.useForm<ICustomerCariUnlimitedSubscriptionCancellationData>();

	// ! http client
	const cancelHttpClient = useNewHttpClient();

	// ! handlers
	const onConfirmCancelSubscription = (data: ICustomerCariUnlimitedSubscriptionCancellationData) => {
		return cancelHttpClient.request({
			requestConfig: CUSTOMER_API.cancelSubscription(data),
			successCallback: () => {
				message.success(tSubscriptionCancelModal('success_message'), 5);

				// close modal and make sure that customer information is updated
				onCloseModal();
				refreshData();
			},
		});
	};

	const onOkModal = async () => {
		const data = await getFormValues(form);
		if (!data) return;

		// on confirm
		onConfirmCancelSubscription(data);
	};

	const onAfterCloseModal = () => form.resetFields();

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={open}
			title={tSubscriptionCancelModal('title')}
			okText={tCommon('action_buttons.confirm')}
			confirmLoading={cancelHttpClient.isLoading}
			onOk={onOkModal}
			onCancel={onCloseModal}
			afterClose={onAfterCloseModal}
		>
			<Flex
				vertical
				gap='middle'
			>
				{expiryDate && (
					<Alert
						banner
						type='info'
						message={
							<T.Text>
								{tSubscriptionCancelModal('alert_message')}
								&nbsp;
								<LocalTimeDate
									date={expiryDate}
									format={EXPIRE_ON_DATE_FORMAT}
								/>
							</T.Text>
						}
					/>
				)}

				<Form
					form={form}
					layout='vertical'
					name='cari_unlimited_subscription_edit_form'
				>
					<Form.Item
						hidden
						name='customer_id'
						initialValue={customerId}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name='cancellation_method'
						label={tSubscriptionCancelModal('choose_an_option_label')}
						rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
					>
						<Radio.Group options={CANCELLATION_OPTIONS} />
					</Form.Item>

					<Form.Item
						name='cancellation_reason'
						label={tSubscriptionCancelModal('cancellation_reason')}
						rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
					>
						<Input.TextArea rows={3} />
					</Form.Item>
				</Form>
			</Flex>
		</Modal>
	);
};

export default memo(CariUnlimitedSubscriptionCancellationModal);
