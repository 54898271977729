import { PropsWithChildren, createContext, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { THttpFunction, http } from 'utils/axiosInstance';
import { IStorageUtils, storageUtils } from 'utils/localStorage';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { EBrand, EGlobalConfigFields, IGlobalConfig } from 'types/appConfig';
import { Nullable } from 'types/common';
import * as yup from 'yup';

const useAbsoluteRoutesHelper = () => {
	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! helpers
	const generatePathCustom = (path: string, values: Record<string, any>) => {
		return path.replaceAll(/:([a-zA-Z]+)/g, (_, key) => values[key] || `:${key}`);
	};

	// ! memos
	const routes: Record<string, string> = useMemo(() => {
		// * Replace arguments that can be replaced
		const replacedRouteEntries = Object.values(ABSOLUTE_ROUTES).map((value) => [
			value,
			generatePathCustom(value, { countryIsoTwoCode }),
		]);

		return Object.fromEntries(replacedRouteEntries);
	}, [countryIsoTwoCode]);

	// ! handlers
	const customGeneratePath = <Path extends string>(
		originalPath: Path,
		params?: Record<string, Nullable<string | number>>
	): string => {
		return generatePath(routes[originalPath], params);
	};

	// !
	return customGeneratePath;
};

export const getConfiguration = async (): Promise<object> => {
	const response = await fetch('/config.json', {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});
	return response.json();
};

export interface IGlobalConfigData {
	configs: IGlobalConfig;

	// utils
	http: THttpFunction;
	storage: IStorageUtils;

	customGeneratePath: ReturnType<typeof useAbsoluteRoutesHelper>;
}

const GlobalConfigContext = createContext<IGlobalConfigData>({} as IGlobalConfigData);

const useGlobalConfigs = () => {
	return useContext(GlobalConfigContext);
};

const GlobalConfigProvider = ({ children }: PropsWithChildren) => {
	const [configs, setConfigs] = useState<IGlobalConfig>();
	const customGeneratePath = useAbsoluteRoutesHelper();

	useLayoutEffect(() => {
		getConfiguration().then((config: object) => {
			const schema: yup.ObjectSchema<IGlobalConfig> = yup.object({
				[EGlobalConfigFields.BRAND]: yup.string().oneOf(Object.values(EBrand)).required(),
				[EGlobalConfigFields.API_URL]: yup.string().required(),
				[EGlobalConfigFields.WS_URL]: yup.string().required(),
			});

			try {
				const validatedConfig = schema.validateSync(config);

				setConfigs(validatedConfig);
			} catch (error) {
				console.error(
					"Global configuration json file is not valid! Verify either 'runtime-config.json or public/config.json'"
				);
				console.error(error);
				throw error;
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!configs) {
		return null;
	}

	const storage = storageUtils(configs.brand);

	const data = {
		configs,

		http: http(configs.api_url, storage),
		storage: storageUtils(configs.brand),
		customGeneratePath,
	};

	return <GlobalConfigContext.Provider value={data}>{children}</GlobalConfigContext.Provider>;
};

export default useGlobalConfigs;
export { useGlobalConfigs, GlobalConfigProvider };
