import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { IHomeDnDContentElement } from '../types';
import { useDraggable } from '@dnd-kit/core';

export const NormalDraggable: FC<PropsWithChildren & { id: string; content: IHomeDnDContentElement }> = ({
	children = <Outlet />,
	id,
	...props
}) => {
	// Ensure only one child
	const onlyChild = React.Children.only(children) as ReactElement;

	const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
		id,
		data: {
			isTemplateComponent: true,
			...props.content,
		},
	});

	const handleProps = {
		...attributes,
		...listeners,
	};

	// Clone the child and pass additional props
	return <div ref={setNodeRef}>{React.cloneElement(onlyChild, { handleProps, isDragging, ...props })}</div>;
};
