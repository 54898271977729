import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { ICustomerWalletTransactionTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { UserRole } from 'components/UserRole';
import WalletTransactionTag from 'components/_tags/WalletTransactionTag';
import { ColumnsType } from 'antd/es/table';

const translationKeyPrefix: string = 'customer_details.tabs.wallet_history';

export const WALLET_TRANSACTIONS_TABLE_COLUMNS: ColumnsType<ICustomerWalletTransactionTableData> = [
	{
		key: 'comment',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.comment'
				extra={{ keyPrefix: translationKeyPrefix }}
			/>
		),
		dataIndex: 'comment',
	},
	{
		key: 'transaction_type',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='table_columns.transaction_type'
				extra={{ keyPrefix: translationKeyPrefix }}
			/>
		),
		width: 120,
		render: (_, { transaction_type }) => <WalletTransactionTag type={transaction_type} />,
	},
	{
		key: 'amount',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.amount'
				extra={{ keyPrefix: translationKeyPrefix }}
			/>
		),
		dataIndex: 'amount',
		width: 200,
	},
	{
		key: 'author',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.author'
				extra={{ keyPrefix: translationKeyPrefix }}
			/>
		),
		render: (_, { author }) => (
			<UserRole
				asTag={false}
				{...author}
			/>
		),
	},
	{
		key: 'transaction_date',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='table_columns.transaction_date'
				extra={{ keyPrefix: translationKeyPrefix }}
			/>
		),
		width: 200,
		render: (_, { transaction_date }) => <LocalTimeDate date={transaction_date} />,
	},
];
