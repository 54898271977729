import { Nullable } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { RuleObject } from 'antd/es/form';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getLesserEqualThanValidator = (max: number): ValidatorRule => ({
	validator(_: RuleObject, value: Nullable<number>) {
		if (value === null || value <= max) {
			return Promise.resolve();
		}

		return Promise.reject(
			<TranslatedLabel
				i18nKey='form_validations.lesser_equal_than'
				extra={{ max }}
			/>
		);
	},
});
