import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EMyAccountGenericEntryComponentType } from '../types';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib';

export const MyAccountGenericEntryComponentTypeSelector: FC<SelectProps<EMyAccountGenericEntryComponentType>> = ({
	...props
}) => {
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	// ! render
	return (
		<Select
			showSearch
			options={Object.values(EMyAccountGenericEntryComponentType).map((type) => ({
				value: type,
				label: tSchemaBuilder(`enums.EMyAccountGenericEntryComponentType.${type}`),
			}))}
			placeholder={tSchemaBuilder('form.type.placeholder')}
			{...props}
		/>
	);
};
