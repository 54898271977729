import { Nullable } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { RuleObject } from 'antd/es/form';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getGreaterThanZeroValidator = (): ValidatorRule => ({
	validator(_: RuleObject, value: Nullable<number>) {
		if (value === null || value > 0) {
			return Promise.resolve();
		}

		return Promise.reject(<TranslatedLabel i18nKey='form_validations.greater_than_zero' />);
	},
});
