import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { SelectAreasDrawerOpenButton } from 'components/SelectAreasDrawer/SelectAreasDrawerOpenButton';
import CountrySelect from './CountrySelect';
import styles from './Header.module.css';
import { TimeZoneSelect } from './TimeZoneSelect/TimeZoneSelect';
import { Avatar, Button, Dropdown, Flex, MenuProps, Typography as T } from 'antd';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';

export const Header: FC = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const { t: tCommon } = useTranslation('common');

	// ! handlers
	const onUserInfoClickItem = () => {
		navigate(ABSOLUTE_ROUTES.USER_SETTINGS);
	};

	const userMenu = useMemo<MenuProps>(
		() => ({
			selectable: false,
			items: [
				{
					key: 'user-info',

					label: (
						<>
							<T.Paragraph className={styles.name}>{auth.user?.name}</T.Paragraph>
							<T.Paragraph className={styles.email}>{auth.user?.email}</T.Paragraph>
						</>
					),
					onClick: onUserInfoClickItem,
				},
				{ type: 'divider' },
				{
					key: 'user-settings',
					icon: <SettingOutlined />,
					label: tCommon('header.user.settings'),
					onClick: onUserInfoClickItem,
				},

				{ type: 'divider' },
				{
					key: 'logout-btn',
					className: styles.logout_btn,
					label: (
						<Button
							block
							onClick={() => auth.signout()}
						>
							{tCommon('header.user.sign_out')}
						</Button>
					),
				},
			],
		}),
		[auth] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// ! render
	return (
		<Flex
			className={styles.app_header}
			align='center'
			justify='flex-end'
		>
			<Flex
				gap='middle'
				justify='center'
				align='center'
			>
				<Flex
					gap='small'
					wrap='wrap'
				>
					<CountrySelect />
					<SelectAreasDrawerOpenButton />
					<TimeZoneSelect />
				</Flex>

				<Dropdown
					menu={userMenu}
					placement='bottomLeft'
					arrow={{ pointAtCenter: true }}
				>
					<Avatar
						size={40}
						icon={<UserOutlined />}
						className={styles.avatar}
					/>
				</Dropdown>
			</Flex>
		</Flex>
	);
};
