import { FC, ReactNode, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useForceRefreshData } from 'hooks';
import { APPLICATION_CONFIGURATION_API } from 'configs/api/applicationConfiguration';
import { ID_COLUMN_WIDTH } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { COMPONENT_TYPE_FILTERS_CONFIG } from './configs';
import { IMobileComponentCreatePayload, IMobileComponentListResponse, IMobileListTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import PageContainer from 'components/PageContainer';
import TableWrapper from 'components/Table';
import { SchemaComponent } from '../components/SchemaComponent/SchemaComponent';
import { MobileComponentEditAddDrawer } from './MobileComponentEditAddDrawer/MobileComponentEditAddDrawer';
import { IMobileComponentEditAddDrawerHandle } from './MobileComponentEditAddDrawer/types';
import { useMobileComponentController } from './useMobileComponentController';
import { Button, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { UniqueIdentifier } from '@dnd-kit/core';

interface IMobileComponentsListProps {
	withTitle: boolean;
	extraActions?: ReactNode;
}

export const MobileComponentsList: FC<IMobileComponentsListProps> = ({ withTitle, extraActions }) => {
	const { t: tCommon } = useTranslation();
	const { t: tMobileComponents } = useTranslation('app-mobile-components');
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	const { hasPermission } = useAuth();
	const mobileComponentController = useMobileComponentController();

	const { canCreate, canEdit, canDelete } = useMemo(
		() => ({
			canCreate: hasPermission([APP_PERMISSIONS.app_management.app_component.add]),
			canEdit: hasPermission([APP_PERMISSIONS.app_management.app_component.update]),
			canDelete: hasPermission([APP_PERMISSIONS.app_management.app_component.delete]),
		}),
		[hasPermission]
	);

	// ! refs
	const editComponentDrawerRef = useRef<IMobileComponentEditAddDrawerHandle>(null);

	// ! states
	const { forceRefreshData, refreshingData } = useForceRefreshData();

	// ! handlers
	const handleDeleteGroup = (groupId: UniqueIdentifier) => {
		return mobileComponentController.deleteComponent(groupId)?.then(refreshingData);
	};

	// ! memos
	const listRequestConfig = useMemo(() => APPLICATION_CONFIGURATION_API.APP_COMPONENTS_API.list(), []);

	const columns = useMemo<ColumnsType<IMobileListTableData>>(
		() => {
			return [
				{
					title: tCommon('table.headers.id'),
					dataIndex: 'id',
					width: ID_COLUMN_WIDTH,
				},
				{
					title: tCommon('table.headers.name'),
					dataIndex: 'name',
				},
				{
					key: 'type',
					title: tMobileComponents('table.headers.type'),
					filters: COMPONENT_TYPE_FILTERS_CONFIG,
					render: (_, { type }) => <>{tSchemaBuilder(`enums.ESchemaComponentType.${type}`)}</>,
				},
				{
					key: 'preview',
					title: tMobileComponents('table.headers.preview'),

					render: (_, record) => (
						<Flex justify='center'>
							<div style={{ maxWidth: 300 }}>
								<SchemaComponent
									type={record.type}
									content={{
										dndId: record.id,
										type: record.type,
										component: record,
									}}
								/>
							</div>
						</Flex>
					),
				},
				{
					key: 'actions',
					title: tCommon('table.headers.actions'),
					width: getActionButtonsColumnWidth(2),
					fixed: 'right',
					render: (_, record) => {
						const actions: IActionMenuItem[] = [
							// EDIT
							{
								type: 'button',
								title: tCommon('action_buttons.edit'),
								icon: <EditOutlined />,
								disabled: !canEdit,
								actionCb: () => {
									editComponentDrawerRef.current?.open(record);
								},
							},
							// DELETE
							{
								type: 'button',
								title: tCommon('action_buttons.delete'),
								icon: <DeleteOutlined />,
								disabled: !canDelete,
								actionCb: () => handleDeleteGroup(record.id),
							},
						];
						return <ActionsMenu actions={actions} />;
					},
				},
			];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[canEdit]
	);

	// ! render
	const CreateActionButton = useCallback(
		() =>
			canCreate ? (
				<Button
					type='primary'
					onClick={() => editComponentDrawerRef.current?.open()}
				>
					{tCommon('action_buttons.create')}
				</Button>
			) : null,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[canCreate]
	);

	return (
		<PageContainer
			show={withTitle}
			pageHeader={
				<PageHeader
					title={tMobileComponents('page.title')}
					extra={<CreateActionButton />}
				/>
			}
		>
			<TableWrapper<IMobileListTableData, IMobileComponentListResponse>
				searchParam='name'
				columns={columns}
				scrollX={'max-content'}
				refetchData={forceRefreshData}
				requestConfig={listRequestConfig}
				transformDataToTableData={(data) => ({ ...data, key: data.id })}
				tableExtraActions={
					<>
						{extraActions}
						{withTitle ? null : <CreateActionButton />}
					</>
				}
				defaultControlSizes={withTitle ? undefined : 'middle'}
			/>

			<MobileComponentEditAddDrawer
				ref={editComponentDrawerRef}
				onUpdateValues={(values) => {
					const payload: IMobileComponentCreatePayload = {
						...values,
						vertical_ids: values.vertical_ids?.map((entity) => entity.id),
						vendor_ids: values.vendor_ids?.map((entity) => entity.id),
						banner_ids: values.banner_ids?.map((entity) => entity.id),
					};

					if (payload.id) {
						return mobileComponentController.patchComponent(payload).then(refreshingData);
					} else {
						return mobileComponentController.createComponent(payload).then(refreshingData);
					}
				}}
				confirmLoading={
					mobileComponentController.createHttpClient.isLoading ||
					mobileComponentController.patchHttpClient.isLoading
				}
			/>
		</PageContainer>
	);
};
