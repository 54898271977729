import { TRouteObject } from 'configs/routes';
import { EHubTabs } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { HubDefaultSchemaPage } from './HubDefaultSchemaPage/HubDefaultSchemaPage';
import { HubStoreSectionList } from './HubStoreSectionList/HubStoreSectionList';

export const HUB_DETAILS_TAB_ROUTES: Record<EHubTabs, TRouteObject> = {
	[EHubTabs.HUB_SCHEMA]: {
		path: EHubTabs.HUB_SCHEMA,
		element: <HubDefaultSchemaPage />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='schema-builder'
					i18nKey={EHubTabs.HUB_SCHEMA}
					extra={{ keyPrefix: 'enums.EHubTabs' }}
				/>
			),
		},
	},
	[EHubTabs.STORES_LIST_SCHEMA]: {
		path: EHubTabs.STORES_LIST_SCHEMA,
		element: <HubStoreSectionList />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='schema-builder'
					i18nKey={`enums.EHubTabs.${EHubTabs.STORES_LIST_SCHEMA}`}
				/>
			),
		},
	},
};

export const HUB_LIST_TABS_ROUTES: TRouteObject[] = Object.values(HUB_DETAILS_TAB_ROUTES);
