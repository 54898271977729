import TranslatedLabel from 'components/TranslatedLabel';
import { getArrayLengthValidator } from './getArrayLengthValidator';
import { getGreaterEqualThanValidator } from './getGreaterEqualThanValidator';
import { getGreaterThanZeroValidator } from './getGreaterThanZeroValidator';
import { getIsIBanValidator } from './getIsIBanValidator';
import { getLesserEqualThanValidator } from './getLesserEqualThanValidator';
import { getValidateFileSizeValidator } from './getValidateFileSizeValidator';
import { getValidateFileTypeValidator } from './getValidateFileTypeValidator';
import { Rule } from 'antd/es/form';

const PHONE_NUMBER_REGEX_PATTERN = /^(?:[0-9] ?){6,14}[0-9]$/;
const PASSWORD_REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

enum EGenericFormValidators {
	REQUIRED = 'REQUIRED',
	WHITE_SPACE = 'WHITE_SPACE',
	REQUIRED_AND_WHITE_SPACE = 'REQUIRED_AND_WHITE_SPACE',

	// * NUMBER
	NUMBER_LESSER_EQUAL_THAN = 'NUMBER_LESSER_EQUAL_THAN',
	NUMBER_GREATER_EQUAL_THAN = 'NUMBER_GREATER_EQUAL_THAN',
	NUMBER_GREATER_THAN_ZERO = 'NUMBER_GREATER_THAN_ZERO',

	// * ARRAY
	ARRAY_LENGTH = 'ARRAY_LENGTH',

	// * IMAGES
	IMAGE_FILE_TYPES = 'IMAGE_FILE_TYPES',
	IMAGE_FILE_SIZE = 'IMAGE_FILE_SIZE',

	// * OTHERS
	IS_IBAN = 'IS_IBAN',
	PHONE_NUMBER = 'PHONE_NUMBER',
	PASSWORD = 'PASSWORD',
}

// ! MAP
export const FORM_VALIDATORS: Record<EGenericFormValidators, (...args: any[]) => Rule> = {
	[EGenericFormValidators.REQUIRED]: () => ({ required: true }),
	[EGenericFormValidators.WHITE_SPACE]: () => ({ whitespace: true }),
	[EGenericFormValidators.REQUIRED_AND_WHITE_SPACE]: () => ({ required: true, whitespace: true }),
	// * NUMBER
	[EGenericFormValidators.NUMBER_LESSER_EQUAL_THAN]: getLesserEqualThanValidator,
	[EGenericFormValidators.NUMBER_GREATER_EQUAL_THAN]: getGreaterEqualThanValidator,
	[EGenericFormValidators.NUMBER_GREATER_THAN_ZERO]: getGreaterThanZeroValidator,
	// * ARRAY
	[EGenericFormValidators.ARRAY_LENGTH]: getArrayLengthValidator,
	// * IMAGES
	[EGenericFormValidators.IMAGE_FILE_TYPES]: getValidateFileTypeValidator,
	[EGenericFormValidators.IMAGE_FILE_SIZE]: getValidateFileSizeValidator,

	// * OTHERS
	[EGenericFormValidators.IS_IBAN]: getIsIBanValidator,
	[EGenericFormValidators.PHONE_NUMBER]: () => ({
		pattern: PHONE_NUMBER_REGEX_PATTERN,
		message: <TranslatedLabel i18nKey='form.phone_number.pattern_validation' />,
	}),
	[EGenericFormValidators.PASSWORD]: () => ({
		pattern: PASSWORD_REGEX_PATTERN,
		message: <TranslatedLabel i18nKey='form_validations.invalid_password_pattern' />,
	}),
};
