import { AxiosRequestConfig } from 'axios';
import { THttpRequestConfig, THttpRequestParams } from 'hooks';

export const DEEP_LINKS_API = {
	vendorList: (name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/deep-links/vendors',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),
	storeList: (name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/deep-links/stores',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),
	menuList: (search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/deep-links/menus',
		method: 'GET',
		params: { name: search, ...params },
	}),
	categoryList: (menuId: number, search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/deep-links/menus/${menuId}/categories`,
		method: 'GET',

		params: { search, ...params },
	}),
	categoryProductList: (
		menuId: number,
		categoryId: number,
		search?: string,
		params?: THttpRequestParams
	): THttpRequestConfig => ({
		url: `operations/deep-links/menus/${menuId}/category/${categoryId}/products`,
		method: 'GET',
		params: { search, ...params },
	}),
	subCategoryList: (
		menuId: number,
		categoryId: number,
		search?: string,
		params?: THttpRequestParams
	): THttpRequestConfig => ({
		url: `operations/deep-links/menus/${menuId}/categories/${categoryId}/sub_categories`,
		method: 'GET',

		params: { search, ...params },
	}),
	subCategoryProductList: (
		menuId: number,
		categoryId: number,
		subCategoryId: number,
		search?: string,
		params?: THttpRequestParams
	): THttpRequestConfig => ({
		url: `operations/deep-links/menus/${menuId}/categories/${categoryId}/sub_categories/${subCategoryId}/products`,
		method: 'GET',

		params: { search, ...params },
	}),
	getVerticals: (verticalId: number, name?: string, params?: THttpRequestParams): AxiosRequestConfig => ({
		url: '/operations/deep-links/verticals',
		method: 'GET',
		params: {
			...params,
			parent_id: verticalId,
			name,
		},
	}),
};
