import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getIsValidSnoozeTimeValidator = (errorMessage: string, isCustomTime: boolean): ValidatorRule => ({
	validator: (_, time) => {
		if (!isCustomTime) {
			return Promise.resolve();
		}

		if (typeof time === 'undefined' || !time || (!time.hour() && !time.minute())) {
			return Promise.reject(new Error(errorMessage));
		}

		return Promise.resolve();
	},
});
