import { Nullable } from 'types/common';
import { EDeepLinkPathPages } from './types';

export const generateDeepLinkFromEDeepLinkPathPages = (
	brand: string,
	path: EDeepLinkPathPages,
	linkParams: Record<string, string | number | undefined>
) => {
	const deepLinkBaseUrl = `https://${brand}.app`;

	const regExp = /\{([^}]+)\}/g;

	const pathSuffix = path.replace(regExp, (match, key) => `${linkParams[key] || match}`);

	return `${deepLinkBaseUrl}${pathSuffix}`;
};

export const getMatchDeepLinkPaths = (
	value: string | undefined
): Array<[EDeepLinkPathPages, Nullable<RegExpExecArray>]> => {
	if (!value?.length) return [];

	try {
		const urlValue = new URL(value);

		const currentValue = urlValue.pathname + urlValue.search;

		const allValidResults = Object.values(EDeepLinkPathPages)
			//
			.map<[EDeepLinkPathPages, Nullable<RegExpExecArray>]>((path) => {
				// Convert path to regex pattern
				const regexString = path
					.replace(/\?/g, '\\?') // escape "?"
					.replace(/&/g, '\\&') // escape "&"
					.replace(/=/g, '\\=') // escape "="
					.replace(/{(\w+)}/g, '(?<$1>[^&]+)'); // capture values

				// Full string match
				const regex = new RegExp(`^${regexString}$`, 'i');
				// Apply regex
				const regexResult = regex.exec(currentValue);
				// Return/save result
				return [path, regexResult];
			})
			// filter/remove the null values
			.filter((result) => result[1] !== null)
			// sort items by value length (descending order)
			.sort((a, b) => b[1]!.length - a[1]!.length);

		return allValidResults;
	} catch (e) {
		return [];
	}
};
