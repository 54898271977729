import { FC } from 'react';
import { DroppableContainer, EContainerTypes } from './DndContainers';

export const EmptyDnDContainer: FC<{ position: number }> = ({ position }) => (
	<DroppableContainer
		id={`empty_${position}`}
		type={EContainerTypes.EMPTY}
		content={{ position }}
	/>
);
