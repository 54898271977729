import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import { DeepLinkInput } from './DeepLinkInput';
import { Col, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

const DeepLinksPage: FC = () => {
	const { t: tDeepLinks } = useTranslation('deep-links');

	// ! render
	return (
		<PageLayout header={<PageHeader title={tDeepLinks('title')} />}>
			<Row>
				<Col span={24}>
					<DeepLinkInput />
				</Col>
			</Row>
		</PageLayout>
	);
};

export default DeepLinksPage;
