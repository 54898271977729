import { FC, Key, useEffect, useState } from 'react';
import { THttpRequestConfig, THttpRequestParams, useNewHttpClient } from 'hooks';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { IGenericVendor } from 'types/api';
import { IListResponse } from 'types/common';
import { ISegmentOption } from 'components/_input-components/GenericListSelectors';
import { transformIGenericListToISegmentOption } from 'components/_input-components/GenericListSelectors/helper';
import { Select, SelectProps } from 'antd';

export interface ISelectSimpleFetchProps extends SelectProps {
	requestConfig?: (search?: string, params?: THttpRequestParams) => THttpRequestConfig;
}

export const SelectSimpleFetch: FC<ISelectSimpleFetchProps> = ({ requestConfig, ...props }) => {
	// ! http clients

	const fetchVendorsHttpClient = useNewHttpClient<IListResponse<IGenericVendor>>();
	// ! states
	const [options, setOptions] = useState<ISegmentOption[]>([]);

	// ! handlers
	const handleSearch = (value?: string, newOptions: ISegmentOption[] = options) => {
		if (!requestConfig) {
			return;
		}

		const searchValue = value?.trim() || undefined; // never send an empty string

		return fetchVendorsHttpClient.request({
			requestConfig: requestConfig(searchValue),
			displayErrorMsg: false,
			successCallback: ({ data }) => {
				const resultingOptions = transformIGenericListToISegmentOption(data);

				const optionSet = newOptions.concat(resultingOptions).reduce((acc, item) => {
					acc[item.value] = item;
					return acc;
				}, {} as Record<Key, ISegmentOption>);

				setOptions(Object.values(optionSet));
			},
		});
	};

	const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! effects
	useEffect(() => {
		handleSearch(undefined, []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestConfig]);

	// ! render
	return (
		<Select
			allowClear
			showSearch
			maxTagCount={3}
			options={options}
			popupMatchSelectWidth={false}
			loading={fetchVendorsHttpClient.isLoading}
			filterOption={(inputValue, option) => {
				return option?.key?.includes(inputValue.toLowerCase());
			}}
			onSearch={debouncedHandleSearch}
			{...props}
		/>
	);
};
