import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode, isUnitedArabEmiratesCountry } from 'store/selectors';
import { useGetFormValues } from 'hooks';
import { CUSTOMER_ADDRESS_TYPE_OPTION } from './helpers';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { CenterMapComponent } from 'utils/Map/CenterMapComponent';
import { PlaceAutocomplete } from 'utils/Map/PlaceAutocomplete';
import { debounce } from 'utils/debounce';
import { DEFAULT_MODAL_PROPS, LAT_REGEX_PATTERN, LNG_REGEX_PATTERN } from 'configs/common';
import { ECustomerAddressType, ICustomerAddressPayloadData } from 'types/api';
import { Nullable } from 'types/common';
import { ICreateCustomerAddressForm, ICustomerAddressEditModalProps } from './types';
import Spinner from 'components/Spinner';
import VisCommonMap, { MAP_ID } from 'components/VisCommonMap';
import styles from './CustomerAddressModal.module.css';
import { App, Col, Form, Input, Modal, Row, Select } from 'antd';
import { AdvancedMarker, ControlPosition, MapControl, MapMouseEvent } from '@vis.gl/react-google-maps';

const CustomerAddressEditModal: FC<ICustomerAddressEditModalProps> = ({
	isOpen,
	editingAddress,
	confirmLoading,
	onConfirm,
	onClose,
}) => {
	const { message } = App.useApp();
	const { getFormValues } = useGetFormValues();
	const [form] = Form.useForm<ICreateCustomerAddressForm>();

	const { t: tCustomerAddress } = useTranslation('customers', { keyPrefix: 'customer_details.tabs.addresses' });

	const [isLoadingGeoCoding, setIsLoadingGeoCoding] = useState(false);

	const [currMapPointPosition, setCurrMapPointPosition] = useState<Nullable<google.maps.LatLng>>(null);

	// ! selectors
	const selectedCountryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);
	const isUAE = useSelector(isUnitedArabEmiratesCountry);

	// ! handlers
	const onAfterCloseModal = () => {
		// on close reset the data
		form.resetFields();
		setCurrMapPointPosition(null);
	};

	const onFormSubmit = async () => {
		const formValues = await getFormValues(form);
		if (!formValues || !selectedCountryIsoTwoCode) return;
		if (!currMapPointPosition) {
			message.error(tCustomerAddress('edit_address_modal.submit_error'), 3);
			return;
		}

		const data: ICustomerAddressPayloadData = {
			country_iso_code: selectedCountryIsoTwoCode,
			...formValues,
		};
		onConfirm(data);
	};

	const handleSettingNewLocationDebounceFn = debounce(
		() =>
			handleSettingNewLocation(
				new google.maps.LatLng(form.getFieldValue('latitude'), form.getFieldValue('longitude')),
				true
			),
		500
	);

	const preFillAddressFromGeoCode = (props: google.maps.GeocoderResponse) => {
		interface IAddressComponent {
			type: string;
			value: string;
		}
		const flattenGeoCodeInformation = props.results.reduce<IAddressComponent[]>((acc, addressValue) => {
			const flatAddressComponents = addressValue.address_components.reduce<IAddressComponent[]>(
				(accAddComponent, valueAddComponent) =>
					accAddComponent.concat(
						valueAddComponent.types.map((addressComponentType) => ({
							type: addressComponentType,
							value: valueAddComponent.short_name,
						}))
					),
				[]
			);

			return acc.concat(flatAddressComponents);
		}, []);

		const getAddressComponentByName = (type: 'route' | 'neighborhood' | 'locality' | 'sublocality_level_1') =>
			flattenGeoCodeInformation.find((addressComponent) => addressComponent.type === type)?.value;

		const street = getAddressComponentByName('route');
		const neighborhood = getAddressComponentByName('neighborhood');
		const locality = getAddressComponentByName('locality');
		const sublocality_level_1 = getAddressComponentByName('sublocality_level_1');

		// ? DEV NOTE: map of fields by country --------------------
		// 'field_1' - Area (KW & SA & AE)
		// 'field_2' - Block (KW) || Neighborhood (SA)
		// 'field_3' - Street (KW & SA & AE)
		// 'field_4' - Avenue (KW) || Lane/Pathway (SA)
		// 'field_5' - Building number (KW & SA) || Building name (AE)
		// 'field_6' - Floor number (KW & SA & AE)
		// 'field_7' - Door number (KW & SA & AE)
		// 'field_8' - Additional Instructions (KW & SA & AE)
		// ? --------------------------- ---------------------------

		if (isUAE) {
			// ! for UAE country
			form.setFieldsValue({
				fields: [
					{ key: 'field_1', value: locality },
					{ key: 'field_3', value: street },
					{ key: 'field_5', value: '' },
					{ key: 'field_6', value: '' },
					{ key: 'field_7', value: '' },
					{ key: 'field_8', value: '' },
				],
			});
		} else {
			// ! for KW & SA countries
			form.setFieldsValue({
				fields: [
					{ key: 'field_1', value: locality },
					{ key: 'field_2', value: [sublocality_level_1, neighborhood].filter((id) => id).join(' - ') },
					{ key: 'field_3', value: street },
					{ key: 'field_4', value: '' },
					{ key: 'field_5', value: '' },
					{ key: 'field_6', value: '' },
					{ key: 'field_7', value: '' },
					{ key: 'field_8', value: '' },
				],
			});
		}
	};

	const handleSettingNewLocation = (latLng: Nullable<google.maps.LatLng>, preFillAddress: boolean) => {
		setCurrMapPointPosition(latLng);

		if (latLng) {
			setIsLoadingGeoCoding(true);
			const geocoder = new google.maps.Geocoder();
			// get address and fill lat_lng form data
			geocoder
				.geocode({ location: latLng }, (results, status) => {
					if (status !== google.maps.GeocoderStatus.OK || !results?.length) return;

					form.setFieldsValue({
						latitude: `${latLng?.lat()}`,
						longitude: `${latLng?.lng()}`,
					});
				})
				.then((props: google.maps.GeocoderResponse) => {
					if (preFillAddress) preFillAddressFromGeoCode(props);
				})
				.finally(() => {
					setIsLoadingGeoCoding(false);
				});
		}
	};

	// ! effects
	useEffect(() => {
		if (!editingAddress) return;

		const { address_name, address_type, lat, lng, fields } = editingAddress;
		// remove the 'null' values
		fields.forEach((item) => (item.value = item.value ?? ''));

		const latNumber = Number.parseFloat(lat);
		const lngNumber = Number.parseFloat(lng);
		const googleLatLng = new google.maps.LatLng({ lat: latNumber, lng: lngNumber });

		// * set edited data on form
		handleSettingNewLocation(googleLatLng, false);
		form.setFieldsValue({ address_name, address_type, fields });
	}, [editingAddress, form]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<>
			<Modal
				{...DEFAULT_MODAL_PROPS}
				open={isOpen}
				className={styles.modal}
				title={tCustomerAddress('edit_address_modal.title', { id: editingAddress?.id })}
				confirmLoading={confirmLoading}
				onOk={onFormSubmit}
				onCancel={onClose}
				afterClose={onAfterCloseModal}
			>
				{!selectedCountryIsoTwoCode && <Spinner defaultAntdSpinner />}

				<Form
					form={form}
					layout='vertical'
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name='address_name'
								label={tCustomerAddress('shared.name')}
								rules={[FORM_VALIDATORS.WHITE_SPACE()]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name='address_type'
								label={tCustomerAddress('shared.type')}
								initialValue={ECustomerAddressType.HOUSE}
								rules={[FORM_VALIDATORS.REQUIRED()]}
							>
								<Select
									disabled
									options={CUSTOMER_ADDRESS_TYPE_OPTION}
									placeholder={tCustomerAddress('edit_address_modal.select_address_type_placeholder')}
								/>
							</Form.Item>
						</Col>

						{/* DYNAMIC "ADDRESS FIELDS" LIST */}
						{editingAddress?.fields?.map(({ key, label, visible, required }, index) =>
							visible ? (
								<Col
									span={12}
									key={index}
								>
									<Form.Item
										hidden
										name={['fields', index, 'key']}
										initialValue={key}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name={['fields', index, 'value']}
										label={label}
										rules={[{ required, ...FORM_VALIDATORS.WHITE_SPACE() }]}
									>
										{isLoadingGeoCoding ? <Input.Search loading /> : <Input />}
									</Form.Item>
								</Col>
							) : null
						)}

						<Col span={12}>
							<Form.Item
								name='latitude'
								label={tCustomerAddress('shared.latitude')}
								initialValue=''
								rules={[
									FORM_VALIDATORS.REQUIRED(),
									{
										pattern: LAT_REGEX_PATTERN,
										message: tCustomerAddress('edit_address_modal.invalid_latitude'),
									},
								]}
							>
								<Input
									placeholder={tCustomerAddress('edit_address_modal.select_address_placeholder')}
									onChange={handleSettingNewLocationDebounceFn}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name='longitude'
								label={tCustomerAddress('shared.longitude')}
								initialValue=''
								rules={[
									FORM_VALIDATORS.REQUIRED(),
									{
										pattern: LNG_REGEX_PATTERN,
										message: tCustomerAddress('edit_address_modal.invalid_longitude'),
									},
								]}
							>
								<Input
									placeholder={tCustomerAddress('edit_address_modal.select_address_placeholder')}
									onChange={handleSettingNewLocationDebounceFn}
								/>
							</Form.Item>
						</Col>

						<Col
							span={24}
							className={styles.map_wrapper_col}
						>
							<VisCommonMap
								mapId={MAP_ID.POI}
								defaultZoom={13}
								defaultCenter={currMapPointPosition ? currMapPointPosition.toJSON() : undefined}
								onClick={(e: MapMouseEvent) => {
									if (e.detail.latLng) {
										handleSettingNewLocation(new google.maps.LatLng(e.detail.latLng), true);
									}
								}}
							>
								<CenterMapComponent newCoordinates={currMapPointPosition?.toJSON()} />

								<MapControl position={ControlPosition.TOP}>
									<PlaceAutocomplete
										onPlaceSelect={(place) => {
											if (place?.geometry?.location) {
												handleSettingNewLocation(place?.geometry?.location, true);
											}
										}}
									/>
								</MapControl>

								{currMapPointPosition && <AdvancedMarker position={currMapPointPosition.toJSON()} />}
							</VisCommonMap>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default CustomerAddressEditModal;
