import { RuleObject } from 'antd/es/form';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getArrayLengthValidator = ({
	minLength = -1,
	maxLength,
}: {
	minLength?: number;
	maxLength?: number;
}): ValidatorRule => ({
	validator: (_: RuleObject, value: any[]) => {
		if (!value) {
			return Promise.resolve();
		}

		maxLength = maxLength ?? value.length + 1;

		if (value.length < minLength) {
			return Promise.reject();
		}

		if (maxLength < value.length) {
			return Promise.reject();
		}

		return Promise.resolve();
	},
});
