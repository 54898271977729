import { FC } from 'react';
import { ORDER_DELIVERY_TIME_OPTIONS_LIST } from './configs';
import { ISelectOrderDeliveryTimeProps } from './types';
import { Select } from 'antd';

export const OrderDeliveryTimeSelect: FC<ISelectOrderDeliveryTimeProps> = (props) => {
	// ! render
	return (
		<Select
			showSearch
			options={ORDER_DELIVERY_TIME_OPTIONS_LIST}
			{...props}
		/>
	);
};
