import { Dispatch, FC, useEffect } from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { classNames } from 'utils/classNames';
import { ALL_SERVICES_API } from 'configs/api/applicationConfiguration/allServicesConfiguration';
import { IListResponse } from 'types/common';
import { EStatus } from 'types/common';
import { IVerticalGroupListResponse } from './types';
import Spinner from 'components/Spinner';
import styles from './VerticalGroupPreview.module.css';
import { Button, Empty, Flex, Typography as T, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface IVerticalGroupPreviewProps {
	forceRefreshData: number;
	handleOpenVerticalGroup: Dispatch<IVerticalGroupListResponse>;
}

export const VerticalGroupPreview: FC<IVerticalGroupPreviewProps> = ({ forceRefreshData, handleOpenVerticalGroup }) => {
	const { t: tAllServicesConfiguration } = useTranslation('all-services-configuration');

	// ! http clients
	const httpClient = useNewHttpClient<IListResponse<IVerticalGroupListResponse>>();

	// ! effects
	useEffect(
		() => {
			httpClient.request({
				requestConfig: ALL_SERVICES_API.list({
					sort_by: 'position',
					order_by: 'ASC',
					status: EStatus.ACTIVE,
					per_page: '500',
				}),
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[forceRefreshData]
	);

	// ! render
	return (
		<Flex
			align='center'
			justify='center'
			className='h-100'
			style={{ minHeight: 1000 }}
		>
			<DeviceFrameset
				color='silver'
				device='iPhone 8 Plus'
			>
				<Flex
					vertical
					gap={12}
					className={classNames(styles.device_content, styles.custom_scrollbar)}
				>
					{httpClient.isLoading && <Spinner defaultAntdSpinner />}

					{!(httpClient.response?.data.length || httpClient.isLoading) && (
						<Flex
							align='center'
							justify='center'
							className='h-100'
						>
							<Empty />
						</Flex>
					)}

					{httpClient.response?.data.map((verticalGroup) => {
						return (
							<div
								key={verticalGroup.id}
								className={styles.vertical_group}
							>
								<T.Title
									level={4}
									onClick={() => handleOpenVerticalGroup(verticalGroup)}
								>
									<Flex
										align='center'
										gap='small'
									>
										{verticalGroup.title}

										<Button
											icon={<EditOutlined />}
											type='text'
										/>
									</Flex>
								</T.Title>

								<T.Paragraph>
									<Flex
										gap={12}
										className={classNames(styles.vertical_group_list, styles.custom_scrollbar)}
									>
										{verticalGroup.verticals.map((vertical) => {
											return (
												<Tooltip
													key={vertical.id}
													styles={{ body: { width: 'fit-content' } }}
													title={
														<div>
															<Flex gap='small'>
																<span>{tAllServicesConfiguration('entity.id')}</span>
																<span>{vertical.id}</span>
															</Flex>
															<Flex gap='small'>
																<span>
																	{tAllServicesConfiguration('entity.action')}
																</span>
																<span>{vertical.action}</span>
															</Flex>
														</div>
													}
												>
													<Flex
														vertical
														gap={4}
														align='center'
														className={styles.vertical_card}
														justify='space-between'
													>
														<img
															src={vertical.image}
															alt='vertical-image'
														/>
														<div className={styles.title}>{vertical.name}</div>
													</Flex>
												</Tooltip>
											);
										})}
									</Flex>
								</T.Paragraph>
							</div>
						);
					})}
				</Flex>
			</DeviceFrameset>
		</Flex>
	);
};
