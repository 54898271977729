import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'utils/validationUtils/isNumber';
import { IVendorBranchDeliveryArea } from 'types/api';
import { EDeliveryFeeOptions, IDeliveryFeeSectionProps } from './types';
import styles from './DeliveryFeeSection.module.css';
import { Flex, InputNumber, Radio, RadioChangeEvent, Typography as T } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const DeliveryFeeSection: FC<IDeliveryFeeSectionProps> = ({
	areaId,
	sectionLabel,
	selectedFee,
	setSelectedDeliveryFeeMap,
	disabled = false,
}) => {
	const { t: tVendorBranchDeliveryArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area',
	});

	// ! states
	const [selectedRadioOption, setSelectedRadioOption] = useState<EDeliveryFeeOptions>(
		EDeliveryFeeOptions.FROM_PARENT
	);
	const [inputFeeValue, setInputFeeValue] = useState<IVendorBranchDeliveryArea['delivery_fee']>(0);

	// ! memos
	const isFeeInputDisabled = useMemo(() => {
		return disabled || selectedRadioOption === EDeliveryFeeOptions.FROM_PARENT;
	}, [disabled, selectedRadioOption]);

	// ! handlers
	const updateAreaDeliveryFee = (option: EDeliveryFeeOptions, value: IVendorBranchDeliveryArea['delivery_fee']) => {
		setSelectedDeliveryFeeMap((prev) => {
			const newState = { ...prev };
			newState[areaId] = option === EDeliveryFeeOptions.FROM_PARENT ? null : value;
			return newState;
		});

		if (option === EDeliveryFeeOptions.FROM_PARENT) {
			setInputFeeValue(0);
		}
	};

	const onInputChange = (newValue: IVendorBranchDeliveryArea['delivery_fee']) => {
		setInputFeeValue(newValue ?? 0);

		// update area delivery fee
		if (isNumber(newValue)) {
			updateAreaDeliveryFee(EDeliveryFeeOptions.SPECIFIC_FEE, newValue);
		}
	};

	const onChangeSelectedOption = ({ target }: RadioChangeEvent) => {
		const selectedOption: EDeliveryFeeOptions = target.value;

		// update selected option value
		setSelectedRadioOption(selectedOption);

		// update area delivery fee
		updateAreaDeliveryFee(selectedOption, inputFeeValue);
	};

	// ! effects
	// * set the correct fee value/option when the area id or selected fee changes
	useEffect(() => {
		setInputFeeValue(selectedFee ?? 0);
		setSelectedRadioOption(
			selectedFee !== null ? EDeliveryFeeOptions.SPECIFIC_FEE : EDeliveryFeeOptions.FROM_PARENT
		);
	}, [areaId, selectedFee]);

	// ! render
	return (
		<Flex
			gap={6}
			vertical
		>
			{/* LABEL */}
			<T.Text>{sectionLabel}:</T.Text>

			{/* OPTIONS */}
			<Radio.Group
				size='small'
				disabled={disabled}
				value={selectedRadioOption}
				onChange={onChangeSelectedOption}
			>
				<Flex
					gap={6}
					vertical
				>
					<Radio value={EDeliveryFeeOptions.FROM_PARENT}>
						{tVendorBranchDeliveryArea('delivery_fee.options.from_parent')}
					</Radio>

					<Radio value={EDeliveryFeeOptions.SPECIFIC_FEE}>
						<Flex gap='small'>
							{tVendorBranchDeliveryArea('delivery_fee.options.specific_fee')}
							<InputNumber
								size='small'
								min={0}
								step='.01'
								value={inputFeeValue}
								style={{ maxWidth: '80px' }}
								disabled={isFeeInputDisabled}
								onChange={onInputChange}
							/>
						</Flex>
					</Radio>
				</Flex>
			</Radio.Group>

			{/* ALERT MSG */}
			{!isFeeInputDisabled && isNumber(inputFeeValue) && (
				<T.Text className={styles.alert_msg}>
					<ExclamationCircleOutlined />
					&nbsp;
					{tVendorBranchDeliveryArea('delivery_fee.alert', { fee: inputFeeValue })}
				</T.Text>
			)}
		</Flex>
	);
};

export default memo(DeliveryFeeSection);
