import { FC, PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import { Nullable } from 'types/common';
import { IHomeDnDContentElement } from './types';
import { EContainerTypes } from 'pages/HomeAppConfiguration/SchemaBuilder/components/DndContainers';
import { SchemaComponent } from 'pages/HomeAppConfiguration/components/SchemaComponent/SchemaComponent';
import { useSchemaBuilder } from './useSchemaBuilder';
import { DndContext, DragOverlay, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export const DnDContextWrapper: FC<PropsWithChildren> = ({ children }) => {
	const {
		isSchemaBuilderByDrag,
		isSchemaBuilderByInjection,
		itemList,
		setItemList,
		activeElement,
		setActiveElement,
	} = useSchemaBuilder();

	// ! states
	const [clonedItems, setClonedItems] = useState<Nullable<IHomeDnDContentElement[]>>(null);

	// ! handlers
	const findItem = (id: UniqueIdentifier) => {
		return itemList.findIndex((item) => item.dndId === id);
	};

	const onDragCancel = () => {
		if (clonedItems) {
			// Reset items to their original state in case items have been
			// Dragged across containers
			setItemList(clonedItems);
		}

		setActiveElement(null);
		setClonedItems(null);
	};

	// ! render
	return (
		<>
			<DndContext
				onDragStart={({ active }) => {
					const activeElementData = active.data.current as IHomeDnDContentElement;

					const newElementData = {
						...activeElementData,

						type: activeElementData.type!,
						isTemplateComponent: !!activeElementData.isTemplateComponent,
					};

					setActiveElement(newElementData);

					setClonedItems(itemList);
				}}
				onDragOver={({ active, over }) => {
					const overId = over?.id;

					if (overId == null) {
						return;
					}

					const overContainer = findItem(overId);
					const activeContainer = findItem(active.id);

					if (overContainer == null || activeContainer == null) {
						return;
					}
				}}
				onDragCancel={onDragCancel}
				onDragEnd={({ active, over }) => {
					const activeElementData = active.data.current as IHomeDnDContentElement;

					if (activeElementData.isTemplateComponent) {
						// Clone
						const overId = over?.id;
						const overElementData = over?.data.current as IHomeDnDContentElement;

						const dropIndex = overElementData?.position;

						if (
							overId &&
							overElementData &&
							overElementData.containerType === EContainerTypes.EMPTY &&
							dropIndex !== undefined
						) {
							const newElement: IHomeDnDContentElement = {
								...activeElementData,
								dndId: crypto.randomUUID(),
							};
							delete newElement?.isTemplateComponent;

							setItemList((prev) => {
								const newState = [...prev];

								newState.splice(
									//
									+dropIndex,
									0,
									newElement
								);

								return newState;
							});
						}

						if (overId && overElementData && overElementData.containerType === EContainerTypes.REPLACE) {
							const newElement: IHomeDnDContentElement = {
								...activeElementData,
								dndId: crypto.randomUUID(),
							};
							delete newElement?.isTemplateComponent;

							const newElementIndex = (overElementData as any).sortable.index;

							setItemList((prev) => {
								const newState = [...prev];

								newState[newElementIndex] = newElement;

								return newState;
							});
						}
					}

					const activeContainerIndex = findItem(active.id);

					if (activeContainerIndex == null) {
						setActiveElement(null);
						return;
					}

					const overId = over?.id;

					if (overId == null) {
						setActiveElement(null);
						return;
					}
					const overContainerIndex = findItem(overId);

					if (active.id !== over?.id) {
						setItemList((items) => arrayMove(items, activeContainerIndex, overContainerIndex));
					}

					setActiveElement(null);
				}}
			>
				{children}

				{createPortal(
					<DragOverlay>
						{activeElement && activeElement.type ? (
							<SchemaComponent
								id={activeElement.dndId.toString()}
								type={activeElement.type}
								label={activeElement.component?.name ?? activeElement.dndId}
								content={activeElement}
								extraCommands={
									activeElement.isTemplateComponent
										? undefined
										: {
												dragHandle: isSchemaBuilderByDrag,
												remove: true,
												injectOnPosition: isSchemaBuilderByInjection,
										  }
								}
							/>
						) : null}
					</DragOverlay>,
					document.body
				)}
			</DndContext>
		</>
	);
};
