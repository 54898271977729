import { THttpRequestConfig } from 'hooks';
import { ISchemaPublish } from 'types/api/schemaBuilder';

export const SCHEMA_HOME_API = {
	getSchema: (): THttpRequestConfig => ({
		url: 'operations/app-management/home',
		method: 'GET',
	}),
	publishSchema: (data: ISchemaPublish): THttpRequestConfig => ({
		url: 'operations/app-management/home',
		method: 'POST',
		data,
	}),
};
