import { useTranslation } from 'react-i18next';
import { DeviceMainSection } from './DeviceMainSection';
import { DnDContextWrapper } from './DnDContextWrapper';
import { SchemaComponentLibrary } from './SchemaComponentLibrary';
import { Provider, useSchemaBuilder } from './useSchemaBuilder';
import { Button } from 'antd';

const SaveButton = ({ ...props }) => {
	const { t: tCommon } = useTranslation();

	// ! handlers
	const { onSave } = useSchemaBuilder();

	// ! render
	return (
		<Button
			type='primary'
			className='min-w-save-btn'
			onClick={onSave}
			{...props}
		>
			{tCommon('action_buttons.save')}
		</Button>
	);
};

// ! MAIN COMPONENT
const SchemaBuilder = () => {};

SchemaBuilder.Provider = Provider;
SchemaBuilder.SaveButton = SaveButton;
SchemaBuilder.DnDContextWrapper = DnDContextWrapper;
SchemaBuilder.DeviceMainSection = DeviceMainSection;
SchemaBuilder.SchemaComponentLibrary = SchemaComponentLibrary; // Schema Component SideBar

export default SchemaBuilder;
