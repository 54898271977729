import { FC } from 'react';
import { EVerticalType } from 'types/api';
import { ISelectOption } from 'types/common';
import { EDeepLinkPathPages, IDeepLinkConfig, IDeepLinkInputConfigProps } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { CustomerStoreOfferTypeSelect } from 'components/_input-components/CustomerStoreOfferTypeSelect';
import { OfferLabelSelect, SelectVendor, VerticalsSelect } from 'components/_input-components/GenericListSelectors';
import { MenuPurposeSelect } from 'components/_input-components/MenuPurposeSelect';
import SelectDeepLinkVerticalChild from 'pages/HomeAppConfiguration/DeepLinksPage/Selectors/SelectDeepLinkVerticalChild';
import { SelectDeepLinkMenu } from './Selectors/SelectDeepLinkMenu';
import { SelectDeepLinkMenuCategory } from './Selectors/SelectDeepLinkMenuCategory';
import { SelectDeepLinkMenuCategoryProduct } from './Selectors/SelectDeepLinkMenuCategoryProduct';
import { SelectDeepLinkStore } from './Selectors/SelectDeepLinkStore';
import { StoreWithRegCodeSelect } from './Selectors/StoreWithRegCodeSelect';
import { Input, InputProps, Select, SelectProps } from 'antd/lib';

const REDIRECTION_TYPE_SELECT_OPTIONS: ISelectOption[] = ['external', 'internal'].map((type) => ({
	value: type,
	label: (
		<TranslatedLabel
			nameSpace='deep-links'
			i18nKey={`enums.redirection_type.${type}`}
		/>
	),
}));

// ! Default Input Params Components
const WebViewLinkInputText: FC<IDeepLinkInputConfigProps & InputProps> = ({ onChange, ...props }) => (
	<Input
		onChange={(ev) => onChange?.(ev.target.value)}
		{...props}
	/>
);

const WebViewRedirectionTypSelect: FC<SelectProps> = (props) => (
	<Select
		options={REDIRECTION_TYPE_SELECT_OPTIONS}
		{...props}
	/>
);

// ! TRANSLATION KEYS CONFIG
export const DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG: Record<EDeepLinkPathPages, string> = {
	// Home
	[EDeepLinkPathPages.HOME_SCREEN]: 'home_screen',
	// All Services
	[EDeepLinkPathPages.ALL_SERVICES_SCREEN]: 'all_services_screen',
	// Hub Specific
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL]: 'hub_screen_generic_specific_vertical',
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL]:
		'hub_screen_generic_specific_vertical_and_sub_vertical',
	// [EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY]: 'hub_screen_box_delivery', // ? TEMP HIDE
	// Store Details
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID]: 'store_details_by_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID]: 'store_details_by_id_and_menu_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID]:
		'store_details_by_id_and_menu_id_and_category_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]:
		'store_details_by_id_and_menu_id_and_category_id_and_product_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE]: 'store_details_by_id_and_menu_purpose',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE]: 'store_details_by_cam_reg_code',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID]: 'store_details_by_cam_reg_code_and_menu_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID]:
		'store_details_by_cam_reg_code_and_menu_id_and_category_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]:
		'store_details_by_cam_reg_code_and_menu_id_and_category_id_and_product_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE]:
		'store_details_by_cam_reg_code_and_menu_purpose',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID]: 'store_details_by_vendor_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID]: 'store_details_by_vendor_id_and_menu_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID]:
		'store_details_by_vendor_id_and_menu_id_and_category_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]:
		'store_details_by_vendor_id_and_menu_id_and_category_id_and_product_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE]: 'store_details_by_vendor_id_and_menu_purpose',
	// Wallet
	[EDeepLinkPathPages.WALLET]: 'wallet',
	// Offers
	[EDeepLinkPathPages.OFFERS]: 'offers',
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE]: 'offers_by_offer_type',
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID]: 'offers_by_offer_type_and_offer_label_id',
	// Account
	[EDeepLinkPathPages.ACCOUNT_SCREEN]: 'account_screen',
	[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN]: 'account_favorites_screen',
	[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN]: 'account_addresses_screen',
	[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN]: 'account_cards_screen',
	[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN]: 'account_cari_unlimited_screen',
	[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN]: 'account_cari_prize_screen',
	[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN]: 'account_language_screen',
	[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN]: 'account_about_us_screen',
	// Cart
	[EDeepLinkPathPages.CART]: 'cart',
	// WebView
	[EDeepLinkPathPages.WEBVIEW]: 'webview',
};

// ! DEPP LINK MAP
export const DEEP_LINK_CONFIG_MAP: Record<EDeepLinkPathPages, IDeepLinkConfig> = {
	// Home
	[EDeepLinkPathPages.HOME_SCREEN]: {
		path: EDeepLinkPathPages.HOME_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.HOME_SCREEN],
	},
	// All Services
	[EDeepLinkPathPages.ALL_SERVICES_SCREEN]: {
		path: EDeepLinkPathPages.ALL_SERVICES_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ALL_SERVICES_SCREEN],
	},
	// Hub Specific
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL]: {
		path: EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL],
		params: [
			{
				key: 'verticalId',
				slug: 'vertical_id',
				InputEl: (props) => (
					<VerticalsSelect
						types={[EVerticalType.INDEPENDENT, EVerticalType.PARENT]}
						{...props}
					/>
				),
			},
		],
	},
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL]: {
		path: EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL
			],
		params: [
			{
				key: 'verticalId',
				slug: 'vertical_id',
				InputEl: (props) => (
					<VerticalsSelect
						types={[EVerticalType.PARENT]}
						{...props}
					/>
				),
			},
			{
				key: 'childVerticalId',
				slug: 'child_verticalId_id',
				InputEl: (props) => (
					<SelectDeepLinkVerticalChild
						parent_id={props.extra.verticalId}
						{...props}
					/>
				),
			},
		],
	},
	// ? TEMP HIDE
	// [EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY]: {
	// 	path: EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY,
	// 	translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY],
	// },
	// ? --------

	// Store Details
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: SelectDeepLinkStore,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: SelectDeepLinkStore,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: SelectDeepLinkStore,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: SelectDeepLinkMenuCategory,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
			],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: SelectDeepLinkStore,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: SelectDeepLinkMenuCategory,
			},
			{
				key: 'productId',
				slug: 'product_id',
				InputEl: SelectDeepLinkMenuCategoryProduct,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: SelectDeepLinkStore,
			},
			{
				key: 'menuPurpose',
				slug: 'menu_purpose',
				InputEl: MenuPurposeSelect,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoreWithRegCodeSelect,
			},
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: Input,
				hidden: true,
			},
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: Input,
				hidden: true,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoreWithRegCodeSelect,
			},
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: Input,
				hidden: true,
			},
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: Input,
				disabled: true,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID
			],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoreWithRegCodeSelect,
			},
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: Input,
				hidden: true,
			},
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: Input,
				disabled: true,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: SelectDeepLinkMenuCategory,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
			],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoreWithRegCodeSelect,
			},
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: Input,
				hidden: true,
			},
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: Input,
				disabled: true,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: SelectDeepLinkMenuCategory,
			},
			{
				key: 'productId',
				slug: 'product_id',
				InputEl: SelectDeepLinkMenuCategoryProduct,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoreWithRegCodeSelect,
			},
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: Input,
				hidden: true,
			},
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: Input,
				disabled: true,
			},
			{
				key: 'menuPurpose',
				slug: 'menu_purpose',
				InputEl: MenuPurposeSelect,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: SelectVendor,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: SelectVendor,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID
			],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: SelectVendor,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: SelectDeepLinkMenuCategory,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
			],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: SelectVendor,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: SelectDeepLinkMenu,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: SelectDeepLinkMenuCategory,
			},
			{
				key: 'productId',
				slug: 'product_id',
				InputEl: SelectDeepLinkMenuCategoryProduct,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: SelectVendor,
			},
			{
				key: 'menuPurpose',
				slug: 'menu_purpose',
				InputEl: MenuPurposeSelect,
			},
		],
	},
	// Wallet
	[EDeepLinkPathPages.WALLET]: {
		path: EDeepLinkPathPages.WALLET,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.WALLET],
	},
	// Offers
	[EDeepLinkPathPages.OFFERS]: {
		path: EDeepLinkPathPages.OFFERS,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.OFFERS],
	},
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE]: {
		path: EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE],
		params: [
			{
				key: 'offerType',
				slug: 'offer_type',
				InputEl: CustomerStoreOfferTypeSelect,
			},
		],
	},
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID]: {
		path: EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID],
		params: [
			{
				key: 'offerType',
				slug: 'offer_type',
				InputEl: CustomerStoreOfferTypeSelect,
			},
			{
				key: 'offerLabelId',
				slug: 'offer_label_id',
				InputEl: OfferLabelSelect,
			},
		],
	},
	// Account
	[EDeepLinkPathPages.ACCOUNT_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN],
	},
	// Cart
	[EDeepLinkPathPages.CART]: {
		path: EDeepLinkPathPages.CART,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.CART],
	},
	// WebView
	[EDeepLinkPathPages.WEBVIEW]: {
		path: EDeepLinkPathPages.WEBVIEW,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.WEBVIEW],
		params: [
			{
				key: 'redirectionType',
				slug: 'redirection_type',
				InputEl: WebViewRedirectionTypSelect,
			},
			{
				key: 'link',
				slug: 'link',
				InputEl: WebViewLinkInputText,
			},
		],
	},
};

// ! SELECT OPTIONS
const getDeepLinkSelectOption = (type: EDeepLinkPathPages): ISelectOption => {
	const { path, translationKey } = DEEP_LINK_CONFIG_MAP[type];
	return {
		value: path,
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={`options.${translationKey}`}
			/>
		),
		description: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={`options.descriptions.${translationKey}`}
			/>
		),
	};
};

enum DeepLinkGroups {
	HOME = 'home',
	ALL_SERVICES = 'all_services',
	HUB = 'hub',
	STORE = 'store',
	WALLET = 'wallet',
	OFFERS = 'offers',
	ACCOUNT = 'account',
	CART = 'cart',
	WEBVIEW = 'webview',
}

const DEEP_LINK_GROUPS: Record<DeepLinkGroups, EDeepLinkPathPages[]> = {
	[DeepLinkGroups.HOME]: [EDeepLinkPathPages.HOME_SCREEN],
	[DeepLinkGroups.ALL_SERVICES]: [EDeepLinkPathPages.ALL_SERVICES_SCREEN],
	[DeepLinkGroups.HUB]: [
		EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL,
		EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL,
		// EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY, // ? TEMP Hide
	],
	[DeepLinkGroups.STORE]: [
		EDeepLinkPathPages.STORE_DETAILS_BY_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE,
		EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE,
		EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE,
		EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE,
	],
	[DeepLinkGroups.WALLET]: [EDeepLinkPathPages.WALLET],
	[DeepLinkGroups.OFFERS]: [
		EDeepLinkPathPages.OFFERS,
		EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE,
		EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID,
	],
	[DeepLinkGroups.ACCOUNT]: [
		EDeepLinkPathPages.ACCOUNT_SCREEN,
		EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN,
		EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN,
		EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN,
		EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN,
		EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN,
		EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN,
		EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN,
	],
	[DeepLinkGroups.CART]: [EDeepLinkPathPages.CART],
	[DeepLinkGroups.WEBVIEW]: [EDeepLinkPathPages.WEBVIEW],
};

export const DEEP_LINK_SELECT_OPTIONS_WITH_GROUPS = Object.entries(DEEP_LINK_GROUPS).map(([groupName, options]) => ({
	label: (
		<TranslatedLabel
			nameSpace='deep-links'
			i18nKey={`options.group_labels.${groupName}`}
		/>
	),
	options: options.map(getDeepLinkSelectOption),
}));
