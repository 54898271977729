import { FC, Key, useEffect, useState } from 'react';
import { useNewHttpClient } from 'hooks';
import { transformIGenericListToISegmentOption } from '../helper';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { VENDOR_API } from 'configs/api';
import { EVendorStatus, IGenericVendor } from 'types/api';
import { IListResponse } from 'types/common';
import { ISegmentOption } from '../types';
import { ISelectVendorProps } from './types';
import { Select } from 'antd';

const SelectVendor: FC<ISelectVendorProps> = ({ onlyApproved = false, ...props }) => {
	const [options, setOptions] = useState<ISegmentOption[]>();

	// ! http clients
	const fetchVendorsHttpClient = useNewHttpClient<IListResponse<IGenericVendor>>();

	// ! handlers
	const handleSearch = (value?: string) => {
		const searchValue = value?.trim() || undefined; // never send an empty string

		const requestConfig = VENDOR_API.genericList(searchValue, {
			status: onlyApproved ? EVendorStatus.APPROVED : [],
		});

		return fetchVendorsHttpClient.request({
			requestConfig,
			displayErrorMsg: false,
			successCallback: ({ data }) => {
				const resultingOptions = transformIGenericListToISegmentOption(data);

				const optionSet = (options ?? []).concat(resultingOptions).reduce((acc, item) => {
					acc[item.value] = item;
					return acc;
				}, {} as Record<Key, ISegmentOption>);

				setOptions(Object.values(optionSet));
			},
		});
	};

	const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! effects
	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	return (
		<Select
			allowClear
			showSearch
			maxTagCount={3}
			options={options}
			popupMatchSelectWidth={false}
			loading={fetchVendorsHttpClient.isLoading}
			filterOption={(inputValue, option) => {
				return option?.key?.includes(inputValue.toLowerCase());
			}}
			onSearch={debouncedHandleSearch}
			{...props}
		/>
	);
};

export default SelectVendor;
