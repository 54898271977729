import { FC, memo } from 'react';
import { useSelector } from 'store';
import { getIsRTL } from 'store/selectors';
import { IVerticalCardProps } from './types';
import { TextSplitter } from 'components/TextSplitter';
import styles from './VerticalCard.module.css';
import { Card, Flex, Image, Typography as T, Tooltip } from 'antd';
import Meta from 'antd/es/card/Meta';
import noImgLogo from 'assets/images/no-image.svg';

const VerticalCard: FC<IVerticalCardProps> = ({ id, image, name, name_ar }) => {
	// ! selectors
	const isRTL: boolean = useSelector(getIsRTL);

	// ! render
	return (
		<Tooltip
			title={
				<span>
					#{id} - {name} <TextSplitter /> {name_ar}
				</span>
			}
		>
			<Card
				hoverable
				className={styles.card}
				cover={
					<Flex
						align='center'
						justify='center'
						className={styles.card_cover}
					>
						<Image
							src={image}
							fallback={noImgLogo}
							height={'100%'}
							width={'100%'}
							placeholder={
								<Image
									preview={false}
									src={noImgLogo}
									width={100}
								/>
							}
							loading='lazy'
							preview={false}
							alt='vendor_img'
						/>
					</Flex>
				}
				styles={{
					body: {
						padding: 12,
						width: '100%',
					},
				}}
			>
				<Meta title={<T.Text>{`#${id} - ${isRTL ? name_ar : name}`}</T.Text>} />
			</Card>
		</Tooltip>
	);
};

export default memo(VerticalCard);
