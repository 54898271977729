import { FC, PropsWithChildren, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EVerticalType } from 'types/api';
import { EVerticalTabs } from './types';
import { CustomTabs } from 'components/CustomTabs';
import PageLayout from 'components/PageLayout';
import Spinner from 'components/Spinner';
import StatusSwitch from 'components/_input-components/StatusSwitch';
import ErrorPage from 'pages/ErrorPage';
import useVertical from 'pages/Verticals/hooks/useVertical';
import VerticalTypeRender from '../components/VerticalType';
import { VERTICAL_DETAILS_TABS_ROUTES_CONFIG } from '../routes/routes.vertical-tabs';
import { useVerticalController } from '../useVerticalController';
import { VerticalMoreAction } from './VerticalMoreAction';
import { PageHeader } from '@ant-design/pro-layout';

export const VerticalDetailsPageLayout: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const { t: tVerticals } = useTranslation('verticals');
	const navigate = useNavigate();
	const { hasPermission } = useAuth();

	const { id, data, loading, error, setData } = useVertical();
	const { onToggleVerticalStatusConfirmModal } = useVerticalController();

	const match = useMatch('/:countryIsoTwoCode/application-configuration/verticals/:verticalId/:verticalTab/*');

	// ! handlers
	const onBack = () => navigate('..');

	const onTabChange = (activeKey: EVerticalTabs) => {
		navigate(activeKey);
	};

	// ! memos
	const routeTabs: TRouteObject[] = useMemo(() => {
		if (!data) return [];

		const tabsByVerticalType: Record<EVerticalType, EVerticalTabs[]> = {
			[EVerticalType.PARENT]: [EVerticalTabs.DETAILS, EVerticalTabs.CHILD_VERTICALS, EVerticalTabs.AREAS],
			[EVerticalType.PREMIUM]: [EVerticalTabs.DETAILS, EVerticalTabs.AREAS],
			[EVerticalType.INDEPENDENT]: [
				EVerticalTabs.DETAILS,
				EVerticalTabs.INDEPENDENT_VENDORS,
				EVerticalTabs.AREAS,
			],
			[EVerticalType.CHILD]: [EVerticalTabs.DETAILS, EVerticalTabs.INDEPENDENT_VENDORS],
		};

		return tabsByVerticalType[data.type].map((tabKey) => VERTICAL_DETAILS_TABS_ROUTES_CONFIG[tabKey]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	// ! render
	if (error) {
		return (
			<ErrorPage
				status={error.status}
				title={error.status}
				subTitle={error.data?.message}
			/>
		);
	}

	if (!data || loading) {
		return <Spinner defaultAntdSpinner />;
	}

	return (
		<>
			{loading && <Spinner defaultAntdSpinner />}

			<PageLayout
				header={
					<>
						<PageHeader
							title={tVerticals(`page_titles.details.${data.type}`)}
							subTitle={`#${id}`}
							onBack={onBack}
							tags={
								<VerticalTypeRender
									type={data.type}
									onlyIcon
								/>
							}
							extra={[
								<StatusSwitch
									key='toggle-status'
									size='default'
									value={data.status}
									disabled={!hasPermission(APP_PERMISSIONS.app_management.vertical.update)}
									onChange={(newStatus) => {
										onToggleVerticalStatusConfirmModal(data, () => {
											setData((prev) => (prev ? { ...prev, status: newStatus } : prev));
										});
									}}
								/>,
								<VerticalMoreAction key='more_actions' />,
							]}
						/>

						<CustomTabs
							routes={routeTabs}
							activeKey={match?.params.verticalTab}
							onTabChange={onTabChange}
						/>
					</>
				}
			>
				<Suspense fallback={<Spinner defaultAntdSpinner />}>{children}</Suspense>
			</PageLayout>
		</>
	);
};
