import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import TranslatedLabel from 'components/TranslatedLabel';
import { SubVerticalProvider } from 'pages/Verticals/hooks/useSubVertical';
import { EVerticalTabs } from '../VerticalDetails/types';

// ! lazy imports
const AddVerticalPage = lazy(() => import('../AddVerticalPage'));
const ManageVerticalVendors = lazy(() => import('../ManageVerticalVendors'));
const VerticalSubList = lazy(() => import('../VerticalDetails/SubVerticals'));
const VerticalDetails = lazy(() => import('../VerticalDetails'));
const ManageVerticalAreas = lazy(() => import('../ManageVerticalAreas'));

export const VERTICAL_DETAILS_TABS_ROUTES_CONFIG: Record<EVerticalTabs, TRouteObject> = {
	[EVerticalTabs.DETAILS]: {
		path: EVerticalTabs.DETAILS,
		element: <VerticalDetails />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='verticals'
					i18nKey={`enums.tabs.${EVerticalTabs.DETAILS}`}
				/>
			),
		},
	},
	[EVerticalTabs.CHILD_VERTICALS]: {
		path: EVerticalTabs.CHILD_VERTICALS,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='verticals'
					i18nKey={`enums.tabs.${EVerticalTabs.CHILD_VERTICALS}`}
				/>
			),
		},
		children: [
			{
				index: true,
				element: <VerticalSubList />,
			},
			{
				path: 'new',
				element: <AddVerticalPage isAddChild />,
			},
			{
				path: ':subVerticalId',
				element: (
					<SubVerticalProvider verticalIdSearchParameterName='subVerticalId'>
						<ManageVerticalVendors />
					</SubVerticalProvider>
				),
				handle: {
					permissions: [APP_PERMISSIONS.app_management.vertical.manage_vendor],
				},
			},
		],
	},
	[EVerticalTabs.INDEPENDENT_VENDORS]: {
		path: EVerticalTabs.INDEPENDENT_VENDORS,
		element: <ManageVerticalVendors />,
		handle: {
			permissions: [APP_PERMISSIONS.app_management.vertical.manage_vendor],
			label: (
				<TranslatedLabel
					nameSpace='verticals'
					i18nKey={`enums.tabs.${EVerticalTabs.INDEPENDENT_VENDORS}`}
				/>
			),
		},
	},
	[EVerticalTabs.AREAS]: {
		path: EVerticalTabs.AREAS,
		element: <ManageVerticalAreas />,
		handle: {
			permissions: [APP_PERMISSIONS.areas.view, APP_PERMISSIONS.app_management.vertical.areas.view],
			label: (
				<TranslatedLabel
					nameSpace='verticals'
					i18nKey={`enums.tabs.${EVerticalTabs.AREAS}`}
				/>
			),
		},
	},
};

export const VERTICAL_TABS_ROUTES: TRouteObject[] = Object.values(VERTICAL_DETAILS_TABS_ROUTES_CONFIG);
