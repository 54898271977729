import { FC, useMemo } from 'react';
import { THttpRequestParams } from 'hooks';
import { DEEP_LINKS_API } from 'configs/api/deepLinks';
import { SelectSimpleFetch } from './_SelectSimpleFetch';
import { SelectProps } from 'antd';

export interface ISelectDeepLinkMenuProps extends SelectProps {
	extra?: {
		vendorId: number;
		menuId: number;
		storeId: number;
		categoryId: number;
	};
}

export const SelectDeepLinkMenu: FC<ISelectDeepLinkMenuProps> = ({ ...props }) => {
	// ! memos
	const requestConfig = useMemo(() => {
		return (searchValue?: string, params: THttpRequestParams = {}) => {
			const extraParams = {
				store_id: props.extra?.storeId,
				vendor_id: props.extra?.vendorId,
			};

			Object.entries(extraParams)
				.filter(([, value]) => value !== undefined)
				.forEach(([key, value]) => {
					params[key] = value!.toString();
				});

			return DEEP_LINKS_API.menuList(searchValue, params);
		};
	}, [props.extra?.storeId, props.extra?.vendorId]);

	// ! render
	return (
		<SelectSimpleFetch
			requestConfig={requestConfig}
			{...props}
		/>
	);
};
