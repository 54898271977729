import { IMAGE_FILE_TYPES } from 'configs/common';
import { EFileType } from 'types/common';
import { getAllowedFileFormats } from 'components/CommonUploadFile/helpers';
import TranslatedLabel from 'components/TranslatedLabel';
import { RuleObject } from 'antd/es/form';
import { RcFile } from 'antd/es/upload';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getValidateFileTypeValidator = (allowedFileTypes: EFileType[] = IMAGE_FILE_TYPES): ValidatorRule => ({
	validator: (_: RuleObject, file?: string | RcFile): Promise<void> => {
		if (!file || typeof file === 'string') return Promise.resolve();

		const isValid = allowedFileTypes.includes(file.type as EFileType);

		if (isValid) {
			return Promise.resolve();
		}

		const allowedFormats = getAllowedFileFormats(allowedFileTypes);

		return Promise.reject(
			<TranslatedLabel
				nameSpace='common'
				i18nKey='image.validation.file.type'
				extra={{ type: allowedFormats }}
			/>
		);
	},
});
