import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IArea } from 'types/api';
import { BUTTON_COLOR_SETS, CustomButton, EButtonColorTypes } from 'components/CustomButton';
import styles from './DeliveryAreas.module.css';
import UploadKMLFile, { IUploadKMLFileHandle } from './UploadKMLFile';
import { Button, Flex, List, Typography as T } from 'antd';
import { ShareAltOutlined, UploadOutlined } from '@ant-design/icons';

interface ISelectByPolygonHelperProps {
	isDefiningPolygonArea: boolean;
	areaList: IArea[];
	setPolygonPath: (newPath: google.maps.LatLngLiteral[]) => void;
	onCreateOrEditPolygonArea: () => void;
	onCancelAreaListSelection: () => void;
	onSubmitPreSelection: () => void;
}

export const SelectByPolygonHelper: FC<ISelectByPolygonHelperProps> = ({
	isDefiningPolygonArea,
	areaList = [],
	onCreateOrEditPolygonArea,
	setPolygonPath,
	onCancelAreaListSelection,
	onSubmitPreSelection,
}) => {
	const { t: tCommon } = useTranslation('common');
	const { t: tVendorBranchDeliveryArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area',
	});
	const { t: tUploadKML } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area.upload_kml',
	});

	// ! refs
	const KmlRef = useRef<IUploadKMLFileHandle>(null);

	// ! return
	if (!isDefiningPolygonArea) {
		return (
			<Flex
				gap='small'
				justify='space-between'
			>
				<CustomButton
					type='primary'
					colorTokens={BUTTON_COLOR_SETS[EButtonColorTypes.btn_purple]}
					className={styles.polygon_edit_buttons}
					onClick={() => KmlRef.current?.open()}
				>
					<Flex
						gap='small'
						vertical
						justify='center'
						align='center'
					>
						<UploadOutlined />
						{tUploadKML('open_btn')}
					</Flex>
				</CustomButton>
				<UploadKMLFile
					ref={KmlRef}
					setPolygonPath={(path) => {
						setPolygonPath(path);
						onCreateOrEditPolygonArea();
					}}
				/>
				<CustomButton
					type='primary'
					colorTokens={BUTTON_COLOR_SETS[EButtonColorTypes.btn_purple]}
					className={styles.polygon_edit_buttons}
					onClick={onCreateOrEditPolygonArea}
				>
					<Flex
						gap='small'
						vertical
						justify='center'
						align='center'
					>
						<ShareAltOutlined />
						{tVendorBranchDeliveryArea('actions.draw')}
					</Flex>
				</CustomButton>
			</Flex>
		);
	}

	return (
		<Flex
			vertical
			gap='middle'
			className='h-100'
		>
			<div>
				<T.Title level={5}>{tVendorBranchDeliveryArea('select_by_draw.title')}</T.Title>
				<T.Text type='secondary'>{tVendorBranchDeliveryArea('select_by_draw.help')}</T.Text>
			</div>

			<div className='flex-fill overflow-scroll-y'>
				{!!areaList.length && (
					<List<IArea>
						dataSource={areaList}
						renderItem={(area) => <div>{`#${area.id} - ${area.name}`}</div>}
					/>
				)}
			</div>

			<div className='flex-shrink-zero'>
				<Flex gap='small'>
					<Button
						className={styles.save_btn}
						onClick={onCancelAreaListSelection}
					>
						{tCommon('action_buttons.cancel')}
					</Button>

					<Button
						type='primary'
						className={styles.save_btn}
						onClick={onSubmitPreSelection}
					>
						{tCommon('action_buttons.confirm')}
					</Button>
				</Flex>
			</div>
		</Flex>
	);
};
