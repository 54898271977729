import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth, useNewHttpClient } from 'hooks';
import { VENDOR_WORK_SHIFTS_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { IBranchTiming } from 'types/api';
import { IListResponse, Nullable, TShortWeekDay } from 'types/common';
import { IWorkShiftError } from './types';
import Spinner from 'components/Spinner';
import { IVendorTabProps } from '../../types';
import ApplyToAllModal from './ApplyToAllModal';
import { IApplyToAllModalHandle } from './ApplyToAllModal/types';
import TimingsDay from './TimingsDay';
import styles from './WorkShiftsEditor.module.css';
import WorkshiftTableEditor from './WorkshiftTableEditor';
import { EMPTY_TIMINGS_DATA, MAX_SHIFTS_PER_DAY, WEEK_DAYS } from './config';
import useWorkshift from './useWorkshift';
import { Alert, Button, Col, Flex, Row } from 'antd';

interface IWorkShiftsEditorProps extends IVendorTabProps {
	shiftId: number;
	isEdit?: boolean;
	onLoadChange?: (isLoading: boolean) => void;
}

export const WorkShiftsEditor: FC<IWorkShiftsEditorProps> = ({ vendorId, shiftId, isEdit = true, onLoadChange }) => {
	const { hasPermission } = useAuth();
	const { t: tCommon } = useTranslation('common');
	const { t: tWorkShifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });

	const updateHttpClient = useNewHttpClient<IListResponse<IBranchTiming>>();
	const fetchHttpClient = useNewHttpClient<IListResponse<IBranchTiming>>();

	const isLoading = fetchHttpClient.isLoading || !fetchHttpClient.response?.data;

	// ! refs
	const timeEditModalRef = useRef<IApplyToAllModalHandle>(null);

	// ! state
	const workShiftController = useWorkshift();
	const [errors, setErrors] = useState<Nullable<string[]>>(null);

	// ! handlers
	const updateTimingsData = (response: IListResponse<IBranchTiming>) => {
		if (!response?.data?.length) return;
		const timingsResp: IBranchTiming[] = response.data;

		const newState: Record<TShortWeekDay, IBranchTiming> = timingsResp.reduce<Record<TShortWeekDay, IBranchTiming>>(
			(acc, { day, schedule }) => {
				acc[day] = { day, schedule: schedule || [] };

				return acc;
			},
			{ ...EMPTY_TIMINGS_DATA }
		);

		workShiftController.setTimingsData(newState);
	};

	const onSave = () => {
		if (
			Object.values(workShiftController.timingsData).every(({ schedule }) => schedule.length > MAX_SHIFTS_PER_DAY)
		) {
			setErrors([tWorkShifts('errors.more_than_three_workshifts', { limit: MAX_SHIFTS_PER_DAY })]);
			return;
		}

		const payload = {
			timings: Object.values(workShiftController.timingsData)
				// TEMPORARY while TShortDay has 'all'
				.filter(({ day }) => day !== 'ALL'),
		};

		return updateHttpClient.request({
			requestConfig: VENDOR_WORK_SHIFTS_API.postTiming(vendorId, shiftId, payload),
			successCallback: (data) => {
				updateTimingsData(data);
				setErrors(null);
			},
			errorCallback: (error) => {
				if (!axios.isAxiosError(error) && !!error?.data) {
					const workShiftErrors = error.data as IWorkShiftError;

					if (!!workShiftErrors?.errors?.timings) {
						const errorList: string[] = Object.values(workShiftErrors.errors.timings).flatMap(
							(elem) => elem.schedule
						);

						setErrors(errorList);
					}
				}
			},
		});
	};

	// * Edit All Modal
	const handleOpenEditAllModal = () => timeEditModalRef.current?.open();

	// ! effects
	useEffect(() => {
		if (onLoadChange) onLoadChange(isLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		const ctrl = new AbortController();

		fetchHttpClient.request(
			{
				requestConfig: VENDOR_WORK_SHIFTS_API.getTiming(vendorId, shiftId),
				successCallback: (data) => updateTimingsData(data),
			},
			ctrl.signal
		);

		// Unsubscribing
		return () => ctrl.abort();
	}, [vendorId, shiftId]); // eslint-disable-line react-hooks/exhaustive-deps

	const buttonsDisabled = isLoading || !hasPermission(APP_PERMISSIONS.vendor.shift.update);

	// ! render
	if (isLoading)
		return (
			<Spinner
				defaultAntdSpinner
				hasOverlay={true}
			/>
		);

	return (
		<>
			<Row gutter={[16, 16]}>
				{errors && (
					<Col span={24}>
						<Alert
							showIcon
							type='error'
							message={tCommon('workshift.invalid')}
							description={errors.map((error, index) => (
								<div key={index}>{error}</div>
							))}
						/>
					</Col>
				)}

				<Col flex='1'>
					{WEEK_DAYS.map((day) => {
						return (
							<TimingsDay
								key={day}
								day={day}
								schedule={workShiftController.timingsData[day].schedule}
								isEdit={isEdit}
								showDayTitle={true}
								onTimeChange={(...args) => workShiftController.timeChange(day, ...args)}
								onAddScheduleItem={workShiftController.addScheduleItem}
								onRemoveScheduleItem={(index) => workShiftController.removeScheduledItem(day, index)}
							/>
						);
					})}

					{/* BUTTONS */}
					{isEdit && (
						<Flex
							justify='center'
							gap='small'
						>
							<Button
								onClick={handleOpenEditAllModal}
								disabled={buttonsDisabled}
							>
								{tWorkShifts('apply_to_all_modal.open_btn')}
							</Button>

							<Button
								type='primary'
								className={styles.save_button}
								onClick={onSave}
								disabled={buttonsDisabled}
								loading={updateHttpClient.isLoading}
							>
								{tCommon('action_buttons.save')}
							</Button>
						</Flex>
					)}
				</Col>

				<Col
					flex='1'
					span={18}
				>
					<WorkshiftTableEditor
						isEdit={isEdit}
						workshiftController={workShiftController}
					/>
				</Col>
			</Row>

			<ApplyToAllModal
				ref={timeEditModalRef}
				onOk={workShiftController.applyTimingToAll}
			/>
		</>
	);
};
