import { FC, ReactNode } from 'react';
import { classNames } from 'utils/classNames';
import styles from './FormSectionLabels.module.css';

interface IFormSectionLabelsProps {
	label: ReactNode;
	extra?: ReactNode;
	isRequired?: boolean;
}

export const FormSectionLabels: FC<IFormSectionLabelsProps> = ({ label, extra, isRequired = false }) => {
	return (
		<div>
			<h3
				className={classNames('h3', {
					[styles.required]: isRequired,
				})}
			>
				{label}
			</h3>
			{extra && <span className='body_regular_secondary'>{extra}</span>}
		</div>
	);
};
