import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { APPLICATION_CONFIGURATION_API } from 'configs/api/applicationConfiguration';
import { ISchemaPublish } from 'types/api/schemaBuilder';
import { SchemaBuilder } from 'pages/HomeAppConfiguration/SchemaBuilder';
import { EApplicationSchemaTypes } from 'pages/HomeAppConfiguration/types';
import { Flex, message } from 'antd';

export const HubStoreSectionList = () => {
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	// ! http clients
	const submitHttpClient = useNewHttpClient();

	// ! memos
	const requestSchemaConfig = useMemo(() => APPLICATION_CONFIGURATION_API.SCHEMA_HUB_API.getStoreListSchema(), []);

	// ! handlers
	const handleSaveLayout = (payload: ISchemaPublish) => {
		return submitHttpClient.request({
			requestConfig: APPLICATION_CONFIGURATION_API.SCHEMA_HUB_API.publishStoreListSchema(payload),
			successCallback: () => {
				message.success(tSchemaBuilder(`feedback.create.success`));
			},
		});
	};

	// ! render
	return (
		<SchemaBuilder.Provider
			schemaType={EApplicationSchemaTypes.STORES_LIST}
			handleSubmit={handleSaveLayout}
			fetchSchemaRequestConfig={requestSchemaConfig}
			isSchemaBuilderByDrag={false}
		>
			<SchemaBuilder.DeviceMainSection>
				<Flex justify='end'>
					<SchemaBuilder.SaveButton style={{ margin: '8px 8px 0 0' }} />
				</Flex>
			</SchemaBuilder.DeviceMainSection>
		</SchemaBuilder.Provider>
	);
};
