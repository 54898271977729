import { FC } from 'react';
import { CUSTOMER_STORE_OFFER_TYPE_SELECT_OPTIONS } from './configs';
import { Select, SelectProps } from 'antd';

export const CustomerStoreOfferTypeSelect: FC<SelectProps> = (props) => {
	// ! handlers
	const selectFilterOptionFn: SelectProps['filterOption'] = (inputValue, option) => {
		const lowerCaseOptionValue = (option?.value as string).toLowerCase();
		const lowerCaseInputValue = inputValue.toLowerCase();

		return lowerCaseOptionValue.includes(lowerCaseInputValue);
	};

	// ! render
	return (
		<Select
			showSearch
			options={CUSTOMER_STORE_OFFER_TYPE_SELECT_OPTIONS}
			filterOption={selectFilterOptionFn}
			{...props}
		/>
	);
};
