import { ECustomerStoreOfferType } from 'types/api';
import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

export const CUSTOMER_STORE_OFFER_TYPE_SELECT_OPTIONS: ISelectOption[] = Object.values(ECustomerStoreOfferType).map(
	(value) => ({
		value,
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.enum_customer_store_offer_type.${value}`}
			/>
		),
	})
);
