import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { THttpRequestConfig, useNewHttpClient } from 'hooks';
import { classNames } from 'utils/classNames';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { IGenericEntity, IListResponse, TEmptyFunction } from 'types/common';
import { ISegmentOption } from 'components/_input-components/GenericListSelectors/types';
import { Button, Flex, Select } from 'antd';

interface IAddEntitySelectorProps<T extends IGenericEntity> {
	value?: Array<IGenericEntity['id']>;
	selectedEntityList: T[];
	addEntityPlaceholder: string;

	onClearAll: TEmptyFunction;
	fetchEntityRequestConfig: (search?: string) => THttpRequestConfig;
	handleAddEntity: (entity: T) => void;
	handleRemoveEntity: (id: IGenericEntity['id']) => void;

	renderOption?: (entity: IGenericEntity) => ReactNode;
}

export const AddEntitySelector = <T extends IGenericEntity>({
	selectedEntityList,
	addEntityPlaceholder,
	handleAddEntity,
	handleRemoveEntity,
	onClearAll,
	fetchEntityRequestConfig,
	renderOption,
	...props
}: IAddEntitySelectorProps<T>) => {
	const { t: tCommon } = useTranslation();

	// ! httpClients
	const fetchItemsHttpClient = useNewHttpClient<IListResponse<T>>();

	// ! states
	const [entityMap, setEntityMap] = useState<Record<string, T>>({});

	// ! memos
	const entityNonAddedOptionList = useMemo<ISegmentOption<T>[]>(() => {
		const availableOptionsMap = { ...entityMap };

		const defaultLabelRender = (record: IGenericEntity) =>
			`#${record.id} - ${[record.name, record.name_ar].filter((identity) => identity).join(' | ')}`;

		// # Convert DataType to Options
		const options = Object.values(availableOptionsMap).map<ISegmentOption<T>>((record) => ({
			label: renderOption?.(record) ?? defaultLabelRender(record),
			key: record.id.toString(),
			value: record.id,
			object: record,
		}));

		return options.sort((a, b) => +a.value - +b.value);
	}, [entityMap, renderOption]);

	// ! handlers
	const handleEntitySearch = (value?: string) => {
		const searchValue = value?.trim() || undefined; // never send an empty string

		return fetchItemsHttpClient.request({
			requestConfig: fetchEntityRequestConfig(searchValue),
			displayErrorMsg: false,
			successCallback: ({ data }) => {
				setEntityMap((prev) => {
					const newState = { ...prev };

					data.forEach((entity) => {
						newState[entity.id] = entity;
					});

					return newState;
				});
			},
		});
	};

	const debouncedHandleSearch = debounce(handleEntitySearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	const handleClear = () => {
		debouncedHandleSearch();
		onClearAll();
	};

	const handleSelectValue = (value: number, option: ISegmentOption<T>) => {
		const entity = option.object;

		if (entity) {
			handleAddEntity(entity);
		}
	};

	const handleFilterOption = (inputValue: string, option?: ISegmentOption<T>) => {
		const isAlreadySelected = !selectedEntityList.find((entity) => entity.id.toString() === option?.key);

		return (
			!isAlreadySelected ||
			//
			!!option?.key?.toLocaleLowerCase()?.includes(inputValue.toLowerCase()) ||
			!!option?.object?.name?.toLocaleLowerCase()?.includes(inputValue.toLowerCase()) ||
			!!option?.object?.name_ar?.toLocaleLowerCase()?.includes(inputValue.toLowerCase?.())
		);
	};

	// ! effects
	useEffect(() => {
		handleEntitySearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	return (
		<Flex gap='small'>
			<div className={classNames('flex-fill')}>
				<Select<Array<IGenericEntity['id']>, ISegmentOption<T>>
					allowClear
					showSearch
					mode='multiple'
					maxTagCount={0}
					placeholder={addEntityPlaceholder}
					loading={fetchItemsHttpClient.isLoading}
					options={entityNonAddedOptionList}
					filterOption={handleFilterOption}
					onClear={handleClear}
					onSearch={debouncedHandleSearch}
					onSelect={handleSelectValue}
					onDeselect={handleRemoveEntity}
					{...props}
				/>
			</div>

			<Button
				type='dashed'
				onClick={onClearAll}
			>
				{tCommon('action_buttons.clear_all')}
			</Button>
		</Flex>
	);
};
