import { IHomeDnDContentElement } from './types';

export const X_FAKE_STORE_BUFFER = 10;

export const fakeElement = (): IHomeDnDContentElement => ({
	dndId: crypto.randomUUID() + 'fake',
	containerType: undefined,
	isFakeStore: true,
});

export const injectFakeStoresAtEnd = (currentList: IHomeDnDContentElement[]): IHomeDnDContentElement[] => {
	const areLastElementsEmptyOrFake = currentList
		.slice(-X_FAKE_STORE_BUFFER)
		.every((el, index, slicedArr) => !(index in slicedArr) || el === undefined || el.isFakeStore);

	if (currentList.length === 0 || !areLastElementsEmptyOrFake) {
		//inject fake stores at the end
		currentList.push(...new Array(X_FAKE_STORE_BUFFER));
	}

	return Array.from(currentList, (item) => item || fakeElement());
};
