import { useTranslation } from 'react-i18next';
import { useConfirmModal, useNewHttpClient } from 'hooks';
import { ALL_SERVICES_API } from 'configs/api/applicationConfiguration/allServicesConfiguration';
import { EStatus } from 'types/common';
import { IVerticalGroupPatchPayload } from './types';

export const useVerticalGroupController = () => {
	const { t: tAllServicesConfiguration } = useTranslation('all-services-configuration');

	const { openConfirmModal } = useConfirmModal();

	// ! http clients
	const deleteHttpClient = useNewHttpClient();
	const createHttpClient = useNewHttpClient();
	const patchHttpClient = useNewHttpClient();

	// ! handlers
	const deleteGroup = (groupId: number) => {
		return openConfirmModal({
			title: tAllServicesConfiguration(`confirmation.delete.title`, { groupId }),
			onOk: () => {
				return deleteHttpClient.request({
					requestConfig: ALL_SERVICES_API.delete(groupId),
					withPromiseReject: true,
				});
			},
		});
	};

	const createGroup = ({ verticals, ...group }: IVerticalGroupPatchPayload) => {
		const payload: IVerticalGroupPatchPayload = {
			...group,
			verticals,
		};

		return deleteHttpClient.request({
			requestConfig: ALL_SERVICES_API.create(payload),
			withPromiseReject: true,
		});
	};

	const toggleGroupStatus = (groupId: number, status: EStatus) => {
		return patchHttpClient.request({
			requestConfig: ALL_SERVICES_API.patch(groupId, { status }),
			withPromiseReject: true,
		});
	};

	const patch = ({ verticals, ...group }: IVerticalGroupPatchPayload) => {
		const payload: IVerticalGroupPatchPayload = {
			...group,
			verticals,
		};

		return patchHttpClient.request({
			requestConfig: ALL_SERVICES_API.patch(group.id, payload),
			withPromiseReject: true,
		});
	};

	//
	return {
		deleteGroup,
		createGroup,
		toggleGroupStatus,
		patch,

		deleteHttpClient,
		createHttpClient,
		patchHttpClient,
	};
};
