import { lazy } from 'react';
import { ProductProvider } from 'hooks/useProduct';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import TranslatedLabel from 'components/TranslatedLabel';
import { PRODUCT_DETAILS_TAB_ROUTES } from 'pages/Vendors/VendorDetails/Tabs/Products/routes.product-tabs';
import { MenuOutlined } from '@ant-design/icons';

const BranchMenusTabContent = lazy(() => import('./BranchMenusTabContent'));
const BranchMenuView = lazy(() => import('./BranchMenuView'));
const ProductPageLayout = lazy(
	() => import('pages/Vendors/VendorDetails/Tabs/Products/ProductDetails/ProductPageLayout')
);

export const VENDOR_BRANCH_MENUS_ROUTES: TRouteObject = {
	element: <BranchMenusTabContent />,
	handle: {
		label: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.branches.branch_details.tabs.menus.title'
			/>
		),
		icon: <MenuOutlined />,
		permissions: [APP_PERMISSIONS.vendor.menu.view],
	},
	children: [
		{
			path: ':menuId',
			children: [
				{
					index: true,
					element: <BranchMenuView />,
				},
				{
					path: 'product/:productId',
					element: (
						<ProductProvider>
							<ProductPageLayout />
						</ProductProvider>
					),
					handle: {
						showOnNav: false,
					},
					children: [...PRODUCT_DETAILS_TAB_ROUTES],
				},
			],
		},
	],
};
