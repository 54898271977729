import { FC } from 'react';
import { IGenericVertical } from 'types/api';
import BinaryStatusBullet from 'components/BinaryStatusBullet';
import { Flex } from 'antd';

export const GenericVerticalOptionLabel: FC<IGenericVertical> = ({ status, name, name_ar, id }) => (
	<Flex
		gap='small'
		justify='start'
		align='center'
	>
		<div className='flex-shrink-zero'>
			<BinaryStatusBullet status={status} />
		</div>
		<div>{`#${id} - ${[name, name_ar].filter((id) => id).join(' | ')}`}</div>
	</Flex>
);
