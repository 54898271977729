import { FC } from 'react';
import { TEmptyFunction } from 'types/common';

// ! enums & types
export type TInputParam = 'string' | 'number' | 'select';

// ! interfaces
export interface IDeepLinkConfig {
	path: string;
	translationKey: string;
	params?: {
		key: string;
		slug: string;
		InputEl: FC<any>;
		hidden?: boolean;
		disabled?: boolean;
	}[];
}

export interface IDeepLinkInputProps {
	extra?: Record<string, string | number | undefined>;
	withPredict?: boolean;
	value?: string;
	onChange?: (value?: string) => void;
}

export interface IDeepLinkInputConfigProps extends Omit<IDeepLinkInputProps, 'onChange'> {
	onChange?: (value?: string | object) => void;
}

export interface IDeepLinkInputHandle {
	resetInput: TEmptyFunction;
}

// ! enums & types
// Ref: https://thetakenseat.atlassian.net/wiki/x/GwC4Y
export enum EDeepLinkPathPages {
	// Home
	HOME_SCREEN = '/home',

	// All Services
	ALL_SERVICES_SCREEN = '/all_services',

	// Hub Specific
	HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL = '/hub?hub_type=generic&vertical_id={verticalId}',
	HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL = '/hub?hub_type=generic&vertical_id={verticalId}&child_vertical_id={childVerticalId}',
	// HUB_SCREEN_BOX_DELIVERY = '/hub?hub_type=box_delivery', // ? TEMP HIDE

	// Store Details
	STORE_DETAILS_BY_ID = '/store?store_id={storeId}',
	STORE_DETAILS_BY_ID_AND_MENU_ID = '/store?store_id={storeId}&menu_id={menuId}',
	STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID = '/store?store_id={storeId}&menu_id={menuId}&category_id={categoryId}',
	STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID = '/store?store_id={storeId}&menu_id={menuId}&category_id={categoryId}&product_id={productId}',
	STORE_DETAILS_BY_ID_AND_MENU_PURPOSE = '/store?store_id={storeId}&menu_purpose={menuPurpose}',
	STORE_DETAILS_BY_CAM_REG_CODE = '/store?signup_code={regCode}',
	STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID = '/store?signup_code={regCode}&menu_id={menuId}',
	STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID = '/store?signup_code={regCode}&menu_id={menuId}&category_id={categoryId}',
	STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID = '/store?signup_code={regCode}&menu_id={menuId}&category_id={categoryId}&product_id={productId}',
	STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE = '/store?signup_code={regCode}&menu_purpose={menuPurpose}',

	STORE_DETAILS_BY_VENDOR_ID = '/store?vendor_id={vendorId}',
	STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID = '/store?vendor_id={vendorId}&menu_id={menuId}',
	STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID = '/store?vendor_id={vendorId}&menu_id={menuId}&category_id={categoryId}',
	STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID = '/store?vendor_id={vendorId}&menu_id={menuId}&category_id={categoryId}&product_id={productId}',
	STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE = '/store?vendor_id={vendorId}&menu_purpose={menuPurpose}',

	// Wallet
	WALLET = '/wallet',

	// Offers
	OFFERS = '/offers',
	OFFERS_BY_OFFER_TYPE = '/offers?offer_type={offerType}',
	OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID = '/offers?offer_type={offerType}&offer_label_id={offerLabelId}',

	// Account
	ACCOUNT_SCREEN = '/account',
	ACCOUNT_FAVORITES_SCREEN = '/account?content=favorites',
	ACCOUNT_CARDS_SCREEN = '/account?content=saved_cards',
	ACCOUNT_ADDRESSES_SCREEN = '/account?content=manage_addresses',
	ACCOUNT_CARI_UNLIMITED_SCREEN = '/account?content=cari_unlimited',
	ACCOUNT_CARI_PRIZE_SCREEN = '/account?content=cari_prize',
	ACCOUNT_LANGUAGE_SCREEN = '/account?content=language',
	ACCOUNT_ABOUT_US_SCREEN = '/account?content=about_us',

	// Cart
	CART = '/cart',

	// WebView
	WEBVIEW = '/webview?redirection_type={redirectionType}&link={link}',
}
