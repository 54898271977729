import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_NEW_VALUE_CONFIG } from 'configs/common';
import { EStatus } from 'types/common';
import { IStatusSwitchProps } from './type';
import { Switch } from 'antd';

const StatusSwitch: FC<IStatusSwitchProps> = ({ value, onChange, ...props }) => {
	const { t: tCommon } = useTranslation('common');

	// ! render
	return (
		<Switch
			size='small'
			checkedChildren={tCommon('on_off_switch.active')}
			unCheckedChildren={tCommon('on_off_switch.inactive')}
			checked={value === EStatus.ACTIVE}
			onChange={() => {
				const newStatus = STATUS_NEW_VALUE_CONFIG[value];
				onChange?.(newStatus);
			}}
			{...props}
		/>
	);
};

export default StatusSwitch;
