import { FC, memo, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'store';
import { getSelectedCountryCurrency } from 'store/selectors';
import { getAreaCenter } from '../helper';
import { IDeliveryAreaVehicleTypeMarkerProps } from './types';
import styles from './DeliveryAreaVehicleTypeMarker.module.css';
import { CIRCLE_ICON_COLOR, DELIVERY_AREA_VEHICLE_TYPE_ICONS } from './config';
import { AdvancedMarker, AdvancedMarkerAnchorPoint } from '@vis.gl/react-google-maps';

const DeliveryAreaVehicleTypeMarker: FC<IDeliveryAreaVehicleTypeMarkerProps> = memo(
	({
		isSelected,
		visible,
		vehicleType,
		areaDeliveryFee,
		areaDeliveryTimes,
		polygonPath = [],
		areaCenter,
		...props
	}) => {
		// ! selectors
		const currency = useSelector(getSelectedCountryCurrency);

		// ! memos
		const center = useMemo(() => getAreaCenter(polygonPath, areaCenter), [areaCenter, polygonPath]);

		// ! render
		if (!center || polygonPath.length === 0 || !isSelected || !visible) {
			return null;
		}

		const hasDeliveryFee = areaDeliveryFee !== null;
		const hasDeliveryTimes = Object.values(areaDeliveryTimes).every((t) => t !== null);
		const highlighted = hasDeliveryFee || hasDeliveryTimes;
		const componentString = window.btoa(
			renderToString(
				<svg
					width='44'
					height='44'
					viewBox='0 0 48 48'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<circle
						cx='24'
						cy='24'
						r='16'
						fill={CIRCLE_ICON_COLOR}
						strokeWidth='2.5'
						stroke={highlighted ? 'white' : 'none'}
					/>

					<g fill='#EDF4F2'>{vehicleType && DELIVERY_AREA_VEHICLE_TYPE_ICONS[vehicleType]}</g>
				</svg>
			)
		);
		const iconUrl = `data:image/svg+xml;charset=UTF-8;base64,${componentString}`;

		return (
			<AdvancedMarker
				position={center}
				{...props}
				anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
			>
				<div className='flex flex-column flex-center'>
					<img
						src={iconUrl}
						alt='Delivery area'
					/>

					{hasDeliveryFee && (
						<div
							style={{
								color: CIRCLE_ICON_COLOR,
							}}
							className={styles.marker_label}
						>
							{`$ ${areaDeliveryFee} ${currency}`}
						</div>
					)}
				</div>
			</AdvancedMarker>
		);
	}
);

export default DeliveryAreaVehicleTypeMarker;
