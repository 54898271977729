import { FC } from 'react';
import { classNames } from 'utils/classNames';
import styles from './FakeStoreRender.module.css';
import { Flex } from 'antd';
import { ShopOutlined } from '@ant-design/icons';

export const FakeStoreRender: FC = ({ ...props }) => {
	// ! render
	return (
		<Flex
			align='center'
			justify='center'
			className={classNames({
				[styles.fake_store_card]: true,
			})}
			vertical
			{...props}
		>
			<ShopOutlined style={{ fontSize: '2em' }} />
		</Flex>
	);
};
