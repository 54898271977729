import { EApplicationSchemaTypes, ESchemaComponentType } from 'pages/HomeAppConfiguration/types';

export const ALLOWED_COMPONENTS_PER_PAGE: Record<EApplicationSchemaTypes, Record<ESchemaComponentType, boolean>> = {
	[EApplicationSchemaTypes.HOME]: {
		// Home
		[ESchemaComponentType.BANNER]: true,
		[ESchemaComponentType.BANNER_CAROUSEL]: true,
		[ESchemaComponentType.BILLBOARD_BANNER]: true,
		[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.TRACKABLE_ORDERS]: true,
		[ESchemaComponentType.VERTICALS_MOSAIC]: true,
		// Hub - Main block
		[ESchemaComponentType.VERTICALS_LIST]: false,
		// Hub - Stores List
		[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: true,
		// Account
		[ESchemaComponentType.PROFILE_SECTION]: false,
		[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
		[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
		[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
	},
	[EApplicationSchemaTypes.HUB]: {
		// Home
		[ESchemaComponentType.BANNER]: true,
		[ESchemaComponentType.BANNER_CAROUSEL]: true,
		[ESchemaComponentType.BILLBOARD_BANNER]: true,
		[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.TRACKABLE_ORDERS]: true,
		[ESchemaComponentType.VERTICALS_MOSAIC]: true,
		// Hub - Main block
		[ESchemaComponentType.VERTICALS_LIST]: true,
		// Hub - Stores List
		[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: true,
		// Account
		[ESchemaComponentType.PROFILE_SECTION]: false,
		[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
		[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
		[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
	},
	[EApplicationSchemaTypes.STORES_LIST]: {
		// Home
		[ESchemaComponentType.BANNER]: true,
		[ESchemaComponentType.BANNER_CAROUSEL]: true,
		[ESchemaComponentType.BILLBOARD_BANNER]: true,
		[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: true,
		[ESchemaComponentType.TRACKABLE_ORDERS]: false,
		[ESchemaComponentType.VERTICALS_MOSAIC]: false,
		// Hub - Main block
		[ESchemaComponentType.VERTICALS_LIST]: false,
		// Hub - Stores List
		[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: true,
		// Account
		[ESchemaComponentType.PROFILE_SECTION]: false,
		[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
		[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
		[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
	},
	[EApplicationSchemaTypes.ACCOUNT]: {
		// Home
		[ESchemaComponentType.BANNER]: false,
		[ESchemaComponentType.BANNER_CAROUSEL]: false,
		[ESchemaComponentType.BILLBOARD_BANNER]: false,
		[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: false,
		[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: false,
		[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: false,
		[ESchemaComponentType.TRACKABLE_ORDERS]: false,
		[ESchemaComponentType.VERTICALS_MOSAIC]: false,
		// Hub - Main block
		[ESchemaComponentType.VERTICALS_LIST]: false,
		// Hub - Stores List
		[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: false,
		// Account
		[ESchemaComponentType.PROFILE_SECTION]: true,
		[ESchemaComponentType.MY_ORDERS_SWIMLANE]: true,
		[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: true,
		[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: true,
	},
};
