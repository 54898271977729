import { FC, HTMLAttributes, PropsWithChildren, ReactNode, useState } from 'react';
import { classNames } from 'utils/classNames';
import { MOTORCYCLE_VEHICLE_TYPE_ICON } from 'configs/common';
import { IHomeDnDContentElement } from 'pages/HomeAppConfiguration/SchemaBuilder/types';
import {
	EMyAccountGenericEntryComponentType,
	ESchemaComponentType,
	ISchemaComponentBanner,
	ISchemaComponentBannerCarousel,
	ISchemaComponentBillBoardBanner,
	ISchemaComponentGenericSwimlane,
	ISchemaComponentHeroSwimlane,
	ISchemaComponentMatrixVendorSwimlane,
	ISchemaComponentMyAccountGenericEntry,
	ISchemaComponentMyFavouritesSwimlane,
	ISchemaComponentMyOrdersSwimlane,
	ISchemaComponentProfileSection,
	ISchemaComponentTrackableOrders,
	ISchemaComponentVerticalMosaic,
	ISchemaComponentWideVendorSwimlane,
} from 'pages/HomeAppConfiguration/types';
import styles from './SchemaComponent.module.css';
import { Button, Flex, Typography as T } from 'antd';
import {
	CreditCardOutlined,
	EnvironmentOutlined,
	GlobalOutlined,
	HeartOutlined,
	InfoCircleOutlined,
	RightOutlined,
	ShoppingCartOutlined,
	StarOutlined,
	UserOutlined,
} from '@ant-design/icons';
import noImgLogo from 'assets/images/no-image.svg';

const SchemaComponentImage: FC<PropsWithChildren & HTMLAttributes<HTMLElement> & { image: string | undefined }> = ({
	image,
	...props
}) => {
	const [imageHasError, setImageHasError] = useState<boolean>(false);
	return (
		<img
			alt=''
			src={imageHasError ? noImgLogo : image}
			style={{
				objectFit: 'contain',
				opacity: image ? 'unset' : 0.5,
			}}
			onError={() => setImageHasError(true)}
			{...props}
		/>
	);
};

export const CONTENT_RENDERS: Record<ESchemaComponentType, FC<undefined | IHomeDnDContentElement>> = {
	[ESchemaComponentType.BANNER_CAROUSEL]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentBannerCarousel | undefined;

		const sideElements = 2;
		const elementSize = '20px';

		const bannerOrder = [3, 1, 0, 2, 4];

		return (
			<div
				className={styles.template_banner_carousel}
				style={{
					display: 'grid',
					gap: 8,
					gridTemplateColumns: `repeat(${sideElements},${elementSize}) calc(4*${elementSize}) repeat(${sideElements},${elementSize})`,
					justifyContent: 'center',
				}}
			>
				{[...Array(2 * sideElements + 1)].map((_, index) => {
					const banner = contentConfigs?.banners?.at(bannerOrder[index]);

					return (
						<SchemaComponentImage
							key={index}
							className={styles.template_box_component}
							image={banner?.image}
						/>
					);
				})}
			</div>
		);
	},
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentMatrixVendorSwimlane | undefined;

		const elementsPerColumn = 2;

		const Column = ({ columnIndex }: { columnIndex: number }) => (
			<Flex
				gap='small'
				justify='center'
				vertical
			>
				{[...Array(elementsPerColumn)].map((_, index) => {
					const vendorIndex = columnIndex * elementsPerColumn + index;
					const vendor = contentConfigs?.vendors?.at(vendorIndex);

					return (
						<Flex
							key={vendorIndex}
							gap='small'
						>
							<SchemaComponentImage
								image={vendor?.image}
								className={classNames(styles.template_box_component, styles.small)}
							/>
							____
						</Flex>
					);
				})}
			</Flex>
		);

		return (
			<Flex
				gap='small'
				justify='center'
				className={styles.template_matrix_vendor_swimlane}
			>
				{[...Array(3)].map((_, index) => (
					<Column
						key={index}
						columnIndex={index}
					/>
				))}
			</Flex>
		);
	},
	[ESchemaComponentType.VERTICALS_MOSAIC]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentVerticalMosaic | undefined;

		return (
			<Flex
				gap='small'
				justify='center'
				className={styles.template_vertical}
			>
				<div
					style={{
						display: 'grid',
						gap: 8,
						gridTemplateColumns: 'repeat(4, 1fr)',
						justifyItems: 'center',
					}}
				>
					{[...Array(8)].map((_, index) => {
						const vertical = contentConfigs?.verticals?.at(index);

						return (
							<SchemaComponentImage
								key={index}
								className={classNames(styles.template_box_component)}
								image={vertical?.image}
							/>
						);
					})}
				</div>
			</Flex>
		);
	},
	[ESchemaComponentType.BILLBOARD_BANNER]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentBillBoardBanner | undefined;

		return (
			<SchemaComponentImage
				className={classNames(styles.template_bill_board_banner, styles.template_box_component)}
				image={contentConfigs?.image}
			/>
		);
	},
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentHeroSwimlane | undefined;

		return (
			<Flex
				gap='small'
				vertical
				justify='center'
				className={classNames(styles.template_hero_vendor_swimlane)}
			>
				<div>{contentConfigs?.title ?? '__ ___ _ _ __'}</div>
				<Flex
					gap='small'
					justify='center'
				>
					{[...Array(3)].map((_, index) => {
						const vendor = contentConfigs?.vendors?.at(index);

						return (
							<div
								key={index}
								className='flex-one'
							>
								<SchemaComponentImage
									image={vendor?.image}
									className={styles.template_box_component}
								/>
								<div className={styles.text}>____</div>
							</div>
						);
					})}
				</Flex>
			</Flex>
		);
	},
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentGenericSwimlane | undefined;

		return (
			<Flex
				vertical
				gap='small'
				className={styles.template_generic_vendor_swimlane}
			>
				<Flex
					gap='small'
					align='center'
				>
					<SchemaComponentImage
						image={contentConfigs?.image}
						className={classNames(
							styles.logo,
							styles.template_box_component,
							styles.template_box_component_circle
						)}
					/>
					<div className={styles.title}>{contentConfigs?.title ?? '__ ___ _ _ __'}</div>
				</Flex>
				<Flex
					gap='small'
					className={styles.list}
				>
					{[...Array(8)].map((_, index) => {
						const vendor = contentConfigs?.vendors?.at(index);

						return (
							<div
								key={index}
								className={styles.list_item}
							>
								<SchemaComponentImage
									image={vendor?.image}
									className={styles.template_box_component}
								/>
								<div className={styles.text}>____</div>
							</div>
						);
					})}
				</Flex>
			</Flex>
		);
	},
	[ESchemaComponentType.TRACKABLE_ORDERS]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentTrackableOrders | undefined;

		return (
			<div className={styles.template_trackable_orders}>
				<Flex
					gap='middle'
					align='center'
					className='w-100'
				>
					<div className={classNames(styles.template_box_component, styles.icon)}>
						<ShoppingCartOutlined style={{ fontSize: 20 }} />
					</div>
					<div className='flex-fill'>
						<div>{contentConfigs?.title ?? '__ ___ _ _ __'}</div>
						<div>______ ___ </div>
					</div>
					<div className={classNames(styles.template_box_component, styles.action)} />
				</Flex>
			</div>
		);
	},
	[ESchemaComponentType.VERTICALS_LIST]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentGenericSwimlane | undefined;

		return (
			<Flex
				gap='small'
				className={styles.template_template_vertical_list}
			>
				{[...Array(5)].map((_, index) => {
					const vendor = contentConfigs?.vendors?.at(index);
					return (
						<div
							key={index}
							className='flex-one'
						>
							<SchemaComponentImage
								image={vendor?.image}
								className={classNames(styles.template_box_component)}
							/>
							<div>-----</div>
						</div>
					);
				})}
			</Flex>
		);
	},
	[ESchemaComponentType.BANNER]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentBanner | undefined;

		return (
			<SchemaComponentImage
				className={classNames(styles.template_bill_board_banner, styles.template_box_component)}
				image={contentConfigs?.image}
			/>
		);
	},
	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentWideVendorSwimlane;

		return <>{contentConfigs.name}</>;
	},
	[ESchemaComponentType.PROFILE_SECTION]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentProfileSection;

		return (
			<div className={styles.template_profile_section}>
				<Flex
					vertical
					gap='small'
				>
					<Flex
						gap='small'
						align='center'
						justify='space-between'
					>
						<Button shape='circle'>
							<UserOutlined />
						</Button>

						<Button size='small'>Edit Profile</Button>
					</Flex>

					<Flex vertical>
						<T.Text strong>{contentConfigs.title}</T.Text>
						<T.Text type='secondary'>+xxx xxxx-xxxx</T.Text>
					</Flex>
				</Flex>
			</div>
		);
	},
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentMyOrdersSwimlane;

		return (
			<div
				key={contentConfigs.id}
				className={styles.template_profile_section}
			>
				<Flex
					vertical
					gap='small'
				>
					<Flex
						gap='small'
						align='center'
						justify='space-between'
					>
						<Flex
							gap='small'
							align='center'
						>
							{MOTORCYCLE_VEHICLE_TYPE_ICON}
							<T.Text strong>{contentConfigs.title}</T.Text>
						</Flex>

						<T.Text type='secondary'>
							View All <RightOutlined />
						</T.Text>
					</Flex>
					<Flex
						gap='small'
						className={styles.template_template_vertical_list}
					>
						{[...Array(4)].map((_, index) => {
							return (
								<div
									key={index}
									className='flex-one'
								>
									<SchemaComponentImage
										image={undefined}
										className={classNames(styles.template_box_component)}
									/>
									<div>-----</div>
								</div>
							);
						})}
					</Flex>
				</Flex>
			</div>
		);
	},
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentMyFavouritesSwimlane;

		return (
			<div
				key={contentConfigs.id}
				className={styles.template_profile_section}
			>
				<Flex
					vertical
					gap='small'
				>
					<Flex
						gap='small'
						align='center'
						justify='space-between'
					>
						<Flex
							gap='small'
							align='center'
						>
							<HeartOutlined />
							<T.Text strong>{contentConfigs.title}</T.Text>
						</Flex>

						<T.Text type='secondary'>
							View All <RightOutlined />
						</T.Text>
					</Flex>
					<Flex
						gap='small'
						className={styles.template_template_vertical_list}
					>
						{[...Array(4)].map((_, index) => {
							return (
								<div
									key={index}
									className='flex-one'
								>
									<SchemaComponentImage
										image={undefined}
										className={classNames(styles.template_box_component)}
									/>
									<div>-----</div>
								</div>
							);
						})}
					</Flex>
				</Flex>
			</div>
		);
	},
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: (props) => {
		const contentConfigs = props?.component as ISchemaComponentMyAccountGenericEntry;

		const icons: Record<EMyAccountGenericEntryComponentType, ReactNode> = {
			[EMyAccountGenericEntryComponentType.ABOUT_US]: <InfoCircleOutlined />,
			[EMyAccountGenericEntryComponentType.CARI_UNLIMITED]: undefined,
			[EMyAccountGenericEntryComponentType.CARI_PRIZE]: <StarOutlined />,
			[EMyAccountGenericEntryComponentType.LANGUAGE]: <GlobalOutlined />,
			[EMyAccountGenericEntryComponentType.MANAGE_ADDRESSES]: <EnvironmentOutlined />,
			[EMyAccountGenericEntryComponentType.SAVED_CARDS]: <CreditCardOutlined />,
		};

		return (
			<div className={styles.template_profile_section}>
				<Flex
					gap='small'
					align='center'
					justify='space-between'
				>
					<Flex
						gap='small'
						align='center'
						style={{ minWidth: 0 }}
					>
						{icons[contentConfigs.account_component_name]}

						<T.Text
							strong
							ellipsis
						>
							{contentConfigs.title}
						</T.Text>
					</Flex>

					<RightOutlined />
				</Flex>
			</div>
		);
	},
};
