import { useTranslation } from 'react-i18next';
import { useConfirmModal, useNewHttpClient } from 'hooks';
import { APP_COMPONENTS_API } from 'configs/api/appComponents';
import { EStatus } from 'types/common';
import { IMobileComponentCreatePayload } from './types';
import { App } from 'antd';
import { UniqueIdentifier } from '@dnd-kit/core';

export const useMobileComponentController = () => {
	const { t: tMobileComponents } = useTranslation('app-mobile-components');
	const { message } = App.useApp();

	const { openConfirmModal } = useConfirmModal();

	// ! http clients
	const deleteHttpClient = useNewHttpClient();
	const createHttpClient = useNewHttpClient();
	const patchHttpClient = useNewHttpClient();

	// ! handlers
	const deleteComponent = (id: UniqueIdentifier) => {
		return openConfirmModal({
			title: tMobileComponents(`confirmation.delete.title`, { id }),
			onOk: () => {
				return deleteHttpClient.request({
					requestConfig: APP_COMPONENTS_API.delete(id),
					successCallback: () => {
						message.success(tMobileComponents(`feedback.delete.success`, { id }));
					},
					withPromiseReject: true,
				});
			},
		});
	};

	const toggleComponentStatus = (id: number, status: EStatus) => {
		return patchHttpClient.request({
			requestConfig: APP_COMPONENTS_API.patch(id, { status }),
			successCallback: () => {
				message.success(tMobileComponents(`feedback.toggle_status.success`, { id }));
			},
		});
	};

	const createComponent = (data: IMobileComponentCreatePayload) => {
		return createHttpClient.request({
			requestConfig: {
				...APP_COMPONENTS_API.create(data),
				headers: { 'Content-Type': 'multipart/form-data' },
			},
			successCallback: (response) => {
				message.success(tMobileComponents(`feedback.create.success`));
			},
			withPromiseReject: true,
		});
	};

	const patchComponent = (data: IMobileComponentCreatePayload) => {
		return patchHttpClient.request({
			requestConfig: {
				...APP_COMPONENTS_API.patch(data.id, data),
				headers: { 'Content-Type': 'multipart/form-data' },
			},

			withPromiseReject: true,
		});
	};

	//
	return {
		deleteComponent,
		createComponent,
		toggleComponentStatus,
		patchComponent,

		deleteHttpClient,
		createHttpClient,
		patchHttpClient,
	};
};
