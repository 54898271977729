import { ISegmentOption, TGenericList } from './types';

export const transformIGenericListToISegmentOption = (list: TGenericList): ISegmentOption[] => {
	return list.map<ISegmentOption>(
		(item): ISegmentOption => ({
			label: `#${item.id} - ${item.name} | ${item.name_ar}`,
			key: `#${item.id} - ${item.name} | ${item.name_ar}`.toLowerCase(),
			value: item.id,

			object: item,
		})
	);
};
