import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { DESCRIPTIONS_PROPS } from '../configs';
import { ICustomerCariUnlimitedDetailsCardProps } from './types';
import { CariUnlimitedTag } from 'components/_tags/CariUnlimitedTag';
import { CustomerCariUnlimitedSubscriptionStatusTag } from './CustomerCariUnlimitedSubscriptionStatusTag';
import { Button, Card, Descriptions, Flex, Space, Typography as T } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

export const CustomerCariUnlimitedDetailsCard: FC<ICustomerCariUnlimitedDetailsCardProps> = memo(({ details }) => {
	const { hasPermission } = useAuth();
	const { t: tCustomerCariUnlimitedDetails } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.details.cari_unlimited_card',
	});

	const canManageCariUnlimited = hasPermission(APP_PERMISSIONS.cari_unlimited.manage);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! render
	const title = (
		<Space>
			<CariUnlimitedTag />
			<T.Text style={{ textTransform: 'uppercase' }}>{tCustomerCariUnlimitedDetails('customer')}</T.Text>
		</Space>
	);

	if (!details) {
		return (
			<Card>
				<Flex justify='space-between'>
					{title}
					<CustomerCariUnlimitedSubscriptionStatusTag status={null} />
				</Flex>
			</Card>
		);
	}

	return (
		<Card
			title={title}
			extra={
				<CustomerCariUnlimitedSubscriptionStatusTag
					status={details.status}
					statusDescription={details.status_description}
				/>
			}
		>
			{details && (
				<Descriptions {...DESCRIPTIONS_PROPS}>
					<Descriptions.Item label={tCustomerCariUnlimitedDetails('billing_amount')}>
						{details.billing_amount}
					</Descriptions.Item>

					<Descriptions.Item label={tCustomerCariUnlimitedDetails('next_billing_date')}>
						{details.next_billing_date}
					</Descriptions.Item>

					<Descriptions.Item label={tCustomerCariUnlimitedDetails('plan')}>
						<Flex gap='small'>
							{details.plan.name}
							{canManageCariUnlimited && (
								<Link
									to={generatePath(ABSOLUTE_ROUTES.MANAGEMENT_CARI_UNLIMITED_PLAN_DETAILS, {
										countryIsoTwoCode,
										planId: details.plan.id,
									})}
								>
									<Button
										size='small'
										icon={<EyeOutlined />}
									/>
								</Link>
							)}
						</Flex>
					</Descriptions.Item>
				</Descriptions>
			)}
		</Card>
	);
});
