import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import CITCIcon from 'components/_icons/CitcIcon/CitcIcon';
import { EDriverTabsKeys } from '../DriverDetails/Tabs/types';
import {
	BankOutlined,
	CarOutlined,
	FileDoneOutlined,
	InfoOutlined,
	ShopOutlined,
	UnorderedListOutlined,
} from '@ant-design/icons';

// ! Lazy module imports
const DetailsTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/DetailsTab'));
const OrdersTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/OrdersTab'));
const VehicleDetailsTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/VehicleDetailsTab'));
const DocumentsTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/DocumentsTab'));
const BankDetailsTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/BankDetailsTab'));
const DriverLogsTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/DriverLogsTab'));
const CitcDriverTab = lazy(() => import('pages/Drivers/DriverDetails/Tabs/CitcDriverTab'));

const DRIVER_TABS: Record<EDriverTabsKeys, TRouteObject> = {
	[EDriverTabsKeys.DETAILS]: {
		path: EDriverTabsKeys.DETAILS,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.DETAILS}.title`}
				/>
			),
			icon: <InfoOutlined />,
			permissions: [APP_PERMISSIONS.driver.view],
		},
		element: <DetailsTab />,
	},

	[EDriverTabsKeys.ORDERS]: {
		path: EDriverTabsKeys.ORDERS,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.ORDERS}.title`}
				/>
			),
			icon: <ShopOutlined />,
			permissions: [APP_PERMISSIONS.order.view],
		},
		element: <OrdersTab />,
	},
	[EDriverTabsKeys.VEHICLE]: {
		path: EDriverTabsKeys.VEHICLE,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.VEHICLE}.title`}
				/>
			),
			icon: <CarOutlined />,
			permissions: [APP_PERMISSIONS.driver.vehicle_details.view],
		},
		element: <VehicleDetailsTab />,
	},
	[EDriverTabsKeys.DOCUMENTS]: {
		path: EDriverTabsKeys.DOCUMENTS,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.DOCUMENTS}.title`}
				/>
			),
			icon: <FileDoneOutlined />,
			permissions: [APP_PERMISSIONS.driver.document.view],
		},
		element: <DocumentsTab />,
	},
	[EDriverTabsKeys.BANK_DETAILS]: {
		path: EDriverTabsKeys.BANK_DETAILS,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.BANK_DETAILS}.title`}
				/>
			),
			icon: <BankOutlined />,
			permissions: [APP_PERMISSIONS.driver.bank_info.view],
		},
		element: <BankDetailsTab />,
	},
	[EDriverTabsKeys.LOGS]: {
		path: EDriverTabsKeys.LOGS,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.LOGS}.title`}
				/>
			),
			icon: <UnorderedListOutlined />,
			permissions: [APP_PERMISSIONS.driver.logs.view],
		},
		element: <DriverLogsTab />,
	},
	[EDriverTabsKeys.CITC]: {
		path: EDriverTabsKeys.CITC,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='drivers'
					i18nKey={`driver_details.tabs.${EDriverTabsKeys.CITC}.title`}
				/>
			),
			icon: <CITCIcon />,
			permissions: [APP_PERMISSIONS.citc.view],
			country_white_list: [ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA],
		},
		element: <CitcDriverTab />,
	},
};

export const DRIVER_TAB_ROUTES: TRouteObject[] = Object.values(DRIVER_TABS);

export const DEFAULT_DRIVER_DETAILS_TAB_KEY = EDriverTabsKeys.DETAILS;
