import { APP_COMPONENTS_API } from './appComponents';
import { ALL_SERVICES_API } from './applicationConfiguration/allServicesConfiguration';
import { SCHEMA_HOME_API } from './applicationConfiguration/schemaHome';
import { SCHEMA_HUB_API } from './applicationConfiguration/schemaHub';
import { VERTICALS_API } from './verticals';

export const APPLICATION_CONFIGURATION_API = {
	ALL_SERVICES_API,
	SCHEMA_HOME_API,
	SCHEMA_HUB_API,
	VERTICALS_API,
	APP_COMPONENTS_API,
};
