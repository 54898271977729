import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ISchemaPublish } from 'types/api/schemaBuilder';

export const SCHEMA_HUB_API = {
	getSchema: (): THttpRequestConfig => ({
		url: 'operations/app-management/hub',
		method: 'GET',
	}),
	publishSchema: ({ vertical_id, ...data }: ISchemaPublish): THttpRequestConfig => ({
		url: 'operations/app-management/hub',
		method: 'POST',
		params: { vertical_id },
		data,
	}),

	getStoreListSchema: (params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/app-management/stores-list',
		method: 'GET',
	}),
	publishStoreListSchema: ({ vertical_id, ...data }: ISchemaPublish): THttpRequestConfig => ({
		url: 'operations/app-management/stores-list',
		method: 'POST',
		params: { vertical_id },
		data,
	}),
};
