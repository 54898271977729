import { Suspense, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { VENDOR_API, VERTICALS_API } from 'configs/api';
import { APP_COMPONENTS_API } from 'configs/api/appComponents';
import { APPLICATION_CONFIGURATION_API } from 'configs/api/applicationConfiguration';
import {
	COMPONENT_CONSTRAINTS_DISPLAY_COUNT_MAX,
	COMPONENT_CONSTRAINTS_DISPLAY_COUNT_MIN,
	COMPONENT_CONSTRAINTS_ITEMS_PER_COLUMN_MAX,
	COMPONENT_CONSTRAINTS_ITEMS_PER_COLUMN_MIN,
	COMPONENT_CONSTRAINTS_ITEMS_PER_ROW_MAX,
	COMPONENT_CONSTRAINTS_ITEMS_PER_ROW_MIN,
	IMAGE_RATIO_MESSAGE,
	componentsRequiringAction,
	componentsRequiringImage,
	componentsRequiringTitle,
	componentsRequiringVendorIds,
	componentsRequiringVerticalList,
} from './configs';
import { EVendorStatus, EVerticalType } from 'types/api';
import { EImgFileSize, IListResponse } from 'types/common';
import { IMobileComponentListResponse } from '../types';
import {
	IMobileComponentEditAddDrawerHandle,
	IMobileComponentEditAddDrawerProps,
	IMobileComponentEditAddForm,
	IMobileComponentEditAddFormError,
} from './types';
import CustomDrawer, { ICustomDrawerHandle } from 'components/CustomDrawer';
import Spinner from 'components/Spinner';
import { GenericVerticalOptionLabel } from 'components/_input-components/GenericListSelectors/VerticalsSelect';
import { DeepLinkInput } from 'pages/HomeAppConfiguration/DeepLinksPage';
import { GenericTableInput } from 'pages/HomeAppConfiguration/components/GenericTableInput';
import { ImageFormInput } from 'pages/HomeAppConfiguration/components/ImageFormInput';
import { MobileComponentSelector } from 'pages/HomeAppConfiguration/components/MobileComponentSelector';
import { MyAccountGenericEntryComponentTypeSelector } from 'pages/HomeAppConfiguration/components/MyAccountGenericEntryComponentTypeSelector';
import { ESchemaComponentType } from '../../types';
import { Button, ColorPicker, Divider, Form, Input, InputNumber } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { InfoCircleOutlined } from '@ant-design/icons';

const FORM_ID = 'edit_component_form';

export const MobileComponentEditAddDrawer = forwardRef<
	IMobileComponentEditAddDrawerHandle,
	IMobileComponentEditAddDrawerProps
>(({ confirmLoading, onUpdateValues, ...props }, ref) => {
	const { t: tCommon } = useTranslation('common');
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	const [form] = useForm<IMobileComponentEditAddForm>();

	// ! httpClients
	const fetchHttpClient = useNewHttpClient<IListResponse>();

	// ! watchers
	const id = useWatch('id', form);

	// ! refs
	const drawerRef = useRef<ICustomDrawerHandle>(null);

	// ! states
	const [type, setType] = useState<ESchemaComponentType>();

	// ! memos
	const fetchVerticalRequestConfig = useCallback(
		(search?: string) =>
			VERTICALS_API.genericList(search, {
				type: Object.values(EVerticalType).filter((type) => type !== EVerticalType.CHILD),
			}),
		[]
	);

	const fetchValidVendorsRequestConfig = useCallback(
		(search?: string) =>
			VENDOR_API.genericList(search, {
				status: Object.values(EVendorStatus).filter((status) => status !== EVendorStatus.BLOCKED),
			}),
		[]
	);

	const fetchGenericBannerListRequestConfig = useCallback((search?: string) => {
		const name = search?.trim() || undefined; // never send an empty string

		return APP_COMPONENTS_API.list(name, { type: ESchemaComponentType.BANNER });
	}, []);

	// ! handlers
	const onOpenModal = (values?: IMobileComponentListResponse) => {
		drawerRef.current?.open();

		if (values) {
			setType(values.type);

			if ('verticals' in values && values.verticals?.length) {
				const sortOrder = values.verticals.map((v) => v.id.toString());
				fetchHttpClient.request({
					requestConfig: VERTICALS_API.genericList(undefined, {
						id: sortOrder,
					}),
					successCallback: ({ data }) => {
						const sortedList = data.sort(
							(a, b) => sortOrder.indexOf(a.id.toString()) - sortOrder.indexOf(b.id.toString())
						);

						form.setFieldValue('vertical_ids', sortedList);
					},
				});
			}

			if ('vendors' in values && values.vendors?.length) {
				const sortOrder = values.vendors.map((v) => v.id.toString());

				fetchHttpClient.request({
					requestConfig: VENDOR_API.genericList(undefined, {
						ids: sortOrder,
					}),
					successCallback: ({ data }) => {
						const sortedList = data.sort(
							(a, b) => sortOrder.indexOf(a.id.toString()) - sortOrder.indexOf(b.id.toString())
						);

						form.setFieldValue('vendor_ids', sortedList);
					},
				});
			}

			if ('banners' in values && values.banners?.length) {
				const sortOrder = values.banners.map((v) => v.id.toString());

				fetchHttpClient.request({
					requestConfig: APPLICATION_CONFIGURATION_API.APP_COMPONENTS_API.list(undefined, {
						id: sortOrder,
					}),
					successCallback: ({ data }) => {
						const sortedList = data.sort(
							(a, b) => sortOrder.indexOf(a.id.toString()) - sortOrder.indexOf(b.id.toString())
						);
						form.setFieldValue('banner_ids', sortedList);
					},
				});
			}

			form.setFieldsValue({ ...values } as any);
		}
	};

	const handleCloseModal = () => {
		form.resetFields();

		setType(undefined);

		drawerRef.current?.close();
	};

	const handleSubmit = (values: IMobileComponentEditAddForm) => {
		onUpdateValues(values)
			//
			.then(handleCloseModal)
			.catch((e) => {
				if (e?.data?.errors) {
					const data: IMobileComponentEditAddFormError = e?.data;

					const formattedErrors = Object.entries(data.errors).map(([field, fieldErrorMap]) => ({
						name: field,
						errors: Object.values(fieldErrorMap),
					}));

					form.setFields(formattedErrors as any);
				}
			});
	};

	// ! ref hook
	useImperativeHandle(
		ref,
		() => ({
			open: onOpenModal,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// ! render
	return (
		<CustomDrawer
			ref={drawerRef}
			size='large'
			title={tSchemaBuilder(id ? 'component_edit_drawer.title' : 'component_create_drawer.title', {
				id,
			})}
			onClose={handleCloseModal}
			footer={
				<Button
					block
					type='primary'
					htmlType='submit'
					form={FORM_ID}
					loading={confirmLoading}
				>
					{tCommon('action_buttons.save')}
				</Button>
			}
		>
			<Suspense fallback={<Spinner defaultAntdSpinner />}>
				<Form
					form={form}
					id={FORM_ID}
					layout='vertical'
					onFinish={handleSubmit}
				>
					<Form.Item
						name='id'
						label='id'
						hidden
					>
						<Input disabled />
					</Form.Item>

					<Form.Item
						label={tSchemaBuilder('form.type.label')}
						name='type'
					>
						<MobileComponentSelector
							disabled={!!id}
							onChange={(newType: ESchemaComponentType) => {
								form.resetFields();
								setType(newType);
								form.setFieldValue('type', newType);
							}}
						/>
					</Form.Item>

					<Form.Item
						name='name'
						label={tSchemaBuilder('form.name.label')}
						rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
					>
						<Input placeholder={tSchemaBuilder('form.name.placeholder')} />
					</Form.Item>

					<Divider />

					{type && componentsRequiringTitle[type] && (
						<>
							<Form.Item
								name='title'
								label={tSchemaBuilder('form.title.label')}
								rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
							>
								<Input placeholder={tSchemaBuilder('form.title.placeholder')} />
							</Form.Item>
							<Form.Item
								name='title_ar'
								label={tSchemaBuilder('form.title_ar.label')}
								rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
							>
								<Input placeholder={tSchemaBuilder('form.title_ar.placeholder')} />
							</Form.Item>
						</>
					)}

					{type === ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY && (
						<>
							<Form.Item
								name='description'
								label={tSchemaBuilder('form.description.label')}
								rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
							>
								<Input placeholder={tSchemaBuilder('form.description.placeholder')} />
							</Form.Item>
							<Form.Item
								name='description_ar'
								label={tSchemaBuilder('form.description_ar.label')}
								rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
							>
								<Input placeholder={tSchemaBuilder('form.description_ar.placeholder')} />
							</Form.Item>

							<Form.Item
								name='account_component_name'
								label={tSchemaBuilder('form.account_component_name.label')}
								rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
							>
								<MyAccountGenericEntryComponentTypeSelector />
							</Form.Item>
						</>
					)}

					{/* VERTICAL QUICK ACCESS */}
					{type && componentsRequiringImage[type] && (
						<>
							<Form.Item
								name='image'
								label={tSchemaBuilder('form.image.label')}
								tooltip={
									IMAGE_RATIO_MESSAGE[type] && {
										icon: <InfoCircleOutlined />,
										title: tCommon('image.extra.dimensions_ratio', {
											ratio: IMAGE_RATIO_MESSAGE[type],
										}),
									}
								}
								rules={[
									FORM_VALIDATORS.REQUIRED(),
									FORM_VALIDATORS.IMAGE_FILE_SIZE(EImgFileSize.APP_COMPONENT),
									FORM_VALIDATORS.IMAGE_FILE_TYPES(),
								]}
							>
								<ImageFormInput />
							</Form.Item>
							<Form.Item
								name='image_ar'
								label={tSchemaBuilder('form.image_ar.label')}
								tooltip={
									IMAGE_RATIO_MESSAGE[type] && {
										icon: <InfoCircleOutlined />,
										title: tCommon('image.extra.dimensions_ratio', {
											ratio: IMAGE_RATIO_MESSAGE[type],
										}),
									}
								}
								rules={[
									FORM_VALIDATORS.REQUIRED(),
									FORM_VALIDATORS.IMAGE_FILE_SIZE(EImgFileSize.APP_COMPONENT),
									FORM_VALIDATORS.IMAGE_FILE_TYPES(),
								]}
							>
								<ImageFormInput />
							</Form.Item>
						</>
					)}

					{type && componentsRequiringAction[type] && (
						<Form.Item
							name='action'
							label={tSchemaBuilder('form.action.label')}
							rules={[FORM_VALIDATORS.WHITE_SPACE()]}
						>
							<DeepLinkInput.DeepLinkInputPopUp />
						</Form.Item>
					)}

					{type === ESchemaComponentType.HERO_VENDOR_SWIMLANE && (
						<Form.Item
							name='background_color'
							label={tSchemaBuilder('form.background_color.label')}
							rules={[FORM_VALIDATORS.REQUIRED()]}
							getValueFromEvent={(color) => `#${color.toHex()}`}
						>
							<ColorPicker
								showText
								disabledAlpha
							/>
						</Form.Item>
					)}

					{type === ESchemaComponentType.MATRIX_VENDOR_SWIMLANE && (
						<Form.Item
							name='items_per_column'
							label={tSchemaBuilder('form.items_per_column.label')}
							rules={[
								FORM_VALIDATORS.REQUIRED(),
								FORM_VALIDATORS.NUMBER_GREATER_EQUAL_THAN(COMPONENT_CONSTRAINTS_ITEMS_PER_COLUMN_MIN),
								FORM_VALIDATORS.NUMBER_LESSER_EQUAL_THAN(COMPONENT_CONSTRAINTS_ITEMS_PER_COLUMN_MAX),
							]}
						>
							<InputNumber
								className='w-100'
								placeholder={tSchemaBuilder('form.items_per_column.placeholder')}
							/>
						</Form.Item>
					)}

					{type === ESchemaComponentType.VERTICALS_MOSAIC && (
						<Form.Item
							name='items_per_row'
							label={tSchemaBuilder('form.items_per_row.label')}
							rules={[
								FORM_VALIDATORS.REQUIRED(),
								FORM_VALIDATORS.NUMBER_GREATER_EQUAL_THAN(COMPONENT_CONSTRAINTS_ITEMS_PER_ROW_MIN),
								FORM_VALIDATORS.NUMBER_LESSER_EQUAL_THAN(COMPONENT_CONSTRAINTS_ITEMS_PER_ROW_MAX),
							]}
						>
							<InputNumber
								className='w-100'
								placeholder={tSchemaBuilder('form.items_per_row.placeholder')}
							/>
						</Form.Item>
					)}

					{type === ESchemaComponentType.BANNER_CAROUSEL && (
						<Form.Item
							name='rotation_period'
							label={tSchemaBuilder('form.rotation_period.label')}
							rules={[FORM_VALIDATORS.REQUIRED(), FORM_VALIDATORS.NUMBER_GREATER_THAN_ZERO()]}
						>
							<InputNumber
								className='w-100'
								placeholder={tSchemaBuilder('form.rotation_period.placeholder')}
							/>
						</Form.Item>
					)}

					{type === ESchemaComponentType.VERTICALS_LIST && (
						<Form.Item
							name='display_count'
							label={tSchemaBuilder('form.display_count.label')}
							rules={[
								FORM_VALIDATORS.REQUIRED(),
								FORM_VALIDATORS.NUMBER_GREATER_EQUAL_THAN(COMPONENT_CONSTRAINTS_DISPLAY_COUNT_MIN),
								FORM_VALIDATORS.NUMBER_LESSER_EQUAL_THAN(COMPONENT_CONSTRAINTS_DISPLAY_COUNT_MAX),
							]}
						>
							<InputNumber
								className='w-100'
								placeholder={tSchemaBuilder('form.display_count.placeholder')}
							/>
						</Form.Item>
					)}

					{type && componentsRequiringVendorIds[type] && (
						<Form.Item
							name='vendor_ids'
							label={tSchemaBuilder('form.vendor_ids.label')}
							rules={[FORM_VALIDATORS.REQUIRED()]} // Hammer rule preventing undefined validation for BE
						>
							<GenericTableInput
								fetchRequestConfig={fetchValidVendorsRequestConfig}
								placeholder={tSchemaBuilder('form.vendor_ids.placeholder')}
							/>
						</Form.Item>
					)}

					{type && componentsRequiringVerticalList[type] && (
						<>
							<Divider />

							<Form.Item
								name='vertical_ids'
								label={tSchemaBuilder('form.vertical_ids.label')}
								rules={[FORM_VALIDATORS.REQUIRED()]} // Hammer rule preventing undefined validation for BE
							>
								<GenericTableInput
									fetchRequestConfig={fetchVerticalRequestConfig}
									placeholder={tSchemaBuilder('form.vertical_ids.placeholder')}
									renderOption={GenericVerticalOptionLabel}
								/>
							</Form.Item>
						</>
					)}

					{type === ESchemaComponentType.BANNER_CAROUSEL && (
						<>
							<Divider />

							<Form.Item
								name='banner_ids'
								label={tSchemaBuilder('form.banner_ids.label')}
								rules={[FORM_VALIDATORS.REQUIRED()]} // Hammer rule preventing undefined validation for BE
							>
								<GenericTableInput
									fetchRequestConfig={fetchGenericBannerListRequestConfig}
									placeholder={tSchemaBuilder('form.banner_ids.placeholder')}
								/>
							</Form.Item>
						</>
					)}
				</Form>
			</Suspense>
		</CustomDrawer>
	);
});
