import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactOptions } from 'i18next';

interface ITranslatedLabelProps {
	nameSpace?: string;
	i18nKey: string;
	extra?: Record<string, ReactNode> & ReactOptions;
	upperCase?: boolean;
}

export const TranslatedLabel: FC<ITranslatedLabelProps> = ({
	nameSpace = 'common',
	i18nKey,
	extra = {},
	upperCase = false,
}) => {
	const { t } = useTranslation(nameSpace);

	// ! render
	const label: string = t(i18nKey, extra);

	return <>{upperCase ? label.toUpperCase() : label}</>;
};
