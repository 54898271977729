import { FC, Key, useEffect, useState } from 'react';
import { useNewHttpClient } from 'hooks';
import { transformIGenericListToISegmentOption } from '../helper';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { OFFERS_LABELS_API } from 'configs/api';
import { IGenericVendor } from 'types/api';
import { IListResponse } from 'types/common';
import { ISegmentOption } from '../types';
import { IOfferLabelSelectProps } from './types';
import { Select, SelectProps } from 'antd';

const OfferLabelSelect: FC<IOfferLabelSelectProps> = (props) => {
	// ! states
	const [options, setOptions] = useState<ISegmentOption[]>();

	// ! http clients
	const fetchVendorsHttpClient = useNewHttpClient<IListResponse<IGenericVendor>>();

	// ! handlers
	const handleSearch = (value?: string) => {
		const searchValue = value?.trim() || undefined; // never send an empty string

		return fetchVendorsHttpClient.request({
			requestConfig: OFFERS_LABELS_API.list(searchValue),
			displayErrorMsg: false,
			successCallback: ({ data }) => {
				const resultingOptions = transformIGenericListToISegmentOption(data);

				const optionSet = (options ?? []).concat(resultingOptions).reduce((acc, item) => {
					acc[item.value] = item;
					return acc;
				}, {} as Record<Key, ISegmentOption>);

				setOptions(Object.values(optionSet));
			},
		});
	};

	const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	const selectFilterOptionFn: SelectProps['filterOption'] = (inputValue, option) => {
		return option?.key?.includes(inputValue.toLowerCase());
	};

	// ! effects
	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	return (
		<Select
			allowClear
			showSearch
			maxTagCount={3}
			options={options}
			popupMatchSelectWidth={false}
			loading={fetchVendorsHttpClient.isLoading}
			filterOption={selectFilterOptionFn}
			onSearch={debouncedHandleSearch}
			{...props}
		/>
	);
};

export default OfferLabelSelect;
