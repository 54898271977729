import { IFilterItem } from 'types/common';
import { ESchemaComponentType } from '../types';
import TranslatedLabel from 'components/TranslatedLabel';

const CONFIGURABLE_COMPONENTS: Record<ESchemaComponentType, boolean> = {
	[ESchemaComponentType.BANNER]: true,
	[ESchemaComponentType.BILLBOARD_BANNER]: true,
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
	//
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: true,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: true,

	[ESchemaComponentType.BANNER_CAROUSEL]: true,
	[ESchemaComponentType.TRACKABLE_ORDERS]: true,
	[ESchemaComponentType.VERTICALS_MOSAIC]: true,

	[ESchemaComponentType.VERTICALS_LIST]: true,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: true,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: false,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
};

export const CONFIGURABLE_COMPONENTS_LIST: ESchemaComponentType[] = Object.values(ESchemaComponentType).filter(
	(type) => CONFIGURABLE_COMPONENTS[type]
);

export const COMPONENT_TYPE_FILTERS_CONFIG: IFilterItem[] = CONFIGURABLE_COMPONENTS_LIST.map((value) => ({
	value,
	text: (
		<TranslatedLabel
			nameSpace='schema-builder'
			i18nKey={`enums.ESchemaComponentType.${value}`}
		/>
	),
}));
