import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ESchemaComponentType } from '../types';
import { CONFIGURABLE_COMPONENTS_LIST } from '../MobileComponentsList/configs';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib';

export const MobileComponentSelector: FC<SelectProps<ESchemaComponentType>> = ({ ...props }) => {
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	// ! render
	return (
		<Select
			showSearch
			options={CONFIGURABLE_COMPONENTS_LIST.map((type) => ({
				value: type,
				label: tSchemaBuilder(`enums.ESchemaComponentType.${type}`),
			}))}
			placeholder={tSchemaBuilder('form.type.placeholder')}
			{...props}
		/>
	);
};
