import { FC, useMemo } from 'react';
import { THttpRequestParams } from 'hooks';
import { DEEP_LINKS_API } from 'configs/api/deepLinks';
import { SelectSimpleFetch } from './_SelectSimpleFetch';
import { SelectProps } from 'antd';

export interface ISelectDeepLinkMenuCategoryProductProps extends SelectProps {
	extra?: {
		vendorId: number;
		menuId: number;
		storeId: number;
		categoryId: number;
	};
}

export const SelectDeepLinkMenuCategoryProduct: FC<ISelectDeepLinkMenuCategoryProductProps> = ({ ...props }) => {
	// ! memos
	const requestConfig = useMemo(() => {
		const menuId = props.extra?.menuId;
		const categoryId = props.extra?.categoryId;

		if (!menuId || !categoryId) {
			return;
		}

		return (searchValue?: string, params: THttpRequestParams = {}) => {
			const extraParams = {
				store_id: props.extra?.storeId,
				vendor_id: props.extra?.vendorId,
			};

			Object.entries(extraParams)
				.filter(([, value]) => value !== undefined)
				.forEach(([key, value]) => {
					params[key] = value!.toString();
				});

			return DEEP_LINKS_API.categoryProductList(menuId, categoryId, searchValue, params);
		};
	}, [props.extra]);

	// ! render
	return (
		<SelectSimpleFetch
			requestConfig={requestConfig}
			{...props}
		/>
	);
};
