import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/classNames';
import { EApplicationSchemaTypes } from 'pages/HomeAppConfiguration/types';
import styles from './OtherSchemaSection.module.css';
import { Flex, Typography } from 'antd';

export const OtherSchemaSection: FC<{ schemaType: EApplicationSchemaTypes } & HTMLAttributes<HTMLElement>> = ({
	schemaType,
	className,
}) => {
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	// ! render
	return (
		<Flex
			gap='middle'
			align='center'
			justify='center'
			className={classNames(className, styles.other_schema_section)}
		>
			<Typography.Title
				className={styles.other_schema_title}
				type='secondary'
				level={2}
			>
				{tSchemaBuilder(`enums.EApplicationSchemaTypes.${schemaType}`)}
			</Typography.Title>
		</Flex>
	);
};
