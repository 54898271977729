import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomSearchParams } from 'hooks';
import { Flex, Segmented, Typography as T } from 'antd';
import { OrderedListOutlined, TableOutlined } from '@ant-design/icons';

export const PREVIEW_MODE_SEARCH_PARAM = 'view_mode';

export enum EListViewMode {
	TABLE = 'table',
	PREVIEW = 'preview',
}
export const BANNERS_VIEW_MODE_OPTIONS = [
	{ icon: <TableOutlined />, value: EListViewMode.TABLE },
	{ icon: <OrderedListOutlined />, value: EListViewMode.PREVIEW },
];

export const useViewMode = () => {
	const [, groupedParams, setParams] = useCustomSearchParams();

	const viewMode = useMemo<EListViewMode>(() => {
		const viewModeParam = groupedParams[PREVIEW_MODE_SEARCH_PARAM] as EListViewMode;

		return Object.values(EListViewMode).includes(viewModeParam) ? viewModeParam : EListViewMode.TABLE;
	}, [groupedParams]);

	const onChangeViewMode = (newViewMode: EListViewMode) => {
		setParams(
			(params) => {
				params.set(PREVIEW_MODE_SEARCH_PARAM, newViewMode);

				return params;
			},
			{ replace: true }
		);
	};

	return { viewMode, onChangeViewMode };
};

export const SegmentedViewModeToggler = () => {
	const { t: tCommon } = useTranslation();

	const { viewMode, onChangeViewMode } = useViewMode();

	return (
		<Flex
			key='view-mode'
			gap={6}
			align='center'
		>
			<T.Text>{tCommon(PREVIEW_MODE_SEARCH_PARAM)}</T.Text>
			<Segmented
				value={viewMode}
				options={BANNERS_VIEW_MODE_OPTIONS}
				onChange={onChangeViewMode}
			/>
		</Flex>
	);
};
