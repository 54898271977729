import { isNumber } from 'utils/validationUtils/isNumber';
import TranslatedLabel from 'components/TranslatedLabel';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getMinDeliveryTimeValidator = (maxDeliveryTimeFormValue?: number): ValidatorRule => ({
	validator(_, value) {
		if (isNumber(maxDeliveryTimeFormValue) && value > maxDeliveryTimeFormValue) {
			return Promise.reject(<TranslatedLabel i18nKey={'form_validations.min_order_delivery_time'} />);
		}
		return Promise.resolve();
	},
});
