import { FC, Key, useEffect, useState } from 'react';
import { THttpRequestParams, useNewHttpClient } from 'hooks';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { VERTICALS_API } from 'configs/api';
import { IGenericVertical } from 'types/api';
import { EStatus, IListResponse } from 'types/common';
import { ISegmentOption } from '../types';
import { IVerticalsSelectProps } from './types';
import { GenericVerticalOptionLabel } from 'components/_input-components/GenericListSelectors/VerticalsSelect/GenericVerticalOptionLabel';
import { Select } from 'antd';

const VerticalsSelect: FC<IVerticalsSelectProps> = ({ onlyActive = false, parent_id, types = [], ...props }) => {
	const [options, setOptions] = useState<ISegmentOption[]>();

	// ! http clients
	const fetchVerticalsHttpClient = useNewHttpClient<IListResponse<IGenericVertical>>();

	// ! handlers
	const handleSearch = (value?: string) => {
		const searchValue = value?.trim() || undefined; // never send an empty string

		const params: THttpRequestParams = {
			type: types,
			status: onlyActive ? EStatus.ACTIVE : [],
		};
		if (parent_id) {
			params.parent_id = parent_id.toString();
		}

		const requestConfig = VERTICALS_API.genericList(searchValue, params);

		return fetchVerticalsHttpClient.request({
			requestConfig,
			displayErrorMsg: false,
			successCallback: ({ data }) => {
				const resultingOptions = data.map<ISegmentOption>(
					(item): ISegmentOption => ({
						label: <GenericVerticalOptionLabel {...item} />,
						key: `#${item.id} - ${item.name} | ${item.name_ar}`.toLowerCase(),
						value: item.id,

						object: item,
					})
				);

				const optionSet = (options ?? []).concat(resultingOptions).reduce((acc, item) => {
					acc[item.value] = item;
					return acc;
				}, {} as Record<Key, ISegmentOption>);

				setOptions(Object.values(optionSet));
			},
		});
	};

	const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! effects
	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [types, parent_id]);

	// ! render
	return (
		<Select<number, ISegmentOption>
			allowClear
			showSearch
			maxTagCount={3}
			options={options}
			popupMatchSelectWidth={false}
			loading={fetchVerticalsHttpClient.isLoading}
			filterOption={(inputValue, opt) => !!opt?.key?.includes(inputValue.toLowerCase())}
			onSearch={debouncedHandleSearch}
			{...props}
		/>
	);
};

export default VerticalsSelect;
