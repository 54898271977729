import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { THttpRequestConfig, useNewHttpClient } from 'hooks';
import { fakeElement, injectFakeStoresAtEnd } from './helper';
import { ALLOWED_COMPONENTS_PER_PAGE } from './configs';
import { IVertical } from 'types/api';
import { ISchemaPublish } from 'types/api/schemaBuilder';
import { Nullable } from 'types/common';
import { EApplicationSchemaTypes, ESchemaComponentType, IHomeConfigSchemaGet } from '../types';
import { IHomeDnDContentElement } from './types';
import { InjectComponentModal } from './components/InjectComponentModal/InjectComponentModal';
import { InjectComponentModalHandle } from './components/InjectComponentModal/types';
import { App } from 'antd';
import { UniqueIdentifier } from '@dnd-kit/core';

export interface ISchemaBuilderController extends ReturnType<typeof useSchemaBuilderController> {}

interface ISchemaBuilderProps {
	schemaType: EApplicationSchemaTypes;
	fetchSchemaRequestConfig: THttpRequestConfig;
	isSchemaBuilderByDrag?: boolean;

	handleSubmit: (payload: ISchemaPublish) => Promise<void>;
}

// ! CONTROLLER HOOK
export const useSchemaBuilderController = ({
	schemaType,
	fetchSchemaRequestConfig,
	handleSubmit,
	isSchemaBuilderByDrag = true,
}: ISchemaBuilderProps) => {
	const { message } = App.useApp();
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	// ! refs
	const injectModalRef = useRef<InjectComponentModalHandle>(null);

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IHomeConfigSchemaGet>();

	// ! states
	const [selectedVertical, setSelectedVertical] = useState<IVertical['id']>();
	const [schemaHasErrors, setSchemaHasErrors] = useState<boolean>(false);

	const [itemList, setItemList] = useState<Array<IHomeDnDContentElement>>([]);
	const [activeElement, setActiveElement] = useState<Nullable<IHomeDnDContentElement>>(null);

	const allowedComponentTypes: ESchemaComponentType[] = Object.entries(ALLOWED_COMPONENTS_PER_PAGE[schemaType])
		.filter(([_, allow]) => allow)
		.map(([componentType, _]) => componentType as ESchemaComponentType);

	// ! handlers
	const onSave = () => {
		const list = itemList
			.map((item, index) => ({ ...item, id: item.component?.id, position: index + 1 }))
			.filter((item) => !item.isFakeStore);

		if (list.some((item) => !item.component)) {
			// invalid
			message.error(tSchemaBuilder('form_validations.invalid_schema'));
			setSchemaHasErrors(true);
			return;
		}
		setSchemaHasErrors(false);

		const payload = {
			vertical_id: selectedVertical,
			schema: list.map(({ id, position }) => ({ id, position })),
		};

		handleSubmit(payload);
	};

	const handleRemoveElement = (id: UniqueIdentifier) => {
		setItemList((prev) => {
			const newState = [...prev];
			const elementIndex = newState.findIndex((item) => item.dndId === id);

			if (isSchemaBuilderByDrag) {
				newState.splice(elementIndex, 1);
			} else {
				// Remove element by overriding with a fake store
				newState[elementIndex] = fakeElement();
			}

			return newState;
		});
	};

	const injectComponent = (values: IHomeDnDContentElement, fromLibrary: boolean) => {
		injectModalRef.current?.open((newPosition: number) => {
			setItemList((prev) => {
				const result = [...prev];

				// remove from previous position
				const previousPosition = result.findIndex((element) => element.dndId === values.dndId);
				if (!fromLibrary && previousPosition !== -1) {
					result[previousPosition] = fakeElement();
				}

				// set new position
				result[newPosition] = values;

				// fill undefined positions with fake store elements
				return injectFakeStoresAtEnd(result);
			});
		});
	};

	// ! effects
	useEffect(() => {
		// inject fake stores when schema builder by injection
		if (!isSchemaBuilderByDrag) {
			setItemList(injectFakeStoresAtEnd([]));
		}
	}, [isSchemaBuilderByDrag]);

	useEffect(
		() => {
			fetchHttpClient.request({
				requestConfig: {
					...fetchSchemaRequestConfig,
					params: {
						...fetchSchemaRequestConfig.params,
						vertical_id: selectedVertical,
					},
				},
				successCallback: (data) => {
					let newList: IHomeDnDContentElement[] = [];

					data.schema.forEach((component) => {
						const dndId = crypto.randomUUID();

						const newElement: IHomeDnDContentElement = {
							dndId,
							type: component.type,
							component: { ...component },
						};

						if (component.position) {
							newList[component.position] = newElement;
						} else {
							newList.push(newElement);
						}
					});

					if (!isSchemaBuilderByDrag) {
						newList = injectFakeStoresAtEnd(newList);
					}

					setItemList(newList);
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedVertical]
	);

	// ! return data
	return {
		injectModalRef,
		schemaType,

		isSchemaBuilderByInjection: !isSchemaBuilderByDrag,
		isSchemaBuilderByDrag,

		fetchHttpClient,
		allowedComponentTypes,
		//
		selectedVertical,
		setSelectedVertical,
		schemaHasErrors,
		setSchemaHasErrors,
		itemList,
		setItemList,
		activeElement,
		setActiveElement,
		// handlers
		handleRemoveElement,
		onSave,
		injectComponent,
	};
};

// ! CONTEXT
const Context = createContext<ISchemaBuilderController>({} as ISchemaBuilderController);

export const useSchemaBuilder = () => {
	return useContext(Context);
};

// ! PROVIDER
export const Provider = ({ children, ...props }: PropsWithChildren<ISchemaBuilderProps>) => {
	const ctrl = useSchemaBuilderController(props);

	// ! render
	return (
		<Context.Provider value={ctrl}>
			{children}

			<InjectComponentModal ref={ctrl.injectModalRef} />
		</Context.Provider>
	);
};
