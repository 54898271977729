import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPositionsOptionList } from './helpers';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { InjectComponentModalHandle } from './types';
import { useSchemaBuilder } from '../../useSchemaBuilder';
import { Alert, Flex, Form, Modal, Select, Typography as T } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';

export const InjectComponentModal = forwardRef<InjectComponentModalHandle>((props, ref) => {
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	const { itemList } = useSchemaBuilder();

	const [form] = useForm();

	// ! refs
	const submitCallbackRef = useRef<any>();

	// ! watchers
	const selectedPosition = useWatch('position', form);

	// ! states
	const [isOpen, setIsOpen] = useState<boolean>(false);

	// ! memos
	const positionOptions = useMemo(
		() => {
			const result = getPositionsOptionList(100);

			return result.map((position, index) => {
				position.label = (
					<T.Text>
						<Flex gap='small'>
							{result[index].label} -
							{itemList[index] && !itemList[index].isFakeStore && (
								<T.Text type='secondary'>{itemList[index].component?.name}</T.Text>
							)}
						</Flex>
					</T.Text>
				);

				return position;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[itemList]
	);

	// ! handlers
	const handleSubmit = (values: { position: number }) => {
		submitCallbackRef.current?.(values.position);

		handleClose();
	};

	const handleClose = () => {
		setIsOpen(false);
		form.resetFields();
	};

	// ! ref hook
	useImperativeHandle(
		ref,
		() => ({
			open: (fn) => {
				setIsOpen(true);

				submitCallbackRef.current = fn;
			},
		}),
		[]
	);

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={isOpen}
			title={tSchemaBuilder('schema_builder.inject_on_position.title')}
			onCancel={handleClose}
			onClose={handleClose}
			okButtonProps={{
				htmlType: 'submit',
				form: 'form',
			}}
		>
			<Form
				id='form'
				form={form}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='position'
					initialValue={''}
					label={tSchemaBuilder('schema_builder.inject_on_position.form.position.label')}
				>
					<Select
						autoFocus
						allowClear
						showSearch
						filterOption={(inputValue, option) => +inputValue - 1 === option?.value}
						placeholder={tSchemaBuilder('schema_builder.inject_on_position.form.position.placeholder')}
						options={positionOptions}
					/>
				</Form.Item>

				{itemList[selectedPosition] && !itemList[selectedPosition]?.isFakeStore && (
					<Form.Item>
						<Alert
							type='warning'
							message={tSchemaBuilder('schema_builder.inject_on_position.replace_warn.message')}
							description={tSchemaBuilder('schema_builder.inject_on_position.replace_warn.description')}
						/>
					</Form.Item>
				)}
			</Form>
		</Modal>
	);
});
