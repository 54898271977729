import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { VERTICALS_API } from 'configs/api';
import { EVerticalType } from 'types/api';
import { IVerticalGroupListTableData, IVerticalGroupPatchPayload } from '../types';
import { IVerticalGroupCreateEditDrawerHandle, IVerticalGroupCreateEditDrawerProps } from './types';
import CustomDrawer from 'components/CustomDrawer';
import { GenericVerticalOptionLabel } from 'components/_input-components/GenericListSelectors/VerticalsSelect';
import { GenericTableInput } from 'pages/HomeAppConfiguration/components/GenericTableInput';
import { Button, Flex, Form, Input, InputNumber } from 'antd';

const FORM_ID = 'all_vertical_modal';

export const VerticalGroupCreateEditDrawer = forwardRef<
	IVerticalGroupCreateEditDrawerHandle,
	IVerticalGroupCreateEditDrawerProps
>(({ confirmLoading, onConfirm, ...props }, ref) => {
	const { t: tCommon } = useTranslation();
	const { t: tAllServicesConfiguration } = useTranslation('all-services-configuration');

	const [form] = Form.useForm<IVerticalGroupPatchPayload>();

	// ! states
	const [verticalGroup, setVerticalGroup] = useState<IVerticalGroupListTableData>();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	// ! memos
	const fetchVerticalRequestConfig = useCallback(
		(search?: string) =>
			VERTICALS_API.genericList(search, {
				type: Object.values(EVerticalType).filter((type) => type !== EVerticalType.CHILD),
			}),
		[]
	);

	// ! handlers
	const onOpenModal: IVerticalGroupCreateEditDrawerHandle['open'] = (verticalGroup) => {
		setIsOpen(true);

		if (!verticalGroup) return;

		setVerticalGroup(verticalGroup);

		form.setFieldsValue(verticalGroup);
	};

	const onCloseModal = () => {
		form.resetFields();

		setIsOpen(false);
		setVerticalGroup(undefined);
	};

	const onSubmit = (values: IVerticalGroupPatchPayload) => {
		const payload: IVerticalGroupPatchPayload = {
			...verticalGroup,
			...values,
			verticals: values.verticals.map((vertical, index) => ({ id: vertical.id, position: index + 1 })),
		};

		return onConfirm(payload)
			.then(onCloseModal)
			.catch(() => {});
	};

	// ! ref hook
	useImperativeHandle(
		ref,
		() => ({
			open: onOpenModal,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isOpen]
	);

	// ! render
	return (
		<CustomDrawer
			open={isOpen}
			size='large'
			title={
				verticalGroup?.id
					? tAllServicesConfiguration('drawer.vertical.title', { id: verticalGroup.id })
					: tAllServicesConfiguration('drawer.create.title')
			}
			footer={
				<Flex justify='center'>
					<Button
						type='primary'
						htmlType='submit'
						form={FORM_ID}
						loading={confirmLoading}
					>
						{tCommon('action_buttons.save')}
					</Button>
				</Flex>
			}
			onClose={onCloseModal}
		>
			<Form
				id={FORM_ID}
				form={form}
				layout='vertical'
				onFinish={onSubmit}
			>
				<Form.Item
					name='title'
					label={tAllServicesConfiguration('form.title.label')}
					rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
				>
					<Input placeholder={tAllServicesConfiguration('form.title.placeholder')} />
				</Form.Item>

				<Form.Item
					name='title_ar'
					label={tAllServicesConfiguration('form.title_ar.label')}
					rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
				>
					<Input placeholder={tAllServicesConfiguration('form.title_ar.placeholder')} />
				</Form.Item>

				<Form.Item
					name='position'
					label={tAllServicesConfiguration('form.position.label')}
					rules={[FORM_VALIDATORS.REQUIRED(), FORM_VALIDATORS.NUMBER_GREATER_THAN_ZERO()]}
				>
					<InputNumber
						min={1}
						className='w-100'
						placeholder={tAllServicesConfiguration('form.position.placeholder')}
					/>
				</Form.Item>

				<Form.Item
					name='verticals'
					label={tAllServicesConfiguration('form.vertical.label')}
				>
					<GenericTableInput
						fetchRequestConfig={fetchVerticalRequestConfig}
						placeholder={tAllServicesConfiguration('form.vertical.placeholder')}
						renderOption={GenericVerticalOptionLabel}
					/>
				</Form.Item>
			</Form>
		</CustomDrawer>
	);
});
