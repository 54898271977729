import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { IGenericEntity } from 'types/common';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import { AddEntitySelector } from '../VerticalGroupList/VerticalGroupCreateEditDrawer/AddEntitySelector';
import { ALL_VERTICAL_MODAL_COLUMNS } from '../VerticalGroupList/VerticalGroupCreateEditDrawer/config';
import { DragHandle, RowComponent } from '../VerticalGroupList/VerticalGroupCreateEditDrawer/draggableComponents';
import { Flex, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface IGenericTableInputProps {
	value?: IGenericEntity[];
	onChange?: (value: IGenericEntity[]) => void;
	fetchRequestConfig: (name?: string, params?: THttpRequestParams) => THttpRequestConfig;
	placeholder: string;
	renderOption?: (entity: IGenericEntity) => ReactNode;
}

export const GenericTableInput: FC<IGenericTableInputProps> = ({
	value = [],
	onChange,
	fetchRequestConfig,
	renderOption,
	placeholder,
	...props
}) => {
	const { t: tCommon } = useTranslation();

	// ! states
	const [selectedEntityList, setSelectedEntityList] = useState<IGenericEntity[]>(value);

	// ! handlers
	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id !== over?.id) {
			setSelectedEntityList((prevState) => {
				const activeIndex = prevState.findIndex((record) => record.id === active?.id);
				const overIndex = prevState.findIndex((record) => record.id === over?.id);

				const newArray: IGenericEntity[] = arrayMove(prevState, activeIndex, overIndex);

				onChange?.(newArray);
				return newArray;
			});
		}
	};

	const onClearAll = () => {
		const newArray: IGenericEntity[] = [];
		setSelectedEntityList(newArray);

		onChange?.(newArray);
	};

	const handleAddEntity = (entity: any) => {
		const newArray: IGenericEntity[] = [...selectedEntityList, entity];
		setSelectedEntityList(newArray);

		onChange?.(newArray);
	};

	const handleRemoveEntity = (id: number) => {
		setSelectedEntityList((prev) => {
			const newArray: IGenericEntity[] = prev.filter((element) => element.id !== id);

			onChange?.(newArray);
			return newArray;
		});
	};

	// ! effects
	useEffect(() => {
		setSelectedEntityList((prev) => {
			const isPreviousStateSameAsNewState = JSON.stringify(prev) === JSON.stringify(value);
			if (isPreviousStateSameAsNewState) {
				// If the arrays are the same, prevent unnecessary state update
				return prev;
			}
			return value;
		});
	}, [value]);

	// ! render
	return (
		<Flex
			gap='small'
			vertical
		>
			<AddEntitySelector
				selectedEntityList={selectedEntityList}
				fetchEntityRequestConfig={fetchRequestConfig}
				addEntityPlaceholder={placeholder}
				handleAddEntity={handleAddEntity}
				handleRemoveEntity={handleRemoveEntity}
				value={selectedEntityList.map((entity) => entity.id)}
				renderOption={renderOption}
				onClearAll={onClearAll}
			/>

			<DndContext
				modifiers={[restrictToVerticalAxis]}
				onDragEnd={onDragEnd}
			>
				<SortableContext
					items={selectedEntityList.map((i) => i.id)}
					strategy={verticalListSortingStrategy}
				>
					<Table<IGenericEntity>
						rowKey='id'
						components={{ body: { row: RowComponent } }}
						columns={[
							{ key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
							...ALL_VERTICAL_MODAL_COLUMNS,
							{
								key: 'actions',
								title: tCommon('table.headers.actions'),
								width: getActionButtonsColumnWidth(1),
								render: (_, record) => {
									const actions: IActionMenuItem[] = [
										{
											type: 'button',
											title: tCommon('action_buttons.delete'),
											icon: <DeleteOutlined />,
											actionCb: () => handleRemoveEntity(record.id),
										},
									];
									return <ActionsMenu actions={actions} />;
								},
							},
						]}
						dataSource={selectedEntityList}
						pagination={false}
					/>
				</SortableContext>
			</DndContext>
		</Flex>
	);
};
