import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { UniqueIdentifier } from '@dnd-kit/core';

export const APP_COMPONENTS_API = {
	list: (search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/app-management/app-components',
		method: 'GET',
		params: {
			name: search,
			...params,
		},
	}),
	create: (data: any): THttpRequestConfig => ({
		url: 'operations/app-management/app-components',
		method: 'POST',
		data,
	}),
	patch: (id: UniqueIdentifier, data: Partial<any>): THttpRequestConfig => ({
		url: `operations/app-management/app-components/${id}`,
		method: 'PATCH',
		data,
	}),
	delete: (id: UniqueIdentifier): THttpRequestConfig => ({
		url: `operations/app-management/app-components/${id}`,
		method: 'DELETE',
	}),
};
