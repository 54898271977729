const BYTES_IN_KB = 1024;
const UNITS: string[] = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];

export const formatSizeToString = (bytes: number): string => {
	if (bytes === 0) {
		return '0 Byte';
	}

	const sizeIndex = Math.floor(Math.log(bytes) / Math.log(BYTES_IN_KB));
	const result = bytes / Math.pow(BYTES_IN_KB, sizeIndex) + ' ' + UNITS[sizeIndex];

	return result;
};
