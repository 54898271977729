import { FC, useState } from 'react';
import { IDeepLinkBranch } from 'types/deepLinks';
import { IDeepLinkInputConfigProps } from '../types';
import TranslatedLabel from 'components/TranslatedLabel';
import { ISegmentOption } from 'components/_input-components/GenericListSelectors';
import { SelectDeepLinkStore } from './SelectDeepLinkStore';
import { Alert, Flex, InputProps, SelectProps } from 'antd';

export interface ISelectDeepLinkStoreProps extends SelectProps {
	extra?: {
		vendorId: number;
	};
}

export const StoreWithRegCodeSelect: FC<IDeepLinkInputConfigProps & InputProps> = ({ extra, onChange, ...props }) => {
	// ! states
	const [selectedOption, setSelectedOption] = useState<ISegmentOption<IDeepLinkBranch>>();

	// ! render
	return (
		<Flex
			gap='small'
			vertical
		>
			<SelectDeepLinkStore
				onChange={(ev, option) => {
					const genericBranchOption = option as ISegmentOption<IDeepLinkBranch> | undefined;

					setSelectedOption(genericBranchOption);

					onChange?.({
						storeId: genericBranchOption?.value,
						vendorId: genericBranchOption?.object?.vendor?.id,
						regCode: genericBranchOption?.object?.vendor?.cam_reg_code,
					});
				}}
				{...props}
			/>
			{selectedOption && !selectedOption.object?.vendor?.cam_reg_code && (
				<Alert
					type='error'
					showIcon
					message={
						<TranslatedLabel
							nameSpace='deep-links'
							i18nKey='warnings.reg_code'
						/>
					}
				/>
			)}
		</Flex>
	);
};
