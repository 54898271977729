import { FC } from 'react';
import { Property } from 'csstype';
import { EStatus } from 'types/common';
import { IBinaryStatusBulletProps, TBulletSize } from './types';
import styles from './BinaryStatusBullet.module.css';

const BULLET_SIZE: Record<TBulletSize, string> = {
	small: '',
	middle: styles.bullet_middle,
	large: styles.bullet_large,
};

const COLOR_CONFIG: Record<EStatus, Property.Color> = {
	[EStatus.ACTIVE]: 'var(--ant-green-7)',
	[EStatus.INACTIVE]: '#d9363e',
};

const BinaryStatusBullet: FC<IBinaryStatusBulletProps> = ({ status, size = 'small' }) => (
	<div
		className={`${styles.bullet} ${BULLET_SIZE[size]}`}
		style={{ backgroundColor: COLOR_CONFIG[status] }}
	/>
);

export default BinaryStatusBullet;
