import TranslatedLabel from 'components/TranslatedLabel';
import { Rule } from 'rc-field-form/es/interface';

export const getIsPasswordMatchValidator =
	(dependencyName: string): Rule =>
	({ getFieldValue }) => ({
		validator(_, value) {
			if (!value || getFieldValue(dependencyName) === value) {
				return Promise.resolve();
			}

			return Promise.reject(
				// ? todo move to common or change-password namespace ?
				<TranslatedLabel
					nameSpace='change-user-details'
					i18nKey='errors.passwords_do_not_match'
				/>
			);
		},
	});
