import isIBAN from 'utils/validationUtils/isIBAN';
import TranslatedLabel from 'components/TranslatedLabel';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export const getIsIBanValidator = (): ValidatorRule => ({
	validator(_, value) {
		if (!value) return Promise.resolve();

		if (isIBAN(value)) return Promise.resolve();

		return Promise.reject(<TranslatedLabel i18nKey='form_validations.invalid_iban' />);
	},
});
