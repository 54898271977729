import { FC } from 'react';
import { DEEP_LINKS_API } from 'configs/api/deepLinks';
import { SelectSimpleFetch } from './_SelectSimpleFetch';
import { SelectProps } from 'antd';

export interface ISelectDeepLinkStoreProps extends SelectProps {}

export const SelectDeepLinkStore: FC<ISelectDeepLinkStoreProps> = ({ ...props }) => {
	// ! render
	return (
		<SelectSimpleFetch
			requestConfig={DEEP_LINKS_API.storeList}
			{...props}
		/>
	);
};
