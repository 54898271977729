import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { classNames } from 'utils/classNames';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { APPLICATION_CONFIGURATION_API } from 'configs/api/applicationConfiguration';
import { EVerticalType } from 'types/api';
import { IListResponse } from 'types/common';
import { EApplicationSchemaTypes } from '../types';
import { IHomeDnDContentElement } from './types';
import { VerticalsSelect } from 'components/_input-components/GenericListSelectors';
import { IMobileComponentListResponse } from '../MobileComponentsList/types';
import { SchemaComponent } from '../components/SchemaComponent/SchemaComponent';
import styles from './SchemaBuilder.module.css';
import { TemplateDraggable } from './components/TemplateDraggable';
import { useSchemaBuilder } from './useSchemaBuilder';
import { Flex, Input, Typography as T } from 'antd';

const VERTICAL_TYPES_BY_APPLICATION_SCHEMA: Record<EApplicationSchemaTypes, EVerticalType[]> = {
	[EApplicationSchemaTypes.HUB]: [EVerticalType.INDEPENDENT, EVerticalType.PARENT],
	[EApplicationSchemaTypes.STORES_LIST]: [EVerticalType.INDEPENDENT, EVerticalType.PARENT, EVerticalType.CHILD],
	[EApplicationSchemaTypes.HOME]: [],
	[EApplicationSchemaTypes.ACCOUNT]: [],
};

export const SchemaComponentLibrary: FC = () => {
	const { t: tCommon } = useTranslation();
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	const { schemaType, setSelectedVertical, allowedComponentTypes, isSchemaBuilderByInjection, injectComponent } =
		useSchemaBuilder();

	// ! http clients
	const componentsListHttpClient = useNewHttpClient<IListResponse<IMobileComponentListResponse>>();

	// ! states
	const [componentsMap, setComponentsMap] = useState<
		Record<IMobileComponentListResponse['id'], IHomeDnDContentElement>
	>({});

	const [searchText, setSearchText] = useState<string>();

	// ! memos
	const myComponentList = useMemo(
		() =>
			Object.values(componentsMap).filter(
				(component) =>
					!searchText || component.component?.name?.toLowerCase()?.includes(searchText.toLowerCase())
			),
		[componentsMap, searchText]
	);

	// ! handlers
	const fetchComponentList = (searchText?: string) => {
		componentsListHttpClient.request({
			requestConfig: APPLICATION_CONFIGURATION_API.APP_COMPONENTS_API.list(searchText?.trim() || undefined, {
				type: allowedComponentTypes,
			}),
			successCallback: ({ data }) => {
				setComponentsMap((prev) => {
					const newState = { ...prev };

					data.forEach((element) => {
						const dndId = crypto.randomUUID();

						const newItem: IHomeDnDContentElement = {
							dndId: dndId,
							type: element.type,
							component: element,
						};

						newState[element.id] = newItem;
					});

					return newState;
				});
			},
		});
	};

	const handleChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
		const newSearchText = e.target.value;

		setSearchText(newSearchText);
		fetchComponentList(newSearchText);
	}, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! effects
	useEffect(() => {
		fetchComponentList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	return (
		<Flex
			gap='middle'
			vertical
		>
			{VERTICAL_TYPES_BY_APPLICATION_SCHEMA[schemaType].length > 0 && (
				<Flex
					gap='small'
					vertical
				>
					<T.Title level={4}>{tSchemaBuilder('schema_builder.library.vertical_search')}</T.Title>

					<VerticalsSelect
						types={VERTICAL_TYPES_BY_APPLICATION_SCHEMA[schemaType]}
						placeholder={tSchemaBuilder('schema_builder.library.vertical_search_placeholder')}
						onChange={(value) => setSelectedVertical(value)}
					/>
				</Flex>
			)}

			<Flex
				gap='small'
				vertical
			>
				<T.Title level={4}>{tSchemaBuilder('schema_builder.library.title')}</T.Title>

				<Input.Search
					placeholder={tCommon('search_by.name')}
					onChange={handleChangeSearch}
				/>
			</Flex>

			<div
				className={classNames(styles.content, 'flex-fill')}
				style={{ overflow: 'auto' }}
			>
				<Flex
					gap='small'
					vertical
				>
					{myComponentList.map((componentData) => {
						const { dndId: id, type, component } = componentData;

						if (!type) return null;

						return (
							<div key={id}>
								<TemplateDraggable
									type={type}
									content={componentData}
									id={componentData.dndId}
								>
									<SchemaComponent
										type={type}
										label={component?.name || id}
										content={componentData}
										extraCommands={{
											dragHandle: false,
											remove: false,
											injectOnPosition: isSchemaBuilderByInjection,
										}}
										onInject={(values) => injectComponent(values, true)}
									/>
								</TemplateDraggable>
							</div>
						);
					})}
				</Flex>
			</div>
		</Flex>
	);
};
