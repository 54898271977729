import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { Alert, Button, Flex, Modal, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';

interface IUploadKMLFileProps {
	setPolygonPath: (path: google.maps.LatLngLiteral[]) => void;
}

export interface IUploadKMLFileHandle {
	open: () => void;
}

const UploadKMLFile = forwardRef<IUploadKMLFileHandle, IUploadKMLFileProps>(({ setPolygonPath }, ref) => {
	const { t: tCommon } = useTranslation();
	const { t: tUploadKML } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area.upload_kml',
	});

	useImperativeHandle(
		ref,
		() => {
			const api: IUploadKMLFileHandle = {
				open: () => setIsOpen(true),
			};

			return api;
		},
		[]
	);

	// ! states
	const [isOpen, setIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [uploadedPath, setUploadedPath] = useState<google.maps.LatLngLiteral[]>([]);

	const [fileList, setFileList] = useState<UploadFile<File>[]>([]);

	// ! handlers
	const processCoordinates = (coordinates: string): google.maps.LatLngLiteral[] => {
		// remove empty coordinates and white spaces
		const validCoordinates = coordinates
			.replaceAll(/\s/g, ' ')
			.split(' ')
			.filter((line) => line.length);

		return validCoordinates.map((line) => {
			const [lng, lat] = line.split(',').map(Number);
			return { lng, lat };
		}, []);
	};

	const handleFileRead = (file: RcFile) => {
		setErrorMessage('');
		const reader = new FileReader();
		reader.onload = (e) => {
			if (!e.target || !e.target.result) {
				setErrorMessage(tUploadKML('invalid_file'));
				return false;
			}

			const fileContent = e.target.result as string;
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(fileContent, 'application/xml');
			const parserError = xmlDoc.getElementsByTagName('parsererror');

			if (parserError.length) {
				setErrorMessage(tUploadKML('invalid_file'));
				return false;
			}

			// validate file
			const polygons = xmlDoc.getElementsByTagName('Polygon');

			if (!polygons.length) {
				setErrorMessage(tUploadKML('invalid_file'));
				return false;
			}

			// only allow 1 polygon
			if (polygons.length > 1) {
				setErrorMessage(tUploadKML('multiple_polygons_error'));
				return false;
			}

			// polygons[0] because we only support 1 polygon
			const coordinates = polygons[0].getElementsByTagName('coordinates');

			//validate no coordinates inside polygon tag
			if (!coordinates.length) {
				setErrorMessage(tUploadKML('invalid_file'));
				return false;
			}

			const coordinatesContent = coordinates[0].textContent;
			if (!coordinatesContent) return false;
			setFileList([file]);
			setUploadedPath(processCoordinates(coordinatesContent));
		};
		reader.readAsText(file);
		return false;
		// Upload files manually after beforeUpload returns false.
		// Ref: https://ant.design/components/upload/#components-upload-demo-upload-manually
	};

	const handleModalOk = () => {
		setPolygonPath(uploadedPath);
		handleCloseModal();
	};

	const handleCloseModal = () => {
		setFileList([]);
		setErrorMessage('');
		setIsOpen(false);
	};

	const handleRemoveFile = (file: UploadFile) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);

		setErrorMessage('');
	};

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={isOpen}
			title={tUploadKML('title')}
			okText={tCommon('action_buttons.continue')}
			okButtonProps={{
				disabled: !!errorMessage || !fileList.length,
			}}
			onOk={handleModalOk}
			onCancel={handleCloseModal}
		>
			<Flex
				vertical
				gap='small'
			>
				{errorMessage && (
					<Alert
						type='error'
						message={errorMessage}
					/>
				)}

				<Upload
					maxCount={1}
					accept='.kml'
					listType='picture'
					fileList={fileList}
					beforeUpload={handleFileRead}
					onRemove={handleRemoveFile}
				>
					<Button icon={<UploadOutlined />}>{tUploadKML('upload_btn')}</Button>
				</Upload>
			</Flex>
		</Modal>
	);
});

export default UploadKMLFile;
