import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/classNames';
import containerStyles from './DroppableContainer.module.css';
import { Button } from 'antd';

export const EmptyContainer = forwardRef<HTMLDivElement, any>(
	(
		{
			children,
			handleProps,
			horizontal,
			hover,
			onClick,
			onRemove,
			label,
			placeholder,
			style,
			scrollable,
			shadow,
			unstyled,
			...props
		},
		ref
	) => {
		const { t: tSchemaBuilder } = useTranslation('schema-builder');

		// ! render
		return (
			<div
				ref={ref}
				{...props}
			>
				<Button
					type='dashed'
					block
					className={classNames(
						//
						containerStyles.container,
						containerStyles.empty,
						{
							[containerStyles.unstyled]: unstyled,
							[containerStyles.horizontal]: horizontal,
							[containerStyles.hover]: hover,
							[containerStyles.placeholder]: placeholder,
							[containerStyles.scrollable]: scrollable,
							[containerStyles.shadow]: shadow,
						}
					)}
				>
					{tSchemaBuilder('schema_builder.droppable_slot')}
				</Button>
			</div>
		);
	}
);
