import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useNewHttpClient } from 'hooks';
import { throttle } from 'utils/throttle';
import { VENDOR_BRANCH_API } from 'configs/api';
import { IBranch } from 'types/api';
import { Nullable } from 'types/common';
import useVendor from './useVendor';

interface IBranchContextData {
	branchId: number;

	data: Nullable<IBranch>;
	error: Nullable<any>;
	isLoading: boolean;

	// ! handlers
	fetchData: () => void;
	setData: (data: IBranch) => void;

	setBranchMenuId: (id: number) => void;
}

const BranchContext = createContext<IBranchContextData>({} as IBranchContextData);

const useBranch = () => {
	return useContext(BranchContext);
};

const BranchProvider = ({ children = <Outlet /> }: PropsWithChildren) => {
	const { vendorId } = useVendor();
	const { branchId = '' } = useParams();

	// ! states
	const [branchData, setBranchData] = useState<Nullable<IBranch>>(null);

	// ! http client
	const { error, isLoading, request: fetchRequest } = useNewHttpClient<IBranch>();

	// ! handlers
	const fetchData = throttle(() => {
		if (!branchId || isLoading) return;

		fetchRequest({
			requestConfig: VENDOR_BRANCH_API.get(vendorId, +branchId),
			successCallback: (response) => {
				setBranchData(response);
			},
		});
	});

	const setDataHandle = (data: IBranch) => {
		setBranchData(data);
	};

	// ! useEffects
	useEffect(() => {
		fetchData();

		return () => {
			setBranchData(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchId]);

	// ! render
	if (!branchId) return null;

	const vendorData = {
		branchId: +branchId,
		data: branchData,
		error,
		isLoading,

		// * handlers
		fetchData,
		setData: setDataHandle,
		setBranchMenuId: (id: number) => {
			if (branchData) {
				setBranchData((prev) => (prev ? { ...prev, menu_id: id } : null));
			}
		},
	};

	return <BranchContext.Provider value={vendorData}>{children}</BranchContext.Provider>;
};

export default useBranch;
export { useBranch, BranchProvider };
