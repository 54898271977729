import { EMenuPurpose } from 'types/api';
import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

export const MENU_PURPOSE_SELECT_OPTIONS: ISelectOption[] = Object.values(EMenuPurpose).map((purpose) => ({
	value: purpose,
	label: (
		<TranslatedLabel
			nameSpace='menu-editor'
			i18nKey={`shared.enum_menu_purpose.${purpose}`}
		/>
	),
}));
