import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useAuth,
	useBranch,
	useCitcCities,
	useCitcController,
	useConfirmModal,
	useGetFormValues,
	useNewHttpClient,
	useVendor,
} from 'hooks';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { CITC_API, VENDOR_BRANCH_CITC_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ICITCVendorBranchData } from 'types/api';
import { Nullable } from 'types/common';
import { Spinner } from 'components/Spinner/Spinner';
import TranslatedLabel from 'components/TranslatedLabel';
import { App, Button, Card, Col, Descriptions, Form, Row, Select } from 'antd';
import { useWatch } from 'antd/lib/form/Form';

const CitcBranchTabContent: FC = () => {
	const { vendorId } = useVendor();
	const { branchId } = useBranch();
	const { openConfirmModal } = useConfirmModal();
	const { message } = App.useApp();
	const { hasPermission } = useAuth();
	const { t: tVendorCITC } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.citc',
	});

	const [form] = Form.useForm<ICITCVendorBranchData>();
	const { getFormValues } = useGetFormValues();

	const canSave = hasPermission(APP_PERMISSIONS.citc.edit);
	const regionId = useWatch('region_id', form);

	const { selectOptionList: categoriesListOptions, loading: categoriesLoading } = useCitcController(
		CITC_API.categoriesList()
	);
	const { selectOptionList: regionsListOptions, loading: regionsLoading } = useCitcController(CITC_API.regionList());
	const { selectOptionList: authoritiesListOptions, loading: authoritiesLoading } = useCitcController(
		CITC_API.authoritiesList()
	);
	const {
		data: citiesData,
		fetchCitiesFromRegion,
		selectOptionList: citiesListOptions,
		loading: citiesLoading,
	} = useCitcCities(regionId, form);

	// ! http client
	const saveHttpClient = useNewHttpClient();
	const fetchHttpClient = useNewHttpClient<ICITCVendorBranchData>();

	// ! handlers
	const onSaveAction = () => {
		openConfirmModal({
			title: tVendorCITC('confirmation_message'),
			onOk: onSave,
		});
	};

	const onSave = async () => {
		const formValues: Nullable<ICITCVendorBranchData> = await getFormValues(form);

		if (!formValues) return null;

		return saveHttpClient.request({
			requestConfig: VENDOR_BRANCH_CITC_API.update(vendorId, branchId, formValues),
			successCallback: () => {
				message.success(tVendorCITC('success_message'));
			},
		});
	};

	const fetchCITCBranchData = async () =>
		fetchHttpClient.request({
			requestConfig: VENDOR_BRANCH_CITC_API.get(vendorId, branchId),
			displayErrorMsg: false,
			successCallback: (response) => {
				form.setFieldsValue(response);
				fetchCitiesFromRegion(response.region_id);
			},
		});

	const handleChangeRegion = (selectedRegionId: string) => {
		form.resetFields(['city_id']);
		fetchCitiesFromRegion(selectedRegionId);
	};

	// ! useEffects
	useEffect(() => {
		fetchCITCBranchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<>
			{(fetchHttpClient.isLoading || saveHttpClient.isLoading) && <Spinner defaultAntdSpinner />}

			<Card
				title={tVendorCITC('title')}
				size='small'
			>
				<Row gutter={[8, 8]}>
					<Col span={12}>
						<Form
							form={form}
							disabled={!canSave}
						>
							<Descriptions
								layout='vertical'
								column={1}
							>
								<Descriptions.Item label={tVendorCITC('authority')}>
									<Form.Item
										className='w-100'
										name='authority_id'
										rules={[FORM_VALIDATORS.REQUIRED()]}
									>
										<Select
											loading={authoritiesLoading}
											options={authoritiesListOptions}
											placeholder={tVendorCITC('authority')}
										/>
									</Form.Item>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorCITC('category')}>
									<Form.Item
										className='w-100'
										name='category_id'
										rules={[FORM_VALIDATORS.REQUIRED()]}
									>
										<Select
											loading={categoriesLoading}
											options={categoriesListOptions}
											placeholder={tVendorCITC('category')}
										/>
									</Form.Item>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorCITC('region')}>
									<Form.Item
										className='w-100'
										name='region_id'
										rules={[FORM_VALIDATORS.REQUIRED()]}
									>
										<Select
											loading={regionsLoading}
											options={regionsListOptions}
											placeholder={tVendorCITC('region')}
											onChange={(value: string) => handleChangeRegion(value)}
										/>
									</Form.Item>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorCITC('city')}>
									<Form.Item
										className='w-100'
										name='city_id'
										rules={[FORM_VALIDATORS.REQUIRED()]}
									>
										<Select
											loading={citiesLoading}
											disabled={!canSave || !citiesData.length}
											options={citiesListOptions}
											placeholder={tVendorCITC('city')}
										/>
									</Form.Item>
								</Descriptions.Item>
							</Descriptions>
						</Form>
					</Col>

					{canSave && (
						<Col
							span={12}
							className='flex justify-end'
						>
							<Button
								type='primary'
								size='middle'
								onClick={onSaveAction}
							>
								<TranslatedLabel i18nKey='action_buttons.save' />
							</Button>
						</Col>
					)}
				</Row>
			</Card>
		</>
	);
};

export default CitcBranchTabContent;
