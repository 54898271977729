import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { SCHEMA_HUB_API } from 'configs/api/applicationConfiguration/schemaHub';
import { ISchemaPublish } from 'types/api/schemaBuilder';
import { Nullable } from 'types/common';
import { SchemaBuilder } from 'pages/HomeAppConfiguration/SchemaBuilder';
import { EApplicationSchemaTypes } from 'pages/HomeAppConfiguration/types';
import { Alert, App, Flex } from 'antd';

export const HubDefaultSchemaPage: FC = () => {
	const { t: tSchemaBuilder } = useTranslation('schema-builder');

	const { message } = App.useApp();

	// ! states
	const [publishSchemaErrorMsg, setPublishSchemaErrorMsg] = useState<Nullable<string>>(null);

	// ! http clients
	const submitHttpClient = useNewHttpClient();

	// ! memos
	const requestSchemaConfig = useMemo(() => SCHEMA_HUB_API.getSchema(), []);

	// ! handlers
	const handleSaveLayout = (payload: ISchemaPublish) => {
		return submitHttpClient.request({
			requestConfig: SCHEMA_HUB_API.publishSchema(payload),
			successCallback: () => {
				setPublishSchemaErrorMsg(null);
				message.success(tSchemaBuilder(`feedback.create.success`));
			},
			displayErrorMsg: false,
			errorCallback: (error) => {
				setPublishSchemaErrorMsg(error.data?.message || null);
			},
		});
	};

	//! render
	return (
		<SchemaBuilder.Provider
			schemaType={EApplicationSchemaTypes.HUB}
			handleSubmit={handleSaveLayout}
			fetchSchemaRequestConfig={requestSchemaConfig}
		>
			<SchemaBuilder.DeviceMainSection>
				<Flex
					vertical
					gap='small'
					style={{ padding: '8px 8px 0 8px' }}
				>
					<Flex justify='end'>
						<SchemaBuilder.SaveButton />
					</Flex>
					{publishSchemaErrorMsg && (
						<Alert
							showIcon
							type='error'
							message={publishSchemaErrorMsg}
						/>
					)}
				</Flex>
			</SchemaBuilder.DeviceMainSection>
		</SchemaBuilder.Provider>
	);
};
