import { Dispatch, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib';
import { PlusOutlined } from '@ant-design/icons';

interface IImageFormInputProps {
	value?: string;
	onChange?: Dispatch<RcFile | undefined>;
}

export const ImageFormInput: FC<IImageFormInputProps> = ({ value, onChange }) => {
	const { t: tCommon } = useTranslation('common');

	// ! states
	const [fileList, setFileList] = useState<UploadFile<File>[]>([]);

	// ! handlers
	const validatingFile = (file: RcFile) => {
		// Upload files manually after beforeUpload returns false.
		// Ref: https://ant.design/components/upload/#components-upload-demo-upload-manually
		return false;
	};

	const handleFileChange = ({ fileList: newFileList }: UploadChangeParam<UploadFile<any>>) => {
		if (newFileList.length) {
			onChange?.(newFileList[0].originFileObj);
		} else {
			onChange?.(undefined);
		}
		setFileList(newFileList);
	};

	// ! effects
	useEffect(() => {
		if (typeof value === 'string')
			setFileList([
				{
					uid: '-1',
					name: value,
					status: 'done',
					url: value,
				},
			]);
	}, [value]);

	// ! render
	return (
		<Upload
			maxCount={1}
			fileList={fileList}
			listType='picture'
			beforeUpload={validatingFile}
			onChange={handleFileChange}
		>
			<Button icon={<PlusOutlined />}>{tCommon('image.actions.upload')}</Button>
		</Upload>
	);
};
