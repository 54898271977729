import { createContext, useContext, useMemo } from 'react';
import { Button } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const DragHandle: React.FC = () => {
	const { setActivatorNodeRef, listeners } = useContext(RowContext);

	return (
		<Button
			type='text'
			size='small'
			icon={<HolderOutlined />}
			style={{ cursor: 'move' }}
			ref={setActivatorNodeRef}
			{...listeners}
		/>
	);
};

export interface RowContextProps {
	setActivatorNodeRef?: (element: HTMLElement | null) => void;
	listeners?: SyntheticListenerMap;
}

export const RowContext = createContext<RowContextProps>({});

export interface RowProps extends React.HTMLAttributes<HTMLElement> {
	'data-row-key': string;
}

export const RowComponent: React.FC<RowProps> = (props) => {
	const { listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
		id: props['data-row-key'],
	});

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Translate.toString(transform),
		transition,
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
	};

	const contextValue = useMemo<RowContextProps>(
		() => ({ setActivatorNodeRef, listeners }),
		[setActivatorNodeRef, listeners]
	);

	return (
		<RowContext.Provider value={contextValue}>
			<tr
				{...props}
				ref={setNodeRef}
				style={style}
			/>
		</RowContext.Provider>
	);
};
