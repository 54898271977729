import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppBrand } from 'hooks';
import { generateDeepLinkFromEDeepLinkPathPages, getMatchDeepLinkPaths } from './helper';
import { DEFAULT_DESCRIPTIONS_PROPS } from 'configs/common';
import { Nullable } from 'types/common';
import { EDeepLinkPathPages, IDeepLinkConfig, IDeepLinkInputHandle, IDeepLinkInputProps } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import styles from './DeepLinkInput.module.css';
import {
	DEEP_LINK_CONFIG_MAP,
	DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG,
	DEEP_LINK_SELECT_OPTIONS_WITH_GROUPS,
} from './config';
import { Descriptions, Flex, Select, SelectProps, Typography as T } from 'antd';

export const DeepLinkInput = forwardRef<IDeepLinkInputHandle, IDeepLinkInputProps>(
	({ withPredict = false, value, onChange }, ref) => {
		const { brand } = useAppBrand();

		const { t: tDeepLinks } = useTranslation('deep-links');

		// ! states
		const [linkParams, setLinkParams] = useState<Record<string, string | number | undefined>>({});
		const [selectedGoTo, setSelectedGoTo] = useState<Nullable<EDeepLinkPathPages>>(null);

		// ! memos
		const selectedGoToConfig = useMemo<Nullable<IDeepLinkConfig>>(() => {
			if (!selectedGoTo) return null;

			return DEEP_LINK_CONFIG_MAP[selectedGoTo];
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectedGoTo]);

		const finalDeepLink = useMemo<string>(() => {
			if (!selectedGoTo) return value || '';

			return generateDeepLinkFromEDeepLinkPathPages(brand, selectedGoTo, linkParams);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [linkParams, selectedGoToConfig]);

		const canShowDeepLinkResult = useMemo(() => {
			if (!selectedGoToConfig) return false;

			// if is a deep link and there aren't required params to be filled
			if (!Array.isArray(selectedGoToConfig?.params)) return true;

			// if there is required params to filled, validate them
			const allInputParamsFilled = Object.values(linkParams).every((linkValue) => !!linkValue);

			return allInputParamsFilled && Object.keys(linkParams).length === selectedGoToConfig.params.length;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [linkParams, selectedGoToConfig]);

		// ! handlers
		const handleRedirectToChange = (newPath: EDeepLinkPathPages) => {
			setSelectedGoTo(newPath);
			setLinkParams({});
		};

		const redirectToSelectFilterFn: SelectProps['filterOption'] = (inputValue, option) => {
			if (!option?.value) return false;

			const lowercaseSearchText = inputValue.toLowerCase();

			const translationKey = DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[option.value as EDeepLinkPathPages];
			const lowercaseOptionLabel = tDeepLinks(`options.${translationKey}`).toLowerCase();

			return lowercaseOptionLabel.includes(lowercaseSearchText);
		};

		const onParamInputChange = (value: string | undefined | Nullable<number>, key: string) => {
			setLinkParams((prev) => {
				const newState = { ...prev };

				// * Update new link param
				newState[key] = value || undefined;

				return newState;
			});
		};

		const clearFieldsAfterCurrent = (key: string) => {
			// clear link params that depend on Key
			setLinkParams((prev) => {
				const newState = { ...prev };

				const keyIndex = selectedGoToConfig?.params?.findIndex((param) => param.key === key);

				if (keyIndex !== undefined) {
					// Delete all params that come after current Key
					selectedGoToConfig?.params
						//
						?.filter((_, index) => index > keyIndex)
						.forEach((e) => {
							delete newState[e.key];
						});
				}

				return newState;
			});
		};

		const handleParamInputChange = (key: string) => (value: string | Nullable<number> | object) => {
			clearFieldsAfterCurrent(key);

			if (value && typeof value === 'object') {
				Object.entries(value).forEach(([key, value]) => {
					onParamInputChange(value, key);
				});
			} else {
				onParamInputChange(value, key);
			}
		};

		// ! effects
		// * on mount
		useEffect(() => {
			if (withPredict) {
				const matches = getMatchDeepLinkPaths(value);

				const longestMatch = matches.at(0);

				if (longestMatch) {
					const params = longestMatch[1]?.groups;
					const path = longestMatch[0];
					if (params) setLinkParams(params);
					setSelectedGoTo(path);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		// * if deep link, send the final state when valid
		useEffect(() => {
			if (!finalDeepLink.length || !canShowDeepLinkResult || finalDeepLink === value) {
				onChange?.(undefined);
			} else {
				onChange?.(finalDeepLink);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [canShowDeepLinkResult, finalDeepLink]);

		// ! ref hook
		useImperativeHandle(
			ref,
			() => ({
				resetInput: () => {
					setSelectedGoTo(null);
					setLinkParams({});
				},
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[]
		);

		// ! render
		return (
			<Descriptions
				{...DEFAULT_DESCRIPTIONS_PROPS}
				bordered
				styles={{
					label: { width: '200px', minWidth: '180px' },
					content: { maxWidth: 1 }, // Note: workaround to Antd styles to respect ellipses on the final link (CopyToClipBoard component)
				}}
			>
				{/* GO TO */}
				<Descriptions.Item label={tDeepLinks('labels.go_to')}>
					<Select<EDeepLinkPathPages>
						showSearch
						className='w-100'
						popupClassName={styles.redirect_to_select_dropdown}
						value={selectedGoTo}
						options={DEEP_LINK_SELECT_OPTIONS_WITH_GROUPS}
						optionRender={(option) => (
							<Flex vertical>
								{option.data.label}
								<T.Text
									type='secondary'
									className={styles.option_description}
								>
									{option.data.description}
								</T.Text>
							</Flex>
						)}
						placeholder={tDeepLinks('placeholders.go_to')}
						filterOption={redirectToSelectFilterFn}
						onChange={handleRedirectToChange}
					/>
				</Descriptions.Item>

				{/* PARAMS */}
				{selectedGoToConfig?.params?.map(({ key, slug, InputEl, disabled, hidden }, index) => {
					const previousParamKey = selectedGoToConfig?.params?.at(index - 1)?.key;
					const hasPreviousParamValue = index === 0 || (previousParamKey && linkParams[previousParamKey]);

					const isDisabled = disabled || !hasPreviousParamValue;

					if (hidden) {
						return null;
					}

					return (
						<Descriptions.Item
							key={slug}
							label={tDeepLinks(`labels.${slug}`)}
							style={{ backgroundColor: 'white' }}
						>
							<InputEl
								className='w-100'
								extra={linkParams}
								value={linkParams[key]}
								disabled={isDisabled}
								placeholder={tDeepLinks(`placeholders.${slug}`)}
								onChange={handleParamInputChange(key)}
							/>
						</Descriptions.Item>
					);
				})}

				{/* RESULT */}
				{canShowDeepLinkResult && (
					<Descriptions.Item label={tDeepLinks('labels.result').toUpperCase()}>
						<CopyToClipBoard>{finalDeepLink}</CopyToClipBoard>
					</Descriptions.Item>
				)}
			</Descriptions>
		);
	}
);
