import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { CUSTOMER_STATUS_SELECT_OPTIONS } from './configs';
import { ICustomer } from 'types/api';
import { ICustomerEditModalHandle, ICustomerEditModalProps } from './types';
import { Form, Input, Modal, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

const FORM_ID = 'customer_edit_form';

export const CustomerEditModal = forwardRef<ICustomerEditModalHandle, ICustomerEditModalProps>(
	({ confirmLoading, onConfirm }, ref) => {
		const { t: tCustomers } = useTranslation('customers');

		const [form] = Form.useForm<ICustomer>();

		// ! watchers
		const customerId = useWatch('id', form);

		// ! states
		const [isOpen, setIsOpen] = useState<boolean>(false);

		// ! handlers
		const onOpenModal: ICustomerEditModalHandle['open'] = (data) => {
			setIsOpen(true);

			form.setFieldsValue(data);
		};

		const onCloseModal = () => {
			form.resetFields();

			setIsOpen(false);
		};

		const onSubmit: ICustomerEditModalProps['onConfirm'] = (values) => {
			return onConfirm(values).then(onCloseModal);
		};

		// ! ref hook
		useImperativeHandle(
			ref,
			() => ({
				open: onOpenModal,
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[isOpen]
		);

		// ! render
		return (
			<Modal
				{...DEFAULT_MODAL_PROPS}
				open={isOpen}
				title={tCustomers('customer_details.tabs.details.edit_modal_title', { id: customerId })}
				okButtonProps={{
					htmlType: 'submit',
					form: FORM_ID,
				}}
				confirmLoading={confirmLoading}
				onCancel={onCloseModal}
			>
				<Form
					id={FORM_ID}
					form={form}
					layout='vertical'
					onFinish={onSubmit}
				>
					<Form.Item
						name='id'
						hidden
					>
						<Input />
					</Form.Item>

					<Form.Item
						name='first_name'
						label={tCustomers('shared.entity.first_name')}
						rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name='last_name'
						label={tCustomers('shared.entity.last_name')}
						rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name='email'
						label={tCustomers('shared.entity.email')}
						rules={[FORM_VALIDATORS.REQUIRED(), { type: 'email' }]}
					>
						<Input disabled />
					</Form.Item>

					<Form.Item
						name='phone_number'
						label={tCustomers('shared.entity.phone_number')}
						rules={[FORM_VALIDATORS.REQUIRED_AND_WHITE_SPACE()]}
					>
						<Input disabled />
					</Form.Item>

					<Form.Item
						name='status'
						label={tCustomers('shared.entity.status')}
						rules={[FORM_VALIDATORS.REQUIRED()]}
					>
						<Select options={CUSTOMER_STATUS_SELECT_OPTIONS} />
					</Form.Item>
				</Form>
			</Modal>
		);
	}
);
