import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { IActionMenuItem, IActionsMenuProps } from './types';
import { Button, Dropdown, Space, Switch, Tooltip } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { EllipsisOutlined } from '@ant-design/icons';

export const ActionsMenu: FC<IActionsMenuProps> = memo(({ actions = [], withMoreActions }) => {
	const navigate = useNavigate();
	const { t: tCommon } = useTranslation('common');

	const menuOptions = useMemo<ItemType[]>(
		() => {
			if (!withMoreActions) return [];

			return actions
				.filter((action) => action.moreAction && (action.type === 'button' || action.type === 'link'))
				.map((action: IActionMenuItem, index) => {
					const { title, icon, disabled } = action;

					let onClick = undefined;

					if (action.type === 'button') {
						onClick = () => action.actionCb?.();
					}

					if (action.type === 'link') {
						onClick = () => navigate(action.navigateTo);
					}

					return {
						key: index,
						label: title,
						icon,
						disabled,
						onClick,
					};
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actions, withMoreActions]
	);

	// ! render
	return (
		<Space size='small'>
			{actions
				.filter((action) => !withMoreActions || !action.moreAction)
				.map((action, index) => {
					let actionElement: JSX.Element = <></>;

					switch (action.type) {
						case 'button':
							actionElement = (
								<Button
									size='small'
									icon={action.icon}
									disabled={action.disabled}
									className='flex-center'
									onClick={() => action.actionCb?.(undefined)}
								/>
							);
							break;
						case 'switch':
							actionElement = (
								<Switch
									size='small'
									checkedChildren={action.switchCheckedLabel ?? tCommon('on_off_switch.on')}
									unCheckedChildren={action.switchUncheckedLabel ?? tCommon('on_off_switch.off')}
									disabled={action.disabled}
									checked={action.switchValue}
									onChange={action.actionCb}
								/>
							);

							break;

						case 'link':
							actionElement = (
								<Link
									to={action.navigateTo ?? ''}
									target={action.target}
								>
									<Button
										size='small'
										icon={action.icon}
										disabled={action.disabled}
										className='flex-center'
									/>
								</Link>
							);
							break;
					}

					return (
						<Tooltip
							key={index}
							title={action.title}
						>
							{actionElement}
						</Tooltip>
					);
				})}

			{withMoreActions && (
				<Dropdown
					menu={{ items: menuOptions }}
					trigger={['click']}
					placement='bottomRight'
				>
					<Tooltip
						placement='topRight'
						title={tCommon('action_buttons.more_actions')}
					>
						<Button
							size='small'
							className='flex-center'
							icon={<EllipsisOutlined />}
						/>
					</Tooltip>
				</Dropdown>
			)}
		</Space>
	);
});
