export enum EGeneralSettingsKeys {
	ALLOW_TO_ACCUMULATE_DISCOUNTS = 'allow_to_accumulate_discounts',
	BIKERS_ONLY_DELIVERY_RANGE = 'bikers_only_delivery_range',
	CUSTOMER_SUPPORT_CONTACT_NUMBER = 'customer_support_contact_number',
	CUSTOMER_SUPPORT_SCHEDULE = 'customer_support_schedule',
	DRIVER_ACCEPT_TIMEOUT = 'driver_accept_timeout',
	DRIVER_ASSIGNMENT_MODE = 'driver_assignment_mode',
	DRIVER_AUTO_ASSIGN_SEARCH_RADIUS = 'driver_auto_assign_search_radius',
	DRIVER_SEARCH_RADIUS = 'driver_search_radius',
	ENABLED_ASSIGNMENT_ON_NEW_STATUS = 'enabled_assignment_on_new_status',
	LIVE_TRACKING_ALL_DRIVERS = 'live_tracking_all_drivers',
	MAX_CASH_ORDER_AMOUNT = 'max_cash_order_amount',
	OPERATIONS_EMAIL = 'operations_email',
	OTP_CHANNEL = 'otp_channel',
	PLATFORM_DELIVERY_FEE = 'platform_delivery_fee',
	STORE_LISTING_SHUFFLE = 'store_listing_shuffle',
	PRODUCT_MAX_QUANTITY = 'product_maximum_order_quantity',
}

export interface IGeneralSettingsItem {
	id: number;
	key: string;
	value: string;
	description: string;
}

export interface IBulkUpdateGeneralSettingsItem {
	id: number;
	value: string;
}

export interface IBulkUpdateGeneralSettingsConfig {
	general_settings: IBulkUpdateGeneralSettingsItem[];
}
