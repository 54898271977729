import { ESchemaComponentType } from 'pages/HomeAppConfiguration/types';

export const COMPONENT_CONSTRAINTS_ITEMS_PER_ROW_MIN = 4;
export const COMPONENT_CONSTRAINTS_ITEMS_PER_ROW_MAX = 8;
export const COMPONENT_CONSTRAINTS_DISPLAY_COUNT_MIN = 5;
export const COMPONENT_CONSTRAINTS_DISPLAY_COUNT_MAX = 15;
export const COMPONENT_CONSTRAINTS_ITEMS_PER_COLUMN_MIN = 4;
export const COMPONENT_CONSTRAINTS_ITEMS_PER_COLUMN_MAX = 8;

export const componentsRequiringTitle: Record<ESchemaComponentType, boolean> = {
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: true,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: true,
	[ESchemaComponentType.TRACKABLE_ORDERS]: true,
	//
	[ESchemaComponentType.BANNER]: false,
	[ESchemaComponentType.BILLBOARD_BANNER]: false,

	[ESchemaComponentType.BANNER_CAROUSEL]: false,
	[ESchemaComponentType.VERTICALS_MOSAIC]: false,
	[ESchemaComponentType.VERTICALS_LIST]: false,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: true,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: true,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: true,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: true,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: true,
};

export const componentsRequiringAction: Record<ESchemaComponentType, boolean> = {
	[ESchemaComponentType.BANNER]: true,
	[ESchemaComponentType.BILLBOARD_BANNER]: true,
	//
	[ESchemaComponentType.BANNER_CAROUSEL]: false,
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: false,
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: false,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: false,

	[ESchemaComponentType.TRACKABLE_ORDERS]: false,
	[ESchemaComponentType.VERTICALS_LIST]: false,
	[ESchemaComponentType.VERTICALS_MOSAIC]: false,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: false,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: false,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: true,
};

export const componentsRequiringVendorIds: Record<ESchemaComponentType, boolean> = {
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: true,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: true,
	//
	[ESchemaComponentType.BANNER]: false,
	[ESchemaComponentType.BILLBOARD_BANNER]: false,

	[ESchemaComponentType.BANNER_CAROUSEL]: false,
	[ESchemaComponentType.TRACKABLE_ORDERS]: false,
	[ESchemaComponentType.VERTICALS_MOSAIC]: false,
	[ESchemaComponentType.VERTICALS_LIST]: false,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: true,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: false,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
};

export const componentsRequiringVerticalList: Record<ESchemaComponentType, boolean> = {
	[ESchemaComponentType.VERTICALS_MOSAIC]: true,
	//
	[ESchemaComponentType.BANNER_CAROUSEL]: false,
	[ESchemaComponentType.BANNER]: false,
	[ESchemaComponentType.BILLBOARD_BANNER]: false,
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: false,
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: false,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: false,

	[ESchemaComponentType.TRACKABLE_ORDERS]: false,
	[ESchemaComponentType.VERTICALS_LIST]: false,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: false,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: false,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
};

export const componentsRequiringImage: Record<ESchemaComponentType, boolean> = {
	[ESchemaComponentType.BANNER]: true,
	[ESchemaComponentType.BILLBOARD_BANNER]: true,
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: true,
	//
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: false,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: false,

	[ESchemaComponentType.BANNER_CAROUSEL]: false,
	[ESchemaComponentType.TRACKABLE_ORDERS]: false,
	[ESchemaComponentType.VERTICALS_MOSAIC]: false,

	[ESchemaComponentType.VERTICALS_LIST]: false,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: false,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: false,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: false,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: false,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: false,
};

export const IMAGE_RATIO_MESSAGE: Record<ESchemaComponentType, undefined | string> = {
	[ESchemaComponentType.BANNER]: '4:1',
	[ESchemaComponentType.BILLBOARD_BANNER]: '13:5',
	[ESchemaComponentType.GENERIC_VENDOR_SWIMLANE]: '6:7',
	//
	[ESchemaComponentType.HERO_VENDOR_SWIMLANE]: undefined,
	[ESchemaComponentType.MATRIX_VENDOR_SWIMLANE]: undefined,

	[ESchemaComponentType.BANNER_CAROUSEL]: undefined,
	[ESchemaComponentType.TRACKABLE_ORDERS]: undefined,
	[ESchemaComponentType.VERTICALS_MOSAIC]: undefined,

	[ESchemaComponentType.VERTICALS_LIST]: undefined,

	[ESchemaComponentType.WIDE_VENDOR_SWIMLANE]: undefined,

	// Account
	[ESchemaComponentType.PROFILE_SECTION]: undefined,
	[ESchemaComponentType.MY_ORDERS_SWIMLANE]: undefined,
	[ESchemaComponentType.MY_FAVOURITES_SWIMLANE]: undefined,
	[ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY]: undefined,
};
