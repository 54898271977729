import { useTranslation } from 'react-i18next';
import { Outlet, createSearchParams, useMatch, useNavigate } from 'react-router-dom';
import { PREVIEW_MODE_SEARCH_PARAM, useViewMode } from 'hooks/useViewMode';
import { EHubTabs } from './types';
import { CustomTabs } from 'components/CustomTabs';
import PageLayout from 'components/PageLayout';
import { HUB_LIST_TABS_ROUTES } from './routes.hub';
import { PageHeader } from '@ant-design/pro-layout';

export const HubAppConfigurationLayout = () => {
	const navigate = useNavigate();
	const { t: tCommon } = useTranslation();

	const { viewMode } = useViewMode();

	const match = useMatch('/:countryIsoTwoCode/application-configuration/hub/:hubTab/*');

	// ! handlers
	const onTabChange = (activeKey: EHubTabs) => {
		navigate({
			pathname: `${activeKey}`,
			search: createSearchParams({ [PREVIEW_MODE_SEARCH_PARAM]: viewMode.toString() }).toString(),
		});
	};

	// ! render
	return (
		<PageLayout
			header={
				<>
					<PageHeader title={tCommon(`nav_items.hub_screen`)} />

					<CustomTabs
						routes={HUB_LIST_TABS_ROUTES}
						activeKey={match?.params.hubTab}
						onTabChange={onTabChange}
					/>
				</>
			}
		>
			<Outlet />
		</PageLayout>
	);
};
