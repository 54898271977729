import { FC, useMemo } from 'react';
import { THttpRequestParams } from 'hooks';
import { VERTICALS_API } from 'configs/api';
import { SelectSimpleFetch } from './_SelectSimpleFetch';
import { SelectProps } from 'antd';

export interface ISelectDeepLinkVerticalChildProps extends SelectProps {
	extra?: {
		verticalId: number;
	};
}

const SelectDeepLinkVerticalChild: FC<ISelectDeepLinkVerticalChildProps> = ({ ...props }) => {
	// ! memos
	const requestConfig = useMemo(() => {
		const parentId = props.extra?.verticalId;

		if (!parentId) {
			return;
		}

		return (search?: string, params: THttpRequestParams = {}) => {
			return VERTICALS_API.getChildVerticals(parentId, search, params);
		};
	}, [props.extra]);

	// ! render
	return (
		<SelectSimpleFetch
			requestConfig={requestConfig}
			{...props}
		/>
	);
};

export default SelectDeepLinkVerticalChild;
