import { FC, PropsWithChildren, Suspense } from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import { classNames } from 'utils/classNames';
import { EApplicationSchemaTypes } from '../types';
import Spinner from 'components/Spinner';
import { DroppableContainer, EContainerTypes } from 'pages/HomeAppConfiguration/SchemaBuilder/components/DndContainers';
import { SchemaComponent } from 'pages/HomeAppConfiguration/components/SchemaComponent/SchemaComponent';
import { SchemaBuilder } from '.';
import styles from './SchemaBuilder.module.css';
import { EmptyDnDContainer } from './components/EmptyDnDContainer';
import { FakeStoreRender } from './components/FakeStoreRender/FakeStoreRender';
import { NormalDraggable } from './components/NormalDraggable';
import { OtherSchemaSection } from './components/OtherSchemaSection/OtherSchemaSection';
import { useSchemaBuilder } from './useSchemaBuilder';
import { Divider, Flex, Typography as T } from 'antd';
import { SortableContext } from '@dnd-kit/sortable';

export const DeviceMainSection: FC<PropsWithChildren> = ({ children }) => {
	const {
		schemaType,
		isSchemaBuilderByDrag,
		isSchemaBuilderByInjection,
		schemaHasErrors,
		itemList,
		activeElement,
		fetchHttpClient,
		injectComponent,
		handleRemoveElement,
	} = useSchemaBuilder();

	// ! states
	const isTemplateComponent = activeElement?.isTemplateComponent;

	// ! render
	return (
		<Suspense>
			<SchemaBuilder.DnDContextWrapper>
				<Flex
					gap='small'
					className='h-100'
				>
					<SchemaBuilder.SchemaComponentLibrary />

					<Divider
						type='vertical'
						className='h-100'
					/>

					<div className={classNames('flex-fill', 'overflow-scroll-y', styles.home_background)}>
						{children}

						<Flex
							align='center'
							justify='center'
							className='h-100'
							style={{ minHeight: 1000, minWidth: 466 }}
						>
							<DeviceFrameset
								color='silver'
								device='iPhone 8 Plus'
							>
								<div className={styles.device_content}>
									{schemaType === EApplicationSchemaTypes.STORES_LIST && (
										<OtherSchemaSection schemaType={EApplicationSchemaTypes.HUB} />
									)}

									{fetchHttpClient.isLoading ? (
										<Spinner defaultAntdSpinner />
									) : (
										<Flex
											vertical
											className='h-100'
										>
											<Flex
												vertical
												gap='small'
												style={{ margin: 12 }}
											>
												{(itemList.length === 0 || isTemplateComponent) && (
													<EmptyDnDContainer position={0} />
												)}

												<SortableContext items={itemList.map((item) => item.dndId)}>
													{itemList.map((element, index, array) => {
														return (
															<Flex
																key={index + '_' + element.dndId}
																align='center'
																gap='small'
															>
																{!isSchemaBuilderByDrag && (
																	<T.Text
																		className={styles.position_indicator}
																		type={
																			element.isFakeStore
																				? 'secondary'
																				: undefined
																		}
																	>
																		{index + 1}
																	</T.Text>
																)}

																<div className='flex-fill'>
																	{!element.type && (
																		<DroppableContainer
																			id={element.dndId}
																			type={EContainerTypes.REPLACE}
																		>
																			<FakeStoreRender
																				key={index + '_' + element.dndId}
																			/>
																		</DroppableContainer>
																	)}
																	{element.type && (
																		<Flex
																			key={index + '_' + element.dndId}
																			vertical
																			gap='small'
																		>
																			<DroppableContainer
																				id={element.dndId}
																				disabled={
																					!!activeElement &&
																					isTemplateComponent
																				}
																				type={EContainerTypes.ITEM_CONTAINER}
																				content={element}
																			>
																				<NormalDraggable
																					id={element.dndId.toString()}
																					content={element}
																				>
																					<SchemaComponent
																						label={
																							element.component?.name ??
																							element.dndId
																						}
																						extraCommands={{
																							dragHandle:
																								isSchemaBuilderByDrag,
																							remove: true,
																							injectOnPosition:
																								isSchemaBuilderByInjection,
																						}}
																						type={element.type}
																						content={element}
																						onRemove={() =>
																							handleRemoveElement(
																								element.dndId
																							)
																						}
																						hasError={
																							schemaHasErrors &&
																							!element.component
																						}
																						onInject={(values) =>
																							injectComponent(
																								values,
																								false
																							)
																						}
																					/>
																				</NormalDraggable>
																			</DroppableContainer>

																			{isTemplateComponent &&
																				index < array.length && (
																					<EmptyDnDContainer
																						position={index + 1}
																					/>
																				)}
																		</Flex>
																	)}
																</div>
															</Flex>
														);
													})}
												</SortableContext>
											</Flex>
											{schemaType === EApplicationSchemaTypes.HUB && (
												<OtherSchemaSection
													schemaType={EApplicationSchemaTypes.STORES_LIST}
													className='flex-one'
												/>
											)}
										</Flex>
									)}
								</div>
							</DeviceFrameset>
						</Flex>
					</div>
				</Flex>
			</SchemaBuilder.DnDContextWrapper>
		</Suspense>
	);
};
