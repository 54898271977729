import { FC, PropsWithChildren } from 'react';
import { EContainerTypes } from './types';
import { EmptyContainer } from './EmptyContainer';
import { GhostContainer } from './GhostContainer';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';

interface IDroppableContainerProps extends PropsWithChildren {
	disabled?: boolean;
	id: UniqueIdentifier;
	type: EContainerTypes;
	style?: React.CSSProperties;
	content?: any;
	className?: string;
}

export const DroppableContainer = ({
	children,
	disabled,
	id,
	style,
	type,
	content,
	...props
}: IDroppableContainerProps) => {
	const { active, isDragging, over, setNodeRef, transition } = useSortable({
		id,
		data: {
			containerType: type,
			...content,
		},
	});
	const isOverContainer = over ? id === over.id && active?.data.current?.type !== 'container' : false;

	const containerConfigs: Record<EContainerTypes, FC<any>> = {
		[EContainerTypes.EMPTY]: EmptyContainer,
		[EContainerTypes.ITEM_CONTAINER]: GhostContainer,
		[EContainerTypes.REPLACE]: GhostContainer,
	};

	const ContainerComponent = containerConfigs[type];

	// ! render
	return (
		<ContainerComponent
			ref={disabled ? undefined : setNodeRef}
			style={{
				...style,
				transition,
				opacity: isDragging ? 0.5 : undefined,
			}}
			hover={isOverContainer}
			{...props}
		>
			{children}
		</ContainerComponent>
	);
};
