import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeepLinkInputHandle, IDeepLinkInputProps } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import { DeepLinkInput } from './DeepLinkInput';
import { Button, Card, Flex, Popover, Typography as T } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

export const DeepLinkInputPopUp: FC<IDeepLinkInputProps> = ({ value, onChange }) => {
	const { t: tCommon } = useTranslation('common');
	const { t: tDeepLinks } = useTranslation('deep-links');

	// ! refs
	const ref = useRef<IDeepLinkInputHandle>(null);

	// ! states
	const [popOpen, setPopOpen] = useState(false);
	const [temporaryValue, setTemporaryValue] = useState(value);

	// ! handlers
	const onDelete = () => {
		setTemporaryValue(undefined);
		onChange?.(undefined);
		setPopOpen(false);
	};

	const onOpenEditor = () => {
		setPopOpen(true);
	};

	const onCloseEditor = () => {
		setTemporaryValue(value);
		setPopOpen(false);

		ref.current?.resetInput();
	};

	const onSave = () => {
		onChange?.(temporaryValue);
		setPopOpen(false);

		ref.current?.resetInput();
	};

	// ! render
	return (
		<Popover
			open={popOpen}
			placement='top'
			styles={{ body: { width: 700 } }}
			title={<T.Title level={5}>{tDeepLinks('title')}</T.Title>}
			forceRender
			content={
				<Flex
					vertical
					gap='small'
				>
					<DeepLinkInput
						ref={ref}
						value={temporaryValue}
						onChange={setTemporaryValue}
					/>

					<Flex gap='small'>
						<Button
							block
							onClick={onCloseEditor}
						>
							{tCommon('action_buttons.cancel')}
						</Button>
						<Button
							block
							type='primary'
							disabled={!temporaryValue}
							onClick={onSave}
						>
							{tCommon('action_buttons.save')}
						</Button>
					</Flex>
				</Flex>
			}
		>
			{value ? (
				<Card size='small'>
					<Flex
						gap='small'
						justify='space-between'
					>
						<CopyToClipBoard>{value}</CopyToClipBoard>

						<Flex gap='small'>
							<Button
								type='text'
								size='small'
								icon={<EditOutlined />}
								onClick={onOpenEditor}
							/>
							<Button
								type='text'
								size='small'
								icon={<DeleteOutlined />}
								onClick={onDelete}
							/>
						</Flex>
					</Flex>
				</Card>
			) : (
				<Button
					icon={<PlusOutlined />}
					onClick={onOpenEditor}
				>
					{tDeepLinks('action')}
				</Button>
			)}
		</Popover>
	);
};
