import { forwardRef } from 'react';

export const GhostContainer = forwardRef<HTMLDivElement, any>(
	(
		{
			children,
			handleProps,
			horizontal,
			hover,
			onClick,
			onRemove,
			label,
			placeholder,
			style,
			scrollable,
			shadow,
			unstyled,
			...props
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				{...props}
			>
				{children}
			</div>
		);
	}
);
