import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getIsRTL } from 'store/selectors';
import TranslatedLabel from 'components/TranslatedLabel';
import { App, ModalFuncProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export type IOpenConfirmModalProps = ModalFuncProps & Required<Pick<ModalFuncProps, 'title' | 'onOk'>>;

export const useConfirmModal = () => {
	// ! refs
	const waitingRef = useRef(false);

	// ! selectors
	const isRTL = useSelector(getIsRTL);
	const { modal } = App.useApp();

	// ! helpers
	const getClassName = useCallback((args: IOpenConfirmModalProps) => {
		const argsClassName = args.className ? args.className : '';
		const rtlClassName = isRTL ? 'rtl' : '';

		let result: string = '';

		if (argsClassName) result = argsClassName;
		if (rtlClassName) result = result.concat(' ', rtlClassName);

		return result.trim();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openConfirmModal = (args: IOpenConfirmModalProps) => {
		// prevent multiple modals when clicking multiple times
		if (waitingRef.current) return;
		waitingRef.current = true;

		setTimeout(() => {
			waitingRef.current = false;
		}, 400);

		return modal
			.confirm({
				...args,
				className: getClassName(args),
				cancelText: args.cancelText || <TranslatedLabel i18nKey='action_buttons.cancel' />,
				okText: args.okText || <TranslatedLabel i18nKey='action_buttons.yes' />,
				centered: args.centered || true,
				icon: args.icon || <ExclamationCircleOutlined />,
			})
			?.then(
				// Required to convert ConfirmModal into promise
				() => {},
				() => {}
			);
	};

	return { openConfirmModal };
};
