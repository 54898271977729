import { generatePath } from 'react-router-dom';
import { ORDER_CODE_COLUMN_WIDTH } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES, getVendorBranchDetailsPageUrl } from 'configs/routes';
import { ICustomerOrderListTableData } from './types';
import LinkButton from 'components/LinkButton';
import OrderCodeLinkLabel from 'components/OrderCodeLinkLabel';
import TranslatedLabel from 'components/TranslatedLabel';
import OrderStatusTag from 'components/_tags/OrderStatusTag';
import { ColumnsType } from 'antd/es/table';
import { ApartmentOutlined, ShopOutlined } from '@ant-design/icons';

export const CUSTOMER_ORDER_LIST_TABLE_COLUMNS = (
	countryIsoTwoCode: string
): ColumnsType<ICustomerOrderListTableData> => [
	{
		key: 'order_code',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='customer_details.tabs.orders.shared.order_code'
			/>
		),
		width: ORDER_CODE_COLUMN_WIDTH,
		render: (_, record) => (
			<OrderCodeLinkLabel
				id={record.id}
				label={record.order_code}
			/>
		),
	},
	{
		key: 'vendor',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='customer_details.tabs.orders.shared.vendor'
			/>
		),
		render: (_, { branch }) => (
			<LinkButton
				label={branch.vendor_name}
				icon={<ApartmentOutlined />}
				permissions={[APP_PERMISSIONS.vendor.view]}
				to={generatePath(ABSOLUTE_ROUTES.VENDOR_DETAILS, { countryIsoTwoCode, vendorId: branch.vendor_id })}
			/>
		),
	},
	{
		key: 'branch',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='customer_details.tabs.orders.shared.branch'
			/>
		),
		render: (_, { branch }) => (
			<LinkButton
				label={branch.name}
				icon={<ShopOutlined />}
				to={getVendorBranchDetailsPageUrl(countryIsoTwoCode, branch.vendor_id, branch.id)}
				permissions={[APP_PERMISSIONS.vendor.store.view]}
			/>
		),
	},
	{
		key: 'total_cost',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='customer_details.tabs.orders.shared.total_cost'
			/>
		),
		render: (_, { total_cost, currency_code }) =>
			currency_code?.length ? `${total_cost} ${currency_code}` : total_cost,
	},
	{
		key: 'payment_type',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='customer_details.tabs.orders.shared.payment_type'
			/>
		),
		render: (_, { payment_type }) => <TranslatedLabel i18nKey={`order.payment.types.${payment_type}`} />,
	},
	{
		key: 'status',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='customer_details.tabs.orders.shared.status'
			/>
		),
		width: 188,
		render: (_, { status }) => <OrderStatusTag status={status} />,
	},
];
